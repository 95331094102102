import { useEffect, useState } from "react";
import { listCartitems } from "../../config/actions/cartActions";
import { useSelector, useDispatch } from "react-redux";
import ShowVariants from "./ShowVariants";
import CartDetail from "./CartDetail";
import { formatRupiah } from "../Helper";

const CartMini = (onMobile) => {
  const dispatch = useDispatch();
  const cartItemsList = useSelector(state => state.cartItemsList);
  const { loading, error, cartItems } = cartItemsList;

  const [totalQty, setTotalQty] = useState(0);
  const [totalCart, setTotalCart] = useState(0);

  useEffect(() => {
    dispatch(listCartitems())
  }, [dispatch])

  useEffect(() => {
    TotalCartData();
  }, [cartItems])

  function TotalCartData() {
    try {
      let totalQty = 0;
      let totalCart = 0;
      if (cartItems.length > 0) {
        cartItems.map((item) => {
          totalQty = totalQty + parseInt(item.qtyInCart);
          if (parseInt(item.saleprice) > 0) {
            totalCart = totalCart + parseInt(item.qtyInCart) * parseInt(item.saleprice);
          } else {
            totalCart = totalCart + parseInt(item.qtyInCart) * parseInt(item.price);
          }
        })
      }
      setTotalQty(totalQty);
      setTotalCart(totalCart);
    } catch (error) {
      console.log("No data error...")
    }
  }

  const ShowPrice = ({ qty, saleprice, price }) => {
    if (saleprice > 0) {
      return (
        <small>{qty} x {formatRupiah(saleprice)} {" "}<del>{price}</del></small>
      )
    } else {
      return (
        <small>{qty} x {formatRupiah(price)}</small>
      )
    }
  }

  return (
    <>
      <div className="ps-cart--mini">
        <a
          className={"header__extra " + (onMobile ? "btn-shopping-cart" : "btn-shopping-cart")}
          href="/cart" style={{ color: "whitesmoke" }}
        >
          <i className="icon-bag2"></i>
          <span style={{ backgroundColor: "#E02954" }}>
            <i style={{ color: "white", backgroundColor: "#E02954" }}>{totalQty}</i>
          </span>
        </a>
        <div className={onMobile ? "ps-cart--mobile" : "ps-cart--mobile"} >
          <div className="ps-cart__content">
            {
              loading ? (
                <div>Loading...</div>
              ) : error ? (
                <>
                  <div className="ps-cart__items ps-cart_no_items">
                    <span className="cart-empty-message">Keranjang anda kosong.</span>
                  </div>

                </>
              ) : (
                <div className="ps-cart__items">
                  <div className="ps-cart__items__body">
                    {
                      cartItems.length > 0 ?
                        cartItems.map((item, index) => (
                          <CartDetail key={index} item={item} />
                        ))
                        :
                        <div
                          className='cart_empty'
                          style={{
                            padding: "16px",
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            textAlign: "center",
                            alignItems: "center"
                          }}>
                          <p>Keranjang kamu masih kosong</p>
                          <img src={process.env.PUBLIC_URL + "/assets/img/empty_cart.png"} />
                        </div>


                      // <p>No Datas</p>
                    }
                  </div>
                </div>
              )
            }
            {
              cartItems.length > 0 ?
                <div className="ps-cart__footer">
                  <h3>
                    Sub Total:<strong>{formatRupiah(totalCart)}</strong>
                  </h3>
                  <figure>
                    <a className="ps-btn" href="/cart">
                      View Cart
                    </a>
                    <a className="ps-btn" href="/checkout">
                      Checkout
                    </a>
                  </figure>
                </div> : null
            }
          </div>
        </div>
      </div >
    </>
  );
};

export default CartMini;
