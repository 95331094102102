import { useEffect, useState } from "react";
import axios from "./useAxios";
import jwt_decode from "jwt-decode"
import tokenService from "../config/actions/token.service";
import { useDispatch } from "react-redux";
import { refreshToken, logout } from "../config/actions/auth";

const useFetchToken = (url) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [data, setData] = useState([]);

  const localToken = tokenService.getLocalAccessToken();
  const expiredToken = jwt_decode(localToken).exp;
  const dispatch = useDispatch();


  let token = localToken;
  let iduser = jwt_decode(token).userId;

  // console.log("Expired Token :", expiredToken * 1000);
  // console.log("Date Now :", Date.now());


  // console.log("Local Token : ", localToken);
  // console.log("EXP Datetime : ", expiredToken * 1000);
  // console.log("Datetime NOW :", Date.now());

  useEffect(() => {
    fetchDataToken();
  }, [url]);

  const fetchDataToken = async () => {
    const axiosJWT = axios.create(); // bebas variable
    console.log("Expired Token :", expiredToken * 1000);
    console.log("Date Now :", Date.now());

    axiosJWT.interceptors.request.use(
      async (config) => {
        if (expiredToken * 1000 < Date.now()) {
          const response = await axios.get("/token");
          console.log("hereeee beib");
          console.log("Response Token :", response.data);
          config.headers.Authorization = `Bearer ${response.data.accessToken}`;
          const decoded = jwt_decode(response.data.accessToken);
          tokenService.updateLocalAccessToken(response.data.accessToken);
          iduser = decoded.userId;
          token = decoded;
          await dispatch(refreshToken(response.data.accessToken));
        }
        return config;
      },
      (error) => {
        console.log("error axios jwt");
        return Promise.reject(error);
      }
    );
    setLoading(true);
    try {
      // console.log("TOKEN :", token);
      // console.log("ID User :", iduser);
      // const res = await axios.get("/token");
      // if (res.data.result === "OK") {
      //   setToken(res.data.accessToken);
      //   const decoded = jwt_decode(res.data.accessToken);
      //   setExpire(decoded.exp);
      //   setUserId(decoded.userId);
      //   iduser = decoded.userId;
      // }
      const response = await axiosJWT.get(url + iduser, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setData(response.data);
    } catch (err) {
      console.log("Error Fetch Query : ------", err);
      dispatch(logout());

      setError(err);
    }
    setLoading(false);

  };

  return { data, loading, error };

};
export default useFetchToken;
