import React from "react";
import CustomerSideBar from "./SideBar";
import Header from "../../Components/Header/Header";
import HeaderMobile from "../../Components/Header/HeaderMobile";
import Breadcrumb from "../../Components/Common/Breadcrumb";
import Sidebar from "../../Components/SideBar/Sidebar";
import ProductFooter from "../../Components/Footer/ProductFooter";
import useFetchToken from "../../hooks/useFetchToken";
import { useSelector } from "react-redux";
import { Navigate } from "react-router";

const Overview = () => {

  const arrCumb = [
    {
      "name": "Account information",
      "link": "customer/overview"
    }
  ]

  const { data } = useFetchToken("/customervendor/")

  return (
    <>
      <Header />
      <HeaderMobile />
      <Breadcrumb pagename={arrCumb} />
      <Sidebar />
      <section className="ps-section--account crop-avatar customer-page">
        <div className="container">
          <div className="row">
            <CustomerSideBar pageid="overview" />
            <div className="col-lg-8">
              <div className="ps-section__right">
                <div className="ps-section--account-setting">
                  <div className="ps-section__header">
                    <h3>Account information</h3>
                  </div>
                  <div className="ps-section__content">
                    <p><i className="icon-user"></i> <span className="d-inline-block">Name:</span> <strong>{data.name}</strong></p>
                    <p><i className="icon-envelope"></i> <span className="d-inline-block">Email:</span> <strong>{data.email}</strong></p>
                    <p><i className="icon-phone-bubble"></i> <span className="d-inline-block">Phone:</span> <strong>{data.phone === null ? 'N/A' : data.phone}</strong></p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default Overview