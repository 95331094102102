import React, { useState, useEffect, useRef } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import Header from "../Components/Header/Header";
import HeaderMobile from "../Components/Header/HeaderMobile";
import Breadcrumb from "../Components/Common/Breadcrumb";
import Sidebar from "../Components/SideBar/Sidebar";
import ProductFooter from "../Components/Footer/ProductFooter";
import { useFormik } from "formik";
import * as Yup from "yup";
import Loading from "../Components/Loading";
import { useDispatch, useSelector } from "react-redux";
import { login } from "../config/actions/auth";
import { errorPopup, successPopup } from "../Components/PopupAlert";

const Login = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const from = location.state?.pathname || "/";
  console.log("from : ", from)
  const [counterEffect, setCounterEffect] = useState(0);

  // console.log(from);
  const userRef = useRef();

  const { isLoggedIn } = useSelector(state => state.userReducer);
  const { message } = useSelector(state => state.messageReducer);

  const dispatch = useDispatch();

  useEffect(() => {
    userRef.current.focus();
  }, [])

  const arrCumb = [
    {
      "name": "Login",
      "link": "login"
    }
  ]

  if (isLoggedIn) {
    console.log("sudah login");
  }

  const [isLoading, setLoading] = useState(false);

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
      isRemember: false,
    },
    validationSchema: Yup.object({
      email: Yup.string().email("Invalid email address").required("Requires"),
      password: Yup.string()
        .max(20, "Must be 20 Character or less")
        .required("Required"),
    }),
    onSubmit: async (values, { setSubmitting }) => {
      setLoading(true);
      let email = values.email;
      let password = values.password;
      await dispatch(login(email, password))
        .then(() => {
          successPopup("Login berhasil...", 3000)
          navigate(from, { replace: true });
          window.location.reload();
        })
        .catch(() => {
          setLoading(false);
        })
    },
  });

  useEffect(() => {
    setCounterEffect(counterEffect + 1);
    if (message != undefined) {
      if (counterEffect > 0 && counterEffect <= 2) {
        errorPopup(message, 3000);
        setCounterEffect(0);
      }
    }
  }, [isLoading])

  return (
    <>
      {
        isLoading && <div><Loading /></div>
      }
      <Header />
      <HeaderMobile />
      <Breadcrumb pagename={arrCumb} />
      <Sidebar />
      <div className="ps-container">
        <div className="mt-40 mb-40">
          <div className="ps-my-account">
            <div className="container">
              <form
                onSubmit={formik.handleSubmit}
                className="ps-form--account ps-tab-root"
              >
                <div className="ps-form__content">
                  <h4>Log In Your Account</h4>
                  <div className="form-group">
                    <input
                      name="email"
                      className="form-control"
                      value={formik.values.email}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      placeholder="Your Email"
                      ref={userRef}
                    />
                    {formik.touched.email && formik.errors.email ? (
                      <span className="text-danger">{formik.errors.email}</span>
                    ) : null}

                  </div>
                  <div className="form-group form-forgot">
                    <input
                      className="form-control"
                      type="password"
                      name="password"
                      value={formik.values.password}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      placeholder="Password"
                    />
                    {formik.touched.password && formik.errors.password ? (
                      <span className="text-danger">
                        {formik.errors.password}
                      </span>
                    ) : null}

                    <a href="/resetpassword">Forgot?</a>
                  </div>
                  <div className="form-group">
                    <div className="ps-checkbox">
                      <input
                        className="form-control"
                        name="isRemember"
                        type="checkbox"
                        value={formik.values.isRemember}
                        onChange={formik.handleChange}
                        id="remember-me"
                      />
                      <label htmlFor="remember-me">Remember me</label>
                    </div>
                  </div>
                  <div className="form-group submit">
                    {!isLoading && (
                      <button
                        className="ps-btn ps-btn--fullwidth"
                        type="submit"
                      >
                        Login
                      </button>
                    )}
                    {isLoading && (
                      <button className="ps-btn ps-btn--fullwidth" disabled>
                        <i className="fas fa-spinner fa-spin"></i>Please wait...
                      </button>
                    )}
                  </div>

                  <div className="form-group">
                    <p className="text-center">
                      Don't Have an Account?
                      <a href="/Register" className="d-inline-block">
                        Sign up now
                      </a>
                    </p>
                  </div>
                </div>
                <div className="ps-form__footer"></div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <ProductFooter />
      <div id="back2top">
        <i className="icon icon-arrow-up"></i>
      </div>
      <div className="ps-site-overlay"></div>
    </>
  );
};

export default Login;
