import React, { useState, useEffect } from 'react'

// export const HeaderProduct = ({ product, persenstar, reviewsrec, dataProduct, variant, varianLevel }) => {
export const HeaderProduct = (props) => {

    console.log(props)

    const [sticky, setSticky] = useState("header header--product");

    const isSticky = () => {
        const scrollTop = window.scrollY;
        const stickyClass = scrollTop >= 20 ? "header header--product header--sticky" : "header header--product";
        setSticky(stickyClass);
    };

    const ViewPrice = () => {

        let itemSalePrice = 0;
        let itemPrice = 0;

        if (props.dataProduct != null) {
            itemSalePrice = props.dataProduct[0].sale_price;
            itemPrice = props.dataProduct[0].price;
        } else {
            itemSalePrice = props.product[0].sale_price;
            itemPrice = props.product[0].price;
        }
        if (itemSalePrice === null || itemSalePrice === 0 || itemSalePrice === undefined) {
            return (
                <>
                    <span className="ps-product__price">
                        <span>Rp {itemPrice.toLocaleString()}</span>
                    </span>
                </>
            )
        } else {
            return (
                <>
                    <span className="ps-product__price">
                        <span>Rp {itemSalePrice.toLocaleString()}</span>
                        <del>{itemPrice.toLocaleString()}</del>
                    </span>
                </>
            )
        }
    }

    useEffect(() => {
        window.addEventListener("scroll", isSticky);
        return () => {
            window.removeEventListener("scroll", isSticky);
        };
    }, []);

    return (
        <>
            <header className={sticky} data-sticky="true">
                <nav className="navigation">
                    <div className="container">
                        <article className="ps-product--header-sticky">
                            <div className="ps-product__thumbnail">

                                <img src={`https://gracetoko.com/storage/products/${props.product[0]?.images[0]}`}
                                    onError={(e) => { e.target.onerror = null; e.target.src = process.env.PUBLIC_URL + "/assets/img/placeholder.png" }}
                                    alt={props.product[0]?.name}
                                />
                            </div>
                            <div className="ps-product__wrapper">
                                <div className="ps-product__content">
                                    <a
                                        className="ps-product__title"
                                        href={`/${props.product[0]?.mp_stores.key}/${props.product[0]?.slugs_key}`}
                                    >
                                        {props.product[0]?.name}
                                    </a>
                                    <span>Sold By : {props.product[0].mp_stores.name}</span>

                                    <div className="rating_wrap">
                                        <a href={void (0)}>
                                            <div className="rating">
                                                <div
                                                    className="product_rate"
                                                    style={{ "width": `${props.persenstar}%` }}
                                                ></div>
                                            </div>
                                            <span className="rating_num">({props.reviewsrec || 0} reviews)</span>
                                        </a>
                                    </div>

                                    {/* <ul className="ps-tab-list">
                                <li className="active">
                                <a href="#tab-description">Description</a>
                                </li>
                                <li>
                                <a href="#tab-reviews">Reviews ({product[0]?.reviews.records})</a>
                                </li>
                            </ul> */}
                                </div>


                                <div className="ps-product__shopping">
                                    <ViewPrice />
                                    {/* <form
                                className="add-to-cart-form"
                                method="POST"
                                action="http://gracetoko.com/cart/add-to-cart"
                            >
                                <input
                                type="hidden"
                                name="_token"
                                value="p7mk0zaIfBF0EVima8gKki0s2clCxFcsOuXOWopE"
                                />
                                <input
                                type="hidden"
                                name="id"
                                className="hidden-product-id"
                                value={product[0]?.id}
                                />
                                <input type="hidden" name="qty" value="1" />
                                <button
                                className="ps-btn "
                                type="submit"
                                style={{ color: "black" }}
                                >
                                Add to cart
                                </button>
                            </form> */}

                                    <button
                                        className="ps-btn "
                                        type="button"
                                        style={{ color: "white" }}
                                        onClick={(e) => { props.setClickButton(1) }}
                                    >
                                        Add to cart
                                    </button>

                                </div>
                            </div>
                        </article>
                    </div>
                </nav>
            </header>
        </>
    )
}
