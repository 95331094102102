import React from "react";
import HeaderMobileCart from "../Components/Header/HeaderMobileCart";
import "../Components/sass/payment.scss";

function Checkout() {
  return (
    <>
      <HeaderMobileCart />

      <div className="checkout-page" style={{ marginTop: "15px" }}>
        <div className="checkout-content-wrap">
          <div
            className="container"
            style={{
              width: "100%",
              paddingRight: "15px",
              paddingLeft: "15px",
              marginLeft: "auto",
              marginRight: "auto",
            }}
          >
            <div className="row">
              <form
                name="asdsa"
                method="POST"
                action="https://gracetoko.com/checkout/caae330b470e2b2b087a7885c0f9d8bf/process"
                acceptCharset="UTF-8"
                className="checkout-form payment-checkout-form"
                id="checkout-form"
              >
                <input
                  name="_token"
                  type="hidden"
                  value="z7pbGNo8QVdpaEV2tZch0LlLsNtzf1wgvTIUD8B1"
                />
                <input
                  type="hidden"
                  name="checkout-token"
                  id="checkout-token"
                  value="caae330b470e2b2b087a7885c0f9d8bf"
                />

                <div
                  className="container"
                  id="main-checkout-product-info"
                  style={{ paddingRight: "15px", paddingLeft: "15px" }}
                >
                  <div className="row">
                    <div
                      className="order-1 order-md-2 col-lg-5 col-md-6 right"
                      style={{ flex: "41%", paddingTop: "40px" }}
                    >
                      {/* <div className="d-block d-sm-none">
                        <div className="checkout-logo">
                          <div className="container">
                            <a
                              href="https://gracetoko.com"
                              title="Martfury - Laravel Ecommerce system"
                            >
                              <img
                                src={
                                  process.env.PUBLIC_URL +
                                  "/assets/img/general/logo-dark.pngss"
                                }
                                alt=""
                                className="img-fluid"
                                width="150"
                              />
                            </a>
                          </div>
                        </div>
                        <hr />
                      </div> */}
                      <div id="cart-item" className="position-relative">
                        <div
                          className="payment-info-loading"
                          style={{ display: "none" }}
                        >
                          <div className="payment-info-loading-content">
                            <i className="fas fa-spinner fa-spin"></i>
                          </div>
                        </div>

                        <div className="bg-light p-2">
                          <p className="font-weight-bold mb-0">Product(s):</p>
                        </div>
                        <div
                          className="checkout-products-marketplace"
                          id="shipping-method-wrapper"
                        >
                          <div className="mt-3 bg-light mb-3">
                            <div
                              className="p-2"
                              style={{ background: "antiquewhite" }}
                            >
                              <img
                                src="https://gracetoko.com/storage/general/logo-300x300.png"
                                alt="Martfury - Laravel Ecommerce system"
                                className="img-fluid rounded"
                                width="30"
                              />
                              <span className="font-weight-bold">
                                Martfury - Laravel Ecommerce system
                              </span>
                              <div className="rating_wrap">
                                <div className="rating">
                                  <div
                                    className="product_rate"
                                    style={{ width: "80%" }}
                                  ></div>
                                </div>
                              </div>
                            </div>

                            <div className="p-3">
                              <div className="row cart-item">
                                <div className="col-3">
                                  <div className="checkout-product-img-wrapper">
                                    <img
                                      className="item-thumb img-thumbnail img-rounded"
                                      src="https://gracetoko.com/storage/products/9-150x150.jpg"
                                      alt="Madu Sumbawa"
                                    />
                                    <span
                                      className="checkout-quantity"
                                      style={{
                                        position: "absolute",
                                        background: "#a2a2a2",
                                        border: "1px solid #a2a2a2",
                                        WebkitBorderRadius: "50%",
                                        color: "#fff",
                                        right: "-2px",
                                        top: "-7px",
                                        textAlign: "center",
                                        width: "25px",
                                        lineHeight: "22px",
                                        borderRadius: "50%",
                                      }}
                                    >
                                      1
                                    </span>
                                  </div>
                                </div>
                                <div className="col-5">
                                  <p className="mb-0">Madu Sumbawazz </p>
                                  <p className="mb-0">
                                    <small>(Color: Green, Size: S)</small>
                                  </p>
                                </div>
                                <div className="col-4 text-end">
                                  <p>$200,000.00</p>
                                </div>
                              </div>
                            </div>
                            <hr />
                            <div className="p-3">
                              <div className="row">
                                <div className="col-6">
                                  <p>Subtotal:</p>
                                </div>
                                <div className="col-6 text-end">
                                  <p className="price-text sub-total-text text-end">
                                    $200,000.00
                                  </p>
                                </div>
                              </div>

                              <div className="row">
                                <div className="col-6">
                                  <p>Shipping fee:</p>
                                </div>
                                <div className="col-6 text-end">
                                  <p className="price-text">
                                    <span className="font-weight-bold">
                                      $0.00
                                    </span>
                                  </p>
                                </div>
                              </div>

                              <div className="row">
                                <div className="col-6">
                                  <p>Tax:</p>
                                </div>
                                <div className="col-6 text-end">
                                  <p className="price-text tax-price-text">
                                    $0.00
                                  </p>
                                </div>
                              </div>

                              <div className="row">
                                <div className="col-6">
                                  <p>Total:</p>
                                </div>
                                <div className="col-6 float-end">
                                  <p
                                    className="total-text raw-total-text mb-0"
                                    data-price="200000"
                                  >
                                    $200,000.00
                                  </p>
                                </div>
                              </div>
                            </div>
                            <div className="shipping-method-wrapper p-3">
                              <div className="payment-checkout-form">
                                <div className="mx-0">
                                  <h6>Shipping method:</h6>
                                </div>

                                <input
                                  type="hidden"
                                  name="shipping_option[0]"
                                  value="1"
                                />
                                <div id="shipping-method-0">
                                  <ul className="list-group list_payment_method">
                                    <li className="list-group-item">
                                      <input
                                        className="magic-radio shipping_method_input"
                                        // checked={true}
                                        type="radio"
                                        name="shipping_method[0]"
                                        id="shipping-method-0-default-1"
                                        //   checked
                                        //   value="default"
                                        data-option="1"
                                        data-id="0"
                                      />
                                      <label htmlFor="shipping-method-0-default-1">
                                        Delivery -<strong>Free shipping</strong>
                                      </label>
                                    </li>
                                  </ul>
                                </div>
                              </div>
                            </div>
                          </div>
                          {/* <div className="mt-3 bg-light mb-3">
                            <div
                              className="p-2"
                              style={{ background: "antiquewhite" }}
                            >
                              <img
                                src="https://gracetoko.com/storage/stores/5-300x300.png"
                                alt="Robert’s Store"
                                className="img-fluid rounded"
                                width="30"
                              />
                              <span className="font-weight-bold">
                                Robert’s Store
                              </span>
                              <div className="rating_wrap">
                                <div className="rating">
                                  <div
                                    className="product_rate"
                                    style={{ width: "80%" }}
                                  ></div>
                                </div>
                              </div>
                            </div>

                            <div className="p-3">
                              <div className="row cart-item">
                                <div className="col-3">
                                  <div className="checkout-product-img-wrapper">
                                    <img
                                      className="item-thumb img-thumbnail img-rounded"
                                      src="https://gracetoko.com/storage/products/6-150x150.jpg"
                                      alt="Nikon HD camera"
                                    />
                                    <span
                                      className="checkout-quantity"
                                      style={{
                                        position: "absolute",
                                        background: "#a2a2a2",
                                        border: "1px solid #a2a2a2",
                                        WebkitBorderRadius: "50%",
                                        color: "#fff",
                                        right: "-2px",
                                        top: "-7px",
                                        textAlign: "center",
                                        width: "25px",
                                        lineHeight: "22px",
                                        borderRadius: "50%",
                                      }}
                                    >
                                      1
                                    </span>
                                  </div>
                                </div>
                                <div className="col-5">
                                  <p className="mb-0">Nikon HD camera </p>
                                  <p className="mb-0">
                                    <small>(Size: S, Color: Black)</small>
                                  </p>
                                </div>
                                <div className="col-4 text-end">
                                  <p>$406.00</p>
                                </div>
                              </div>
                            </div>

                            <hr />
                            <div className="p-3">
                              <div className="row">
                                <div className="col-6">
                                  <p>Subtotal:</p>
                                </div>
                                <div className="col-6 text-end">
                                  <p className="price-text sub-total-text text-end">
                                    {" "}
                                    $406.00{" "}
                                  </p>
                                </div>
                              </div>

                              <div className="row">
                                <div className="col-6">
                                  <p>Shipping fee:</p>
                                </div>
                                <div className="col-6 text-end">
                                  <p className="price-text">
                                    <span className="font-weight-bold">
                                      $0.00
                                    </span>
                                  </p>
                                </div>
                              </div>

                              <div className="row">
                                <div className="col-6">
                                  <p>Tax:</p>
                                </div>
                                <div className="col-6 text-end">
                                  <p className="price-text tax-price-text">
                                    $40.60
                                  </p>
                                </div>
                              </div>

                              <div className="row">
                                <div className="col-6">
                                  <p>Total:</p>
                                </div>
                                <div className="col-6 float-end">
                                  <p
                                    className="total-text raw-total-text mb-0"
                                    data-price="446.6"
                                  >
                                    $446.60
                                  </p>
                                </div>
                              </div>
                            </div>

                            <div className="shipping-method-wrapper p-3">
                              <div className="payment-checkout-form">
                                <div className="mx-0">
                                  <h6>Shipping method:</h6>
                                </div>

                                <input
                                  type="hidden"
                                  name="shipping_option[5]"
                                  value="1"
                                />
                                <div id="shipping-method-5">
                                  <ul className="list-group list_payment_method">
                                    <li className="list-group-item">
                                      <input
                                        className="magic-radio shipping_method_input"
                                        type="radio"
                                        name="shipping_method[5]"
                                        id="shipping-method-5-default-1"
                                        // checked={true}
                                        //   value="default"
                                        data-option="1"
                                        data-id="5"
                                      />
                                      <label htmlFor="shipping-method-5-default-1">
                                        Delivery -<strong>Free shipping</strong>
                                      </label>
                                    </li>
                                  </ul>
                                </div>
                              </div>
                            </div>
                          </div> */}
                        </div>

                        <div className="mt-2 p-2">
                          <div className="row">
                            <div className="col-6">
                              <p>Subtotal:</p>
                            </div>
                            <div className="col-6">
                              <p className="price-text sub-total-text text-end">
                                $200,406.00
                              </p>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-6">
                              <p>Shipping fee:</p>
                            </div>
                            <div className="col-6 float-end">
                              <p className="price-text shipping-price-text">
                                $0.00
                              </p>
                            </div>
                          </div>

                          <div className="row">
                            <div className="col-6">
                              <p>Tax:</p>
                            </div>
                            <div className="col-6 float-end">
                              <p className="price-text tax-price-text">
                                $40.60
                              </p>
                            </div>
                          </div>

                          <div className="row">
                            <div className="col-6">
                              <p>
                                <strong>Total</strong>:
                              </p>
                            </div>
                            <div className="col-6 float-end">
                              <p
                                className="total-text raw-total-text"
                                data-price="200446.6"
                              >
                                $200,446.60
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>

                      <hr />

                      <div className="mt-3 mb-5">
                        <div className="checkout-discount-section">
                          <a href="#coupon" className="btn-open-coupon-form">
                            Gunakan kupon anda ?
                          </a>
                        </div>
                        <div
                          className="coupon-wrapper"
                          id="coupon"
                          style={{ display: "none" }}
                        >
                          <div className="row promo coupon coupon-section">
                            <div className="col-lg-8 col-md-8 col-8">
                              <input
                                type="text"
                                name="coupon_code"
                                className="form-control coupon-code input-md checkout-input"
                                //   value=""
                                placeholder="Enter coupon code..."
                              />
                              <div className="coupon-error-msg">
                                <span className="text-danger"></span>
                              </div>
                            </div>
                            <div className="col-lg-4 col-md-4 col-4 text-end">
                              <button
                                className="btn btn-md btn-gray btn-info apply-coupon-code float-end"
                                data-url="https://gracetoko.com/coupon/apply"
                                type="button"
                              >
                                <i className="fa fa-gift"></i> Apply
                              </button>
                            </div>
                          </div>
                        </div>
                        <div className="clearfix"></div>
                      </div>
                    </div>
                    <div
                      className="col-lg-7 col-md-6 left"
                      style={{
                        borderRight: "1px solid #c8c8c8",
                        paddingTop: "40px",
                        paddingBottom: "50px",
                      }}
                    >
                      <div className="d-none d-sm-block">
                        <div className="checkout-logo">
                          <div className="container">
                            <a
                              href="https://gracetoko.com"
                              title="Martfury - Laravel Ecommerce system"
                            >
                              <img
                                src={
                                  process.env.PUBLIC_URL +
                                  "/assets/img/general/logo-dark.png"
                                }
                                alt=""
                                className="img-fluid"
                                width="150"
                              />
                            </a>
                          </div>
                        </div>
                        <hr />
                      </div>
                      <div className="form-checkout">
                        <form
                          name="asd"
                          action="https://gracetoko.com/payments/checkout"
                          method="post"
                        >
                          <input
                            type="hidden"
                            name="_token"
                            value="z7pbGNo8QVdpaEV2tZch0LlLsNtzf1wgvTIUD8B1"
                          />
                          <div>
                            <h5 className="checkout-payment-title">
                              Shipping information
                            </h5>
                            <input
                              type="hidden"
                              value="https://gracetoko.com/checkout/caae330b470e2b2b087a7885c0f9d8bf/information"
                              id="save-shipping-information-url"
                            />
                            <div className="customer-address-payment-form">
                              <div className="form-group mb-3">
                                <p>
                                  Already have an account?
                                  <a href="https://gracetoko.com/login">
                                    Login
                                  </a>
                                </p>
                              </div>
                              <div className="address-form-wrapper">
                                <div className="row">
                                  <div className="col-12">
                                    <div className="form-group mb-3 ">
                                      <input
                                        type="text"
                                        name="address[name]"
                                        id="address_name"
                                        placeholder="Full Name"
                                        className="form-control address-control-item address-control-item-required checkout-input"
                                        //   value=""
                                      />
                                    </div>
                                  </div>
                                </div>
                                <div className="row">
                                  <div className="col-12">
                                    <div className="form-group mb-3 ">
                                      <input
                                        type="text"
                                        name="address[email]"
                                        id="address_email"
                                        placeholder="Email"
                                        className="form-control address-control-item address-control-item-required checkout-input"
                                        //   value=""
                                      />
                                    </div>
                                  </div>
                                  {/* <div className="col-lg-4 col-12">
                                    <div className="form-group  ">
                                      <input
                                        type="text"
                                        name="address[phone]"
                                        id="address_phone"
                                        placeholder="Phone "
                                        className="form-control address-control-item address-control-item-required checkout-input"
                                        //   value=""
                                      />
                                    </div>
                                  </div> */}
                                </div>
                                <div className="row">
                                  <div className="col-12">
                                    <div className="form-group mb-3 ">
                                      <input
                                        type="text"
                                        name="address[name]"
                                        id="address_name"
                                        placeholder="Full Name"
                                        className="form-control address-control-item address-control-item-required checkout-input"
                                        //   value=""
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="row">
                                <div className="col-12">
                                  <div className="form-group mb-3">
                                    <input
                                      type="checkbox"
                                      name="create_account"
                                      value="1"
                                      id="create_account"
                                    />
                                    <label
                                      htmlFor="create_account"
                                      className="control-label"
                                      style={{ paddingLeft: "5px" }}
                                    >
                                      Register an account with above
                                      information?
                                    </label>
                                  </div>
                                </div>
                              </div>
                              <div
                                className="password-group"
                                style={{ display: "none" }}
                              >
                                <div className="row">
                                  <div className="col-md-6 col-12">
                                    <div className="form-group">
                                      <input
                                        id="password"
                                        type="password"
                                        className="form-control checkout-input"
                                        name="password"
                                        autoComplete="password"
                                        //   autocomplete="password"
                                        placeholder="Password"
                                      />
                                    </div>
                                  </div>

                                  <div className="col-md-6 col-12">
                                    <div className="form-group">
                                      <input
                                        id="password-confirm"
                                        type="password"
                                        className="form-control checkout-input"
                                        //   autocomplete="password-confirmation"
                                        autoComplete="password-confirmation"
                                        placeholder="Password confirmation"
                                        name="password_confirmation"
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <br />

                          {/* <div className="position-relative">
                            <div
                              className="payment-info-loading"
                              style={{ display: "none" }}
                            >
                              <div className="payment-info-loading-content">
                                <i className="fas fa-spinner fa-spin"></i>
                              </div>
                            </div>
                            <h5 className="checkout-payment-title">
                              Payment method
                            </h5>
                            <input
                              type="hidden"
                              name="amount"
                              value="200446.6"
                            />
                            <input type="hidden" name="currency" value="USD" />
                            <input
                              type="hidden"
                              name="callback_url"
                              value="https://gracetoko.com/payment/status"
                            />
                            <input
                              type="hidden"
                              name="return_url"
                              value="https://gracetoko.com/checkout/caae330b470e2b2b087a7885c0f9d8bf/success"
                            />
                          </div>

                          <br />
 */}
                          {/* <div className="form-group mb-3 ">
                            <label
                              htmlFor="description"
                              className="control-label"
                            >
                              Order notes
                            </label>
                            <br />
                            <textarea
                              name="description"
                              id="description"
                              rows="3"
                              className="form-control"
                              placeholder="Notes about your order, e.g. special notes for delivery."
                            ></textarea>
                          </div> */}

                          {/* <div className="form-group mb-3">
                            <div className="row">
                              <div
                                className="col-md-6 d-none d-md-block"
                                // style="line-height: 53px"
                              >
                                <a
                                  className="text-info"
                                  href="https://gracetoko.com/cart"
                                >
                                  <i className="fas fa-long-arrow-alt-left"></i>
                                  <span className="d-inline-block back-to-cart">
                                    Back to cart
                                  </span>
                                </a>
                              </div>
                              <div className="col-md-6 checkout-button-group">
                                <button
                                  type="submit"
                                  className="btn payment-checkout-btn payment-checkout-btn-step float-end"
                                  data-processing-text="Processing. Please wait..."
                                  data-error-header="Error"
                                >
                                  Checkout
                                </button>
                              </div>
                            </div>
                            <div className="d-block d-md-none back-to-cart-button-group">
                              <a
                                className="text-info"
                                href="https://gracetoko.com/cart"
                              >
                                <i className="fas fa-long-arrow-alt-left"></i>
                                <span className="d-inline-block">
                                  Back to cart
                                </span>
                              </a>
                            </div>
                          </div> */}
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Checkout;
