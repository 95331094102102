import React, { useState } from 'react';
import ShowVariants from '../../Components/Common/ShowVariants';
import { formatRupiah, persenDiscount } from '../../Components/Helper';
import Checkbox from '../../Components/Checkbox';

const PrintPrice = ({ saleprice, price }) => {
    if (parseInt(saleprice) > 0) {
        return (
            <>
                <div className="item__content__info__price tx-primary">
                    {formatRupiah(saleprice)}
                </div>
                <div className="item__content__info__old-price">
                    <div className="item__content__info__old-price--value">
                        {formatRupiah(price)}
                    </div>
                    <div className="item__content__info__old-price--discount">
                        {persenDiscount(saleprice, price)}{"%"}
                    </div>
                </div>
            </>
        )

    } else {
        return (
            <>
                <div className="item__content__info__price tx-primary">
                    {formatRupiah(saleprice)}
                </div>
            </>
        )

    }
}

const CartPageDetail = ({ item, storeName, storeUrl, onItemDelete, onPlusQty, onMinusQty, onChangeQty, onCheckStateItem }) => {

    const [stateQty, setStateQty] = useState(item.qtyInCart)

    return (
        <>
            <div className="item-wrapper box">
                <div className="item">
                    <div className="item__checkbox-and-image-wrapper">
                        <Checkbox
                            typeCheck={'CartItem'}
                            valueCheck={item.itemcode}
                            currentCheckState={parseInt(item.checkStatus)}
                            onChangeCheckState={onCheckStateItem}
                        />
                        <a href="/p/oem-sk-p61-celana-dalam-wanita-polos-list-pita-cd-cewek-import-underwear-wanita-sempak-wanita-sexy/is--SAK-70040-00549-00008" className="item__image">
                            <img
                                src="https://www.static-src.com/wcsstore/Indraprastha//images/catalog/thumbnail//111/MTA-19554978/oem_oem_sk-p61_celana_dalam_wanita_polos_list_pita_cd_cewek_import_-_underwear_wanita_-_sempak_wanita_sexy_full15_gp1iptfo.jpg?output-format=webp" data-src="https://www.static-src.com/wcsstore/Indraprastha//images/catalog/thumbnail//111/MTA-19554978/oem_oem_sk-p61_celana_dalam_wanita_polos_list_pita_cd_cewek_import_-_underwear_wanita_-_sempak_wanita_sexy_full15_gp1iptfo.jpg?output-format=webp"
                                alt=""
                                className="lazyImage"
                                data-loaded="true"
                            />
                        </a>
                    </div>
                    <div className="item__content">
                        <div className="item__content__info" style={{ boxSizing: "inherit" }}>
                            <a href={`/${storeUrl}/${item.url}`} className="tx-black">
                                <div className='item__content__info__name'>
                                    {item.title}
                                </div>
                                <div className='item__content__info__attribute'>
                                    <ShowVariants id={item.itemcode} />
                                </div>
                            </a>
                            <PrintPrice saleprice={item.saleprice} price={item.price} />
                        </div>
                        <div className="item__action">
                            <div className="item__action__icon-wrapper">
                                <div className="wishlist item__action--wishlist">
                                    <div className="wishlist__icon">
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            fill="currentColor"
                                            preserveAspectRatio="xMidYMid meet"
                                            width="24"
                                            height="24"
                                            viewBox="0 0 24 24"
                                            className="wishlist__icon__gray">
                                            <path transform="translate(-658 -625) translate(658 625)" d="M20.417 4.798c-2.092-2.39-5.525-2.399-7.628-.02l-.788 1.062H12l-.788-1.062c-2.103-2.379-5.536-2.37-7.628.02-1.908 2.18-2.117 5.633-.489 8.092 1.698 2.565 4.348 5.931 6.46 7.423.676.478 1.611.69 2.445.687h.002c.833.001 1.77-.209 2.446-.687 2.11-1.492 4.761-4.858 6.46-7.423 1.627-2.459 1.418-5.912-.49-8.092z"></path>
                                        </svg>
                                    </div>
                                </div>
                                <div className="item__action--delete">
                                    <svg
                                        onClick={() => onItemDelete(item.itemcode)}
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="currentColor"
                                        preserveAspectRatio="xMidYMid meet"
                                        width="24"
                                        height="24"
                                        viewBox="0 0 24 24">
                                        <path transform="translate(-552 -1290) translate(552 1290) translate(4 1)" d="M14.715 8.203c-.076.015-.147.042-.225.051l-.755.088c-1.895.22-3.825.331-5.735.331-1.91 0-3.839-.112-5.735-.331l-.756-.088c-.078-.009-.149-.036-.225-.051-.38 3.338-.375 6.709.042 10.044.154 1.23 1.112 2.205 2.34 2.38 2.874.411 5.793.411 8.67 0 1.226-.175 2.184-1.15 2.338-2.38.416-3.335.422-6.706.041-10.044zM7 17c0 .552-.448 1-1 1s-1-.448-1-1v-4c0-.552.448-1 1-1s1 .448 1 1v4zm3-5c.552 0 1 .448 1 1v4c0 .552-.448 1-1 1s-1-.448-1-1v-4c0-.552.448-1 1-1zM8 0c2.301 0 4.224 1.571 4.804 3.691.534.053 1.07.088 1.603.159.696.092 1.275.541 1.497 1.159.37 1.03-.368 2.117-1.528 2.251l-.755.088c-3.732.433-7.507.433-11.239 0l-.756-.088C.465 7.126-.274 6.039.096 5.009c.222-.618.801-1.067 1.496-1.159.532-.071 1.068-.106 1.603-.159C3.775 1.571 5.698 0 8 0zm0 2c-1.11 0-2.07.613-2.589 1.512 1.725-.091 3.453-.091 5.178 0C10.07 2.613 9.11 2 8 2z"></path>
                                    </svg>
                                </div>
                            </div>

                            {
                                parseInt(item.checkStatus) > 0 &&
                                <div className="quantity-input box-group b-horizontal " style={{ borderRadius: "8px", marginBottom: "0", border: "1px solid #d6d6d6" }}>
                                    <button
                                        onClick={() => onMinusQty(item.itemcode, item.qtyInCart)}
                                        className="quantity-input--btn box"
                                        style={{
                                            backgroundColor: "#fff",
                                            borderRadius: "10px",
                                            border: "0px solid #d6d6d6"
                                        }}
                                    >-</button>
                                    <div className="quantity-input--value " style={{ marginRight: "6px", borderLeft: "1px solid #d6d6d6", borderRight: "1px solid #d6d6d6" }} >
                                        <input
                                            aria-hidden="false"
                                            type="number"
                                            min="1"
                                            name='qtyInCart'
                                            value={stateQty}
                                            className="quantity-input--field"
                                            onChange={(e) => {
                                                setStateQty((prev) => Number(e.target.value))                                            // console.log(stateQty)
                                                onChangeQty(item.itemcode, e.target.value, stateQty)
                                            }}
                                        />
                                    </div>
                                    <button
                                        onClick={() => onPlusQty(item.itemcode, item.qtyInCart)}
                                        className="quantity-input--btn"
                                        style={{
                                            backgroundColor: "#fff",
                                            borderRadius: "8px",
                                            border: "0px solid #d6d6d6"
                                        }}
                                    >+</button>
                                </div>
                            }


                        </div>
                    </div>
                </div>
            </div>
        </>

    )
}

export default CartPageDetail