import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import CartMini from "../Common/CartMini";
import SearchForm from "../SearchForm";

function HeaderMobile(props) {
  const [sticky, setSticky] = useState("header header--mobile");

  useEffect(() => {
    window.addEventListener("scroll", isSticky);
    return () => {
      window.removeEventListener("scroll", isSticky);
    };
  }, []);


  const isSticky = () => {
    const scrollTop = window.scrollY;
    const stickyClass = scrollTop >= 20 ? "header header--mobile header--mobile-product header--sticky" : "header header--mobile header--mobile-product";
    setSticky(stickyClass);
  };


  return (
    <header className={sticky} data-sticky="true">
      {/* <div className="header__top">
        <div className="header__left">
          <p>Welcome to Martfury Online Shopping Store !</p>
        </div>
        <div className="header__right">
          <ul className="navigation__extra">
            <li>
              <a href="#">Sell on Martfury</a>
            </li>
            <li>
              <a href="#">Tract your order</a>
            </li>
          </ul>
        </div>
      </div> */}
      <div className="navigation--mobile" style={{ backgroundColor: "#2878FE" }}>
        <div className="navigation__left">
          <a className="ps-logo" href="/">
            <img
              src={process.env.PUBLIC_URL + "/assets/img/logo_light.png"}
              alt=""
            />
          </a>
        </div>
        <div className="navigation__right">
          <div className="header__actions">
            <CartMini />

            {/* <div className="ps-block--user-header" style={{ color: "white" }}>
              <div className="ps-block__left" >
                <a href="my-account.html">
                  <i className="icon-user" style={{ color: "white" }} ></i>
                </a>
              </div>
              <div className="ps-block__right">
                <a href="my-account.html">Login</a>
                <a href="my-account.html">Register</a>
              </div>
            </div> */}
          </div>
        </div>
      </div>
      <div className="ps-search--mobile" >
        <SearchForm typeBrowser="mobile" />

        {/* <SearchProducts type="mobile" /> */}
      </div>
    </header>
  );
}

HeaderMobile.propTypes = {};

export default HeaderMobile;
