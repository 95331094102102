import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import axios from '../../hooks/useAxios';
import Breadcrumb from "../../Components/Common/Breadcrumb";
import ProductFooter from "../../Components/Footer/ProductFooter";
import PopupProduct from "../../Components/Common/PopupProduct";
import HeaderPanelSidebar from "../../Components/Header/HeaderPanelSidebar";
import HeaderMobileCart from "../../Components/Header/HeaderMobileCart";
import RelatedProduct from "../../Components/Common/RelatedProduct";
import Sanitized from "../../Components/Sanitized";
import SlidersProducts from "./SlidersProducts";
import { HeaderProduct } from "./HeaderProduct";
import { ContentProduct } from "./ContentProduct";
import Header from "../../Components/Header/Header";
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/thumbs';
import CategoriesMaps from "./CategoriesMaps";
import { VariantsProduct } from "./VariantsProduct";
import styled from "styled-components";
import "./variants-dropdown.css";
import { addProductToCart } from "../../config/actions/cartActions";
import { listCartitems } from "../../config/actions/cartActions";
import { errorPopup, successPopup } from "../../Components/PopupAlert";

const Product = ({ data }) => {

  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { isLoggedIn } = useSelector(state => state.userReducer);
  const cartItemsList = useSelector(state => state.cartItemsList);
  const { loading, error, cartItems } = cartItemsList;

  const shoplink = location.pathname.split("/")[1] || "";
  const productlink = location.pathname.split("/")[2] || "";
  let persenStars = 0;
  let recordReviews = 0;
  let imagesProduct = [];

  if (data[0].reviews != null) {
    let valueStars = data[0].reviews.stars / data[0].reviews.records;
    persenStars = (valueStars * 100) / 5;
    recordReviews = data[0].reviews.records;
  }
  imagesProduct = JSON.parse(data[0].images);

  const arrCumb = [
    {
      "product_id": data[0]?.id,
      "product_name": data[0]?.name
    }
  ]

  const [dataAttribute, setDataAttribute] = useState(null)
  const [itemPrice, setItemPrice] = useState(data[0].price) || 0;
  const [itemSalePrice, setItemSalePrice] = useState(data[0].sale_price) || 0;
  const [itemCode, setItemCode] = useState(0);
  const [itemName, setItemName] = useState(data[0].name) || "";
  const [itemStock, setItemStock] = useState(data[0].quantity) || 0;
  const [variant, setVariant] = useState(0);
  const [variantLevel, setVariantLevel] = useState(0);
  const [errorMessage, setErrorMessage] = useState("");
  const [dataProduct, setDataProduct] = useState(null)
  const [clickButton, setClickButton] = useState(false);
  const [orderQty, setOrderQty] = useState(1);


  const ShowPriceProduct = ({ itemSalePrice, itemPrice }) => {

    if (itemSalePrice === null || itemSalePrice === undefined || itemSalePrice === 0) {
      return (
        <>
          <span>Rp {itemPrice.toLocaleString()} </span>{" "}
        </>
      )

    } else {
      let discount = Math.ceil((itemPrice / itemSalePrice) * 100) - 100

      return (
        <>
          <span>Rp {itemSalePrice.toLocaleString()}</span>
          <div style={{
            display: "flex",
            alignItems: "center",
            marginBottom: "4px",
          }}>
            <div style={{
              display: "flex",
              WebkitBoxPack: "center",
              justifyContent: "center",
              WebkitBoxAlign: "center",
              alignItems: "center",
              height: "20px",
              color: "#F94D63",
              backgroundColor: "#FFDBE2",
              fontSize: "1.2rem",
              fontWeight: "bold",
              borderRadius: "3px",
              margin: "0px 4px 0px 0px"
            }}>&nbsp;{discount}%&nbsp;</div>
            <div style={{
              fontSize: "1.5rem",
              color: "#6D7588",
              textDecoration: "line-through",
              maxWidth: "100%",
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
              overflow: "hidden"
            }}>Rp {itemPrice.toLocaleString()}</div>
          </div>
        </>
      )
    }


  }

  function SetQuantity(status) {
    if (itemCode > 0) {
      if (status === "minus") {
        if (orderQty > 1) {
          setOrderQty(orderQty - 1);
        }
      } else {
        setOrderQty(orderQty + 1);
      }
    } else {
      setErrorMessage("Silahkan pilih varian");
    }
  }

  async function handleCart(btnStatus) {
    setClickButton(0);
    if (isLoggedIn === false) {
      navigate('/login', { state: { pathname: location.pathname } })
    } else {
      if (variantLevel > 0) {
        if (itemCode === 0) {
          setErrorMessage("Silahkan pilih varian")
          errorPopup("Silahkan pilih varian", 3000)
        } else {
          // console.log("Data Product Variant : ", dataProduct);
          // console.log("Data Product : ", data[0]);
          await dispatch(addProductToCart(dataProduct[0], data[0]))
          // console.log(dataProduct[0]);
          successPopup(dataProduct[0].name + " berhasil di tambahkan ...", 3000)
          dispatch(listCartitems())
        }
      } else {
        console.log(data[0]);
        // console.log("Data Product : ", data[0]);
        await dispatch(addProductToCart(data[0]))
        successPopup(data[0].name + " berhasil di tambahkan ...", 3000)
        dispatch(listCartitems())
      }
    }
  }


  const getDataProductVariant = async () => {
    try {
      const res = await axios.get(`/dataproduct/${itemCode}`);
      setItemPrice(res.data[0].price);
      setItemStock(res.data[0].quantity);
      setItemSalePrice(res.data[0].sale_price);
      setDataProduct(res.data);
      console.log("Variant : ", res.data);
    } catch (error) {
      console.log(error)
    }
  }

  const getDataAttribute = async () => {
    try {
      const res = await axios.get(`/productattributeset/${data[0]?.id}`);
      setDataAttribute(res.data);
      setVariantLevel(res.data.length);
      console.log("Attribute :", res.data);
    } catch (error) {
      console.log(error)
    }
  }

  const getProductVariant = async () => {
    if (variantLevel > 0) {
      if (variantLevel === 2) {
        if (variant.varian2 > 0) {
          try {
            const res = await axios.get(`/productattr?product_id=${data[0]?.id}&attr1=${variant.varian1}&attr2=${variant.varian2}`);
            setItemCode(res.data[0].product_id);
          } catch (error) {
            console.log(error);
          }
        }
      } else {
        try {
          const res = await axios.get(`/productattr?product_id=${data[0]?.id}&attr1=${variant.varian1}`);
          setItemCode(res.data[0].product_id);
        } catch (error) {
          console.log(error);
        }
      }
    }
  }

  useEffect(() => {
    if (clickButton > 0) {
      handleCart("cart");
    }
  }, [clickButton])

  useEffect(() => {
    if (itemCode > 0) {
      console.log("Query Data" + itemCode);
      getDataProductVariant();
    }
  }, [itemCode])

  useEffect(() => {
    getDataAttribute();
    setErrorMessage("");
    if (dataAttribute?.length > 0) {
      setItemCode(0);
      getProductVariant();
    }
  }, [variant])

  return (
    <>
      <div id="product-page" >
        <div id="alert-container"></div>
        <HeaderProduct product={data} persenstar={persenStars} reviewsrec={recordReviews} variant={variant} dataProduct={dataProduct} varianLevel={variantLevel} setClickButton={handleCart} />
        <HeaderMobileCart product={data} setClickButton={handleCart} />
        <Header />
        <Breadcrumb pagename={arrCumb} />
        <div className="ps-container" id="isi">
          <div className="mt-40 mb-40" style={{ marginTop: "30px" }}>
            <div className="ps-page--product" style={{ paddingTop: "1px" }}>
              <div className="ps-container">
                <div className="ps-page__container">
                  <div className="ps-page__left">
                    <div className="ps-product--detail ps-product--fullwidth">
                      <div className="ps-product__header">
                        <div
                          className="ps-product__thumbnail"
                          data-vertical="true"
                        >
                          <figure>
                            <div className="ps-wrapper">
                              <div
                                className="ps-product__gallery"
                                data-arrow="true"
                              >
                                {
                                  imagesProduct &&
                                  <SlidersProducts images={imagesProduct} />
                                }

                              </div>
                            </div>
                          </figure>
                          {/* <div
                              className="ps-product__variants"
                              data-item="4"
                              data-md="4"
                              data-sm="4"
                              data-arrow="false"
                            >
                              <div className="item">
                                <img
                                  src="http://gracetoko.com/storage/products/2-150x150.jpg"
                                  alt="Smart Watches"
                                />
                              </div>
                              <div className="item">
                                <img
                                  src="http://gracetoko.com/storage/products/2-1-150x150.jpg"
                                  alt="Smart Watches"
                                />
                              </div>
                              <div className="item">
                                <img
                                  src="http://gracetoko.com/storage/products/2-2-150x150.jpg"
                                  alt="Smart Watches"
                                />
                              </div>
                              <div className="item">
                                <img
                                  src="http://gracetoko.com/storage/products/2-3-150x150.jpg"
                                  alt="Smart Watches"
                                />
                              </div>
                            </div> */}
                        </div>
                        <div className="ps-product__info">
                          <h1>{data[0]?.name}</h1>
                          <div className="ps-product__meta">
                            {/* <p>
                                Brand:{" "}
                                <a href="http://gracetoko.com/brands/sunshine">
                                  Sunshine
                                </a>
                              </p> */}

                            <div className="rating_wrap">
                              <a href={void (0)}>
                                <div className="rating">
                                  <div
                                    className="product_rate"
                                    style={{ "width": `${persenStars}%` }}
                                  ></div>
                                </div>
                                <span className="rating_num">( {recordReviews || 0} reviews )</span>
                              </a>
                            </div>
                          </div>
                          <h4 className="ps-product__price ">
                            {/* {itemPrice}
                            {itemSalePrice} */}
                            <ShowPriceProduct itemSalePrice={itemSalePrice} itemPrice={itemPrice} />
                            {/* {itemCode} */}
                          </h4>
                          <VariantsProduct errorMessage={errorMessage} name={variant} parent_product={data} onDataChange={setVariant} />

                          {/* <div className="pr_switch_wrap">
                              <div
                                className="product-attributes"
                                data-target="http://gracetoko.com/product-variation/2"
                              >
                                <div
                                  className="visual-swatches-wrapper attribute-swatches-wrapper form-group product__attribute product__color"
                                  data-type="visual"
                                >
                                  <label className="attribute-name">Color</label>
                                  <div className="attribute-values">
                                    <ul className="visual-swatch color-swatch attribute-swatch">
                                      <li
                                        data-slug="red"
                                        data-id="3"
                                        className="attribute-swatch-item "
                                        title="Red"
                                      >
                                        <div className="custom-radio">
                                          <label>
                                            <input
                                              className="form-control product-filter-item"
                                              type="radio"
                                              name="attribute_color"
                                              value="3"
                                              //   checked={true}
                                            />
                                            <span
                                              style={{ backgroundColor: "#DA323F" }}
                                            ></span>
                                          </label>
                                        </div>
                                      </li>
                                      <li
                                        data-slug="back"
                                        data-id="4"
                                        className="attribute-swatch-item "
                                        title="Black"
                                      >
                                        <div className="custom-radio">
                                          <label>
                                            <input
                                              className="form-control product-filter-item"
                                              type="radio"
                                              name="attribute_color"
                                              value="4"
                                            />
                                            <span
                                              style={{ backgroundColor: "#2F366C" }}
                                            ></span>
                                          </label>
                                        </div>
                                      </li>
                                      <li
                                        data-slug="brown"
                                        data-id="5"
                                        className="attribute-swatch-item "
                                        title="Brown"
                                      >
                                        <div className="custom-radio">
                                          <label>
                                            <input
                                              className="form-control product-filter-item"
                                              type="radio"
                                              name="attribute_color"
                                              value="5"
                                            />
                                            <span
                                              style={{ backgroundColor: "#87554B" }}
                                            ></span>
                                          </label>
                                        </div>
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                                <div
                                  className="text-swatches-wrapper attribute-swatches-wrapper attribute-swatches-wrapper form-group product__attribute product__color"
                                  data-type="text"
                                >
                                  <label className="attribute-name">Size</label>
                                  <div className="attribute-values">
                                    <ul className="text-swatch attribute-swatch color-swatch">
                                      <li
                                        data-slug="s"
                                        data-id="6"
                                        className="attribute-swatch-item  pe-none "
                                      >
                                        <div>
                                          <label>
                                            <input
                                              className="product-filter-item"
                                              type="radio"
                                              name="attribute_size"
                                              value="6"
                                            />
                                            <span>S</span>
                                          </label>
                                        </div>
                                      </li>
                                      <li
                                        data-slug="l"
                                        data-id="8"
                                        className="attribute-swatch-item "
                                      >
                                        <div>
                                          <label>
                                            <input
                                              className="product-filter-item"
                                              type="radio"
                                              name="attribute_size"
                                              value="8"
                                              //   checked={true}
                                            />
                                            <span>L</span>
                                          </label>
                                        </div>
                                      </li>
                                      <li
                                        data-slug="xl"
                                        data-id="9"
                                        className="attribute-swatch-item  pe-none "
                                      >
                                        <div>
                                          <label>
                                            <input
                                              className="product-filter-item"
                                              type="radio"
                                              name="attribute_size"
                                              value="9"
                                            />
                                            <span>XL</span>
                                          </label>
                                        </div>
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                              </div>
                            </div> */}
                          <div className="number-items-available mb-3">
                            <span className="text-success">
                              ( {itemStock} products available )
                            </span>
                          </div>

                          <div className="ps-product__desc">
                            <p>
                              Sold By:{" "}
                              <a href={`/${data[0]?.mp_stores.key}`}>
                                <strong>{data[0]?.mp_stores.name}</strong>
                              </a>
                            </p>


                            <div className="ps-list--dot">
                              <Sanitized html={data[0]?.description} />
                            </div>
                          </div>
                          <form
                            className="add-to-cart-form"
                            method="POST"
                            action="http://gracetoko.com/cart/add-to-cart"
                          >
                            <input
                              type="hidden"
                              name="_token"
                              value="p7mk0zaIfBF0EVima8gKki0s2clCxFcsOuXOWopE"
                            />
                            <div className="ps-product__shopping">
                              <figure
                                style={{ marginTop: "1px" }}
                              >
                                <figcaption>Quantity</figcaption>
                                <div className="form-group--number product__qty">
                                  <button className="up" type="button" onClick={() => SetQuantity('plus')}>
                                    <i className="icon-plus"></i>
                                  </button>
                                  <button className="down" type="button" onClick={() => SetQuantity('minus')}>
                                    <i className="icon-minus"></i>
                                  </button>
                                  <input
                                    className="form-control qty-input"
                                    type="text"
                                    name="qty"
                                    value={orderQty}
                                    placeholder={orderQty}
                                    readOnly={true}
                                  />
                                </div>
                              </figure>
                              <input
                                type="hidden"
                                name="id"
                                className="hidden-product-id"
                                value="26"
                              />
                              <button
                                className="ps-btn "
                                type="button"
                                name="checkout"
                                onClick={() => handleCart("cart")}
                                style={{ backgroundColor: "#fff", border: "1px solid black", height: "40px", padding: "8px 20px", borderColor: "#4CAF50", color: "#4CAF50", fontWeight: "400", marginRight: "10px" }}
                              >
                                Keranjang
                              </button>
                              <button
                                className="ps-btn "
                                type="button"
                                name="checkout"
                                onClick={() => handleCart("directbuy")}
                                style={{ backgroundColor: "#fff", border: "1px solid black", height: "40px", padding: "8px 20px", borderColor: "#1e90ff", color: "#1e90ff", fontWeight: "400" }}
                              >
                                Beli Langsung
                              </button>
                              <div className="ps-product__actions">
                                <a
                                  className="js-add-to-wishlist-button"
                                  href="#"
                                  data-url="http://gracetoko.com/wishlist/2"
                                >
                                  <i className="icon-heart"></i>
                                </a>
                                {/* <a
                                    className="js-add-to-compare-button"
                                    href="#"
                                    data-url="http://gracetoko.com/compare/2"
                                    title="Compare"
                                  >
                                    <i className="icon-chart-bars"></i>
                                  </a> */}
                              </div>
                            </div>
                          </form>
                          <div className="ps-product__specification">
                            <p>
                              <strong>SKU:</strong>{" "}
                              <span id="product-sku">{data[0]?.sku}</span>
                            </p>
                            <p className="categories">
                              <strong> Categories:</strong>
                              <CategoriesMaps product_id={data[0]?.id} />
                            </p>

                            {/* <p className="tags">
                                <strong> Tags:</strong>
                                <a href="http://gracetoko.com/product-tags/electronic">
                                  Electronic
                                </a>
                                ,{" "}
                                <a href="http://gracetoko.com/product-tags/printer">
                                  Printer
                                </a>
                                ,{" "}
                                <a href="http://gracetoko.com/product-tags/office">
                                  Office
                                </a>{" "}
                              </p> */}

                          </div>
                          <div className="ps-product__sharing">
                            <a
                              className="facebook"
                              href="https://www.facebook.com/sharer/sharer.php?u=http%3A%2F%2Fgracetoko.com%2Fproducts%2Fsmart-watches"
                              target="_blank"
                            >
                              <i className="fa fa-facebook"></i>
                            </a>
                            <a
                              className="linkedin"
                              href="https://www.linkedin.com/shareArticle?mini=true&url=http%3A%2F%2Fgracetoko.com%2Fproducts%2Fsmart-watches&summary= Unrestrained and portable active stereo speaker
            Free from the confines of wires and chords
            20 hours of portable capabilities
            Double-ended Coil Cord with 3.5mm Stereo Plugs Included
            3/4″ Dome Tweeters: 2X and 4″ Woofer: 1X"
                              target="_blank"
                            >
                              <i className="fa fa-linkedin"></i>
                            </a>
                            <a
                              className="twitter"
                              href="https://twitter.com/intent/tweet?url=http%3A%2F%2Fgracetoko.com%2Fproducts%2Fsmart-watches&text= Unrestrained and portable active stereo speaker
            Free from the confines of wires and chords
            20 hours of portable capabilities
            Double-ended Coil Cord with 3.5mm Stereo Plugs Included
            3/4″ Dome Tweeters: 2X and 4″ Woofer: 1X"
                              target="_blank"
                            >
                              <i className="fa fa-twitter"></i>
                            </a>
                          </div>
                        </div>
                      </div>

                      <ContentProduct product={data} />
                    </div>
                  </div>
                  <div className="ps-page__right">
                    <aside className="widget widget_product widget_features">
                      <p>
                        <i className="icon-network"></i> Shipping worldwide
                      </p>
                      <p>
                        <i className="icon-3d-rotate"></i> Free 7-day return if
                        eligible, so easy
                      </p>
                      <p>
                        <i className="icon-receipt"></i> Supplier give bills for
                        this product.
                      </p>
                      <p>
                        <i className="icon-credit-card"></i> Pay online or when
                        receiving goods
                      </p>
                    </aside>
                    <aside className="widget">
                      <div>
                        <a
                          href="http://gracetoko.com/ads-click/2UDULECZWG9X"
                          target="_blank"
                        >
                          <img
                            src="http://gracetoko.com/storage/promotion/8.jpg"
                            style={{ maxWidth: "100%" }}
                            alt="Product sidebar"
                          />
                        </a>
                      </div>
                    </aside>
                  </div>
                </div>
                <RelatedProduct />
              </div>
            </div>
          </div>
        </div>

        <ProductFooter />

        <div data-session-domain="gracetoko.com"></div>

        <PopupProduct />

        <nav className="navigation--mobile-product">
          <button
            onClick={() => handleCart("cart")}
            className="ps-btn ps-btn--black "
            style={{ borderRadius: "0px" }}
            type="button">Keranjang
          </button>
          <button className="ps-btn " type="submit" style={{ borderRadius: "0px" }} name="checkout">Beli</button>
        </nav>

        <div id="back2top">
          <i className="icon icon-arrow-up"></i>
        </div>

        <div className="ps-site-overlay"></div>

        <div className="ps-search" id="site-search">
          <a className="ps-btn--close" href="#"></a>
          <div className="ps-search__content">
            <form
              className="ps-form--primary-search"
              action="http://gracetoko.com/products"
              data-ajax-url="http://gracetoko.com/ajax/search-products"
              method="get"
            >
              <input
                className="form-control input-search-product"
                name="q"
                type="text"
                autoComplete="off"
                placeholder="Search for..."
              />
              <div className="spinner-icon">
                <i className="fa fa-spin fa-spinner"></i>
              </div>
              <button>
                <i className="aroma-magnifying-glass"></i>
              </button>
              <div className="ps-panel--search-result"></div>
            </form>
          </div>
        </div>

        <div
          className="modal fade"
          id="product-quickview"
          tabIndex={-1}
          role="dialog"
          aria-labelledby="product-quickview"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content">
              <span className="modal-close" data-dismiss="modal">
                <i className="icon-cross2"></i>
              </span>
              <article className="ps-product--detail ps-product--fullwidth ps-product--quickview"></article>
            </div>
          </div>
        </div>
      </div >
    </>

  );
}

export default Product;