import React from 'react'

const BlockProducts = ({dataproduct}) => {

    let persenStars = 0;
    let labels = dataproduct.ec_product_labels;
    let stores = dataproduct.mp_stores;
    let reviews = dataproduct.reviews;
    if (reviews.stars !== null) {
        persenStars = ((parseInt(reviews.stars) / parseInt(reviews.records)) * 100 / 5)
    }


  return (
    <div className="ps-product ps-product--inner">
        <div className="ps-product__thumbnail">
            <a href={`/${dataproduct.mp_stores.key}/${dataproduct.slugs_key}`}>
                <img src={`https://gracetoko.com/storage/products/${dataproduct.images[0]}`}
                    onError={(e)=>{e.target.onerror = null; e.target.src=process.env.PUBLIC_URL+"/assets/img/placeholder.png"}}
                    alt={dataproduct.name}
                />
            </a>

            { labels.name !== null ? 
                <div className="ps-product__badge" style={{ "backgroundColor": `${labels.color}`} }>
                    {labels.name}
                </div> : ''
                // <div className="ps-product__badge">-39%</div>
            }


            <ul className="ps-product__actions">
                <li><a className="add-to-cart-button" data-id={dataproduct.id} href={void(0)} data-url="/add-to-cart" title="Add To Cart"><i className="icon-bag2"></i></a></li>
                <li><a href={void(0)} data-url={`/${dataproduct.mp_stores.key}/${dataproduct.slugs_key}`} title="Quick View" className="js-quick-view-button"><i className="icon-eye"></i></a></li>
                <li><a className="js-add-to-wishlist-button" href={void(0)} data-url={`/wishlist/${dataproduct.slugs_key}`} title="Add to Wishlist"><i className="icon-heart"></i></a></li>
            </ul>
        </div>
        <div className="ps-product__container">

            {
                dataproduct.sale_price > 0 ? 
                <p className="ps-product__price sale ">Rp. &nbsp;
                     {dataproduct.sale_price.toLocaleString()} 
                    &nbsp;
                    <del>
                        <span style={{  fontSize:"12px",}}>
                            {dataproduct.price}
                        </span>
                    </del> 
                </p> :
                <p className="ps-product__price ">Rp. {dataproduct.price.toLocaleString()} </p> 
            }


            <div className="ps-product__content">
                <p className="ps-product__vendor">
                    <span>Sold by : </span>
                    <a href={`/${dataproduct.mp_stores.key}`} className="text-uppercase">{dataproduct.mp_stores.name}</a>
                </p>
                <a className="ps-product__title" href={`/${dataproduct.mp_stores.key}/${dataproduct.slugs_key}`}>{dataproduct.name}</a>
                <div className="rating_wrap">
                    <div className="rating">
                        {/* <div className="product_rate" style={{width: "80%"}}></div> */}
                        <div className="product_rate" style={{ "width": `${persenStars}%` }} ></div>
                    </div>
                    <span className="rating_num">{reviews.records || 0}</span>
                </div>
                {/* <div className="ps-product__progress-bar ps-progress" data-value="25">
                <div className="ps-progress__value"><span style={{width: "25%"}}></span></div>
                    <p>Sold: 2</p>
                </div> */}
            </div>
        </div>
    </div>
  )
}

export default BlockProducts