import React from "react";

function BlockCategories() {

    return (

        <>
        <div className="row justify-content-center">
          <div className="col-xl-2 col-lg-3 col-md-4 col-sm-4 col-6">
            <div className="ps-block--category">
              <a href="/product-categories/electronics" className="ps-block__overlay"></a>
              <img src="https://martfury.botble.com/storage/product-categories/1-300x300.jpg" alt="Electronics" /> 
              <p>Electronics</p>
            </div>
          </div>
          <div className="col-xl-2 col-lg-3 col-md-4 col-sm-4 col-6">
            <div className="ps-block--category">
              <a href="/product-categories/clothing" className="ps-block__overlay"></a>
              <img src="https://martfury.botble.com/storage/product-categories/2-300x300.jpg" alt="Clothing" /> <p>Clothing</p>
            </div>
          </div>
          <div className="col-xl-2 col-lg-3 col-md-4 col-sm-4 col-6">
            <div className="ps-block--category">
              <a href="/product-categories/computers" className="ps-block__overlay"></a>
              <img src="https://martfury.botble.com/storage/product-categories/3-300x300.jpg" alt="Computers"/> 
              <p>Computers</p>
            </div>
          </div>
          <div className="col-xl-2 col-lg-3 col-md-4 col-sm-4 col-6">
            <div className="ps-block--category">
              <a href="/product-categories/home-kitchen" className="ps-block__overlay"></a>
              <img src="https://martfury.botble.com/storage/product-categories/4-300x300.jpg" alt="Home &amp; Kitchen" /> 
              <p>Home &amp; Kitchen</p>
            </div>
          </div>
          <div className="col-xl-2 col-lg-3 col-md-4 col-sm-4 col-6">
            <div className="ps-block--category">
              <a href="/product-categories/health-beauty" className="ps-block__overlay"></a>
              <img src="https://martfury.botble.com/storage/product-categories/5-300x300.jpg" alt="Health &amp; Beauty"/> 
              <p>Health &amp; Beauty</p>
            </div>
          </div>
          <div className="col-xl-2 col-lg-3 col-md-4 col-sm-4 col-6">
            <div className="ps-block--category">
              <a href="/product-categories/jewelry-watch" className="ps-block__overlay"></a>
              <img src="https://martfury.botble.com/storage/product-categories/6-300x300.jpg" alt="Jewelry &amp; Watch" /> 
              <p>Jewelry &amp; Watch</p>
            </div>
          </div>
          <div className="col-xl-2 col-lg-3 col-md-4 col-sm-4 col-6">
            <div className="ps-block--category">
              <a href="/product-categories/technology-toys" className="ps-block__overlay"></a>
              <img src="https://martfury.botble.com/storage/product-categories/7-300x300.jpg" alt="Technology Toys" /> 
              <p>Technology Toys</p>
            </div>
          </div>
          <div className="col-xl-2 col-lg-3 col-md-4 col-sm-4 col-6">
            <div className="ps-block--category">
              <a href="/product-categories/phones" className="ps-block__overlay"></a>
              <img src="https://martfury.botble.com/storage/product-categories/8-300x300.jpg" alt="Phones" /> 
              <p>Phones</p>
            </div>
          </div>
        </div>
      </>
    


    )

}

export default BlockCategories;