import React from "react";
import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../../config/actions/auth";
import { useLocation } from "react-router-dom";

const Account = props => {

    const dispatch = useDispatch();
    const { userLogin } = useSelector(state => state.userReducer);

    const logOut = useCallback(() => {
        dispatch(logout());
    }, [dispatch]);

    const location = useLocation();
    var str = location.pathname;
    // console.log(location.pathname)
    const pageid = str.substring(str.lastIndexOf("/") + 1, str.length);
    // console.log(pageid)

    return (
        <>

            <div className="ps-panel__content">
                <ul className="menu--mobile">
                    <li className={pageid === 'overview' ? 'active' : ''}><a href="customer/overview"><i className="icon-user"></i> Account Information</a></li>
                    <li className={pageid === 'editaccount' ? 'active' : ''}><a href="customer/editaccount"><i className="icon-pencil"></i> Update profile</a></li>
                    <li className={pageid === 'orders' ? 'active' : ''}><a href="customer/orders"><i className="icon-papers"></i> Orders</a></li>
                    <li className={pageid === 'address' ? 'active' : ''}><a href="customer/address"><i className="icon-map-marker"></i> Address</a></li>
                    <li className={pageid === 'changepassword' ? 'active' : ''}><a href="customer/changepassword"><i className="icon-lock"></i> Change password</a></li>
                    {

                        userLogin?.isVendor > 0 &&
                        <li className={pageid === 'dashboard' ? 'active' : ''}><a href="customer/dashboard"><i className="icon-cart"></i> Vendor dashboard</a></li>
                    }
                    <li><a href="#" onClick={logOut} ><i className="icon-power-switch"></i> Logout</a></li>

                </ul>
            </div>
        </>
    )
}

export default Account;