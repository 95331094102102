// rafce
import React, { useEffect, useState } from "react";
// import PropTypes from "prop-types";
// import axios from "axios";
import CategoriesSubMenu from "./CategoriesSubMenu";
import axios from "../hooks/useAxios";

const CategoriesMenu = () => {
  const [categories, setCategories] = useState([]);
  const [categoriesDetail, setCategoriesDetail] = useState([]);
  // const apiurl = process.env.React_App_Gracetoko_Api;
  const getCategories = async () => {
    try {
      const response = await axios.get("/categoriesslug");
      const mydata = response.data;
      // console.log(mydata);
      const headerMenu = [];
      mydata.forEach((categ) => {
        // eslint-disable-next-line
          if (categ.parent_id == "0") {
            headerMenu.push(categ);
          }
      })
      // mydata.map((categ) => {
      // });
      setCategories(headerMenu);
      setCategoriesDetail(response.data);
    } catch (error) {
      console.log(error);
    }
  };

  const filterItems = (value) => {
    const newItem = categoriesDetail.filter((newVal) => {
      return newVal.parent_id === value;
    });
    return newItem;
  };

  useEffect(() => {
    getCategories();
  }, []);

  return (
    <>
      <div className="menu--product-categories">
        <div className="menu__toggle">
          <i className="icon-menu"></i>
          <span>Categories</span>
        </div>
        <div className="menu__content">
          <ul className="menu--dropdown">
            {categories.map((cate, index) => (
              <li
                key={cate.id}
                className={
                  filterItems(cate.id).length === 0
                    ? ""
                    : "menu-item-has-children has-mega-menu"
                }
              >
                <a href={`/product-categories/${cate.slugs.key}`}>
                  <i className="icon-star"></i>
                  {cate.name}
                </a>
                {filterItems(cate.id).length !== 0 ? (
                  <CategoriesSubMenu
                    categoriesDetail={categoriesDetail}
                    parent_id_new={cate.id}
                  />
                ) : (
                  ""
                )}
              </li>
            ))}
          </ul>
        </div>
      </div>
    </>
  );
};

export default CategoriesMenu;
