export function formatRupiah(money) {
    return new Intl.NumberFormat('id-ID',
        { style: 'currency', currency: 'IDR', minimumFractionDigits: 0 }
    ).format(money);
}

export function persenDiscount(saleprice, price) {
    let discount = Math.ceil((saleprice / price) * 100) - 100;
    return discount;
}

export function countCartQty(items) {
    let totalItems = 0;
    if (typeof items == 'object') {
        items.forEach(dtcart => {
            if (parseInt(dtcart.checkStatus) > 0) {
                totalItems += parseFloat(dtcart.qtyInCart);
            }
        });
    }
    return totalItems
}

export function summaryCartAmount(items) {
    let totalAmount = 0;
    if (typeof items == 'object') {
        items.forEach(dtcart => {
            if (parseInt(dtcart.checkStatus) > 0) {

                if (parseFloat(dtcart.saleprice) > 0) {
                    totalAmount = totalAmount + (parseInt(dtcart.qtyInCart) * parseFloat(dtcart.saleprice));
                } else {
                    totalAmount = totalAmount + (parseInt(dtcart.qtyInCart) * parseFloat(dtcart.price));
                }
            }

        });
    }
    return totalAmount
}