import React, { useState } from "react";
import CustomerSideBar from "./SideBar";
import Header from "../../Components/Header/Header";
import HeaderMobile from "../../Components/Header/HeaderMobile";
import Breadcrumb from "../../Components/Common/Breadcrumb";
import Sidebar from "../../Components/SideBar/Sidebar";
import ProductFooter from "../../Components/Footer/ProductFooter";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useFormik } from "formik";
import * as Yup from "yup";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import Loading from "../../Components/Loading";
import { useSelector } from "react-redux";

const ChangePassword = () => {
  const axiosPrivate = useAxiosPrivate();
  const { userLogin } = useSelector(state => state.userReducer);
  const [isLoading, setLoading] = useState(false);
  const notify = (status, message) => {
    if (status === "error") {
      toast.error(message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    } else {
      toast.success(message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }
  };

  const arrCumb = [
    {
      "name": "Change password",
      "link": "customer/changepassword"
    }
  ]

  const formik = useFormik({
    initialValues: {
      oldPassword: "",
      newPassword: "",
      confirmPassword: ""
    },
    validationSchema: Yup.object({
      oldPassword: Yup.string()
        .max(20, "Must be 10 Character or less")
        .required('Required'),
      newPassword: Yup.string()
        .max(20, "Must be 10 Character or less")
        .required('Required'),
      confirmPassword: Yup.string()
        .max(20, "Must be 10 Character or less")
        .required('Required')
        .when("newPassword", {
          is: (val) => (val && val.length > 0 ? true : false),
          then: Yup.string().oneOf(
            [Yup.ref("newPassword")],
            "Confirm Password invalid"
          ),
        })
    }),
    onSubmit: async (values, { setSubmitting }) => {
      const body = {
        email: userLogin.email,
        oldPassword: values.oldPassword,
        newPassword: values.newPassword
      };
      try {
        setLoading(true);
        const res = await axiosPrivate.post("/changepassword", body)
        // const res = await axiosJWT.post(apiurl + "/changepassword", body,  {
        //   headers: {
        //     Authorization: `Bearer ${token}`,
        //   }
        // })
        setLoading(false);
        formik.resetForm({ values: '' });
        if (res.data.result === "NG") {
          notify("error", res.data.msg);
        } else {
          notify("success", res.data.msg);
        }
      } catch (error) {
        formik.resetForm({ values: '' });
        setLoading(false);
        notify("error", error.message);
      }
    }
  })

  return (
    <>
      {
        isLoading && <div><Loading /></div>
      }

      <Header />
      {/* email_account={user.email} name_account={user.name} /> */}
      <HeaderMobile />
      <Breadcrumb pagename={arrCumb} />
      <Sidebar />
      <section className="ps-section--account crop-avatar customer-page">
        <div className="container">
          <div className="row">
            <CustomerSideBar pageid="changepassword" />
            <div className="col-lg-8">
              <div className="ps-section__right">
                <form onSubmit={formik.handleSubmit} >
                  <div className="ps-form__header">
                    <h3>Change Password</h3>
                  </div>
                  <div className="ps-form__content">
                    <div className="form-group ">
                      <label htmlFor="old_password">Current password:</label>
                      <input
                        type="password"
                        className="form-control"
                        name="oldPassword"
                        value={formik.values.oldPassword}
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                        placeholder="Current Password"
                      />
                      {formik.touched.oldPassword && formik.errors.oldPassword ? (
                        <span className="text-danger">
                          {formik.errors.oldPassword}
                        </span>
                      ) : null}
                    </div>
                    <div className="form-group ">
                      <label htmlFor="password">New password:</label>
                      <input
                        type="password"
                        className="form-control"
                        name="newPassword"
                        value={formik.values.newPassword}
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                        placeholder="New Password"
                      />
                      {formik.touched.newPassword && formik.errors.newPassword ? (
                        <span className="text-danger">
                          {formik.errors.newPassword}
                        </span>
                      ) : null}
                    </div>
                    <div className="form-group ">
                      <label htmlFor="password_confirmation">Password confirmation:</label>
                      <input
                        type="password"
                        className="form-control"
                        name="confirmPassword"
                        value={formik.values.confirmPassword}
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                        placeholder="Password Confirmation"
                      />
                      {formik.touched.confirmPassword && formik.errors.confirmPassword ? (
                        <span className="text-danger">
                          {formik.errors.confirmPassword}
                        </span>
                      ) : null}
                    </div>
                    <div className="form-group text-center">
                      <div className="form-group submit">


                        {!isLoading && (
                          <button
                            className="ps-btn"
                            type="submit"
                          >
                            Update Password
                          </button>
                        )}
                        {isLoading && (
                          <button className="ps-btn" disabled>
                            <i className="fas fa-spinner fa-spin"></i>Please wait...
                          </button>
                        )}
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </>
  )
}

export default ChangePassword