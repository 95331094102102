import React from 'react';
import { stringtoSlug } from './Slug';

const CardsProducts = props => {
    let persenStars = 0;
    let product = props.data;
    let labels = product.ec_product_labels;
    let stores = product.mp_stores;
    let reviews = product.reviews;
    let link = stringtoSlug(product.name);
    if (reviews.stars !== null) {
        persenStars = ((parseInt(reviews.stars) / parseInt(reviews.records)) * 100 / 5)
    }

    const PriceView = ({sale_price, price}) => {
        if (sale_price > 0 || sale_price != null) {
            let discount = Math.ceil((sale_price / price) * 100) - 100
            return (
                <>
                    <p style={{lineHeight:"20px"}} className="ps-product__price sale ">Rp {sale_price.toLocaleString()} </p>
                    <div style={{
                        display:"flex",
                        alignItems: "center",
                        marginBottom: "4px",
                        marginTop:"-5px"
                    }}>
                        <div style={{
                            display:"flex",
                            WebkitBoxPack:"center",
                            justifyContent:"center",
                            WebkitBoxAlign:"center",
                            alignItems: "center",
                            height: "20px",
                            color: "#F94D63",
                            backgroundColor: "#FFDBE2",
                            fontSize: "1.2rem",
                            fontWeight:"bold",
                            borderRadius:"3px",
                            margin: "0px 4px 0px 0px"
                        }}>&nbsp;{discount}%&nbsp;</div>
                        <div style={{
                            fontSize:"1.2rem",
                            color: "#6D7588",
                            textDecoration: "line-through",
                            maxWidth: "100%",
                            whiteSpace: "nowrap",
                            textOverflow:"ellipsis",
                            overflow:"hidden"
                        }}>Rp {price.toLocaleString()}</div>
                    </div>
                </>
            )
        } else {
            return (
                <>
                    <p style={{lineHeight:"20px"}} className="ps-product__price ">Rp {price.toLocaleString()} </p>
                    <div style={{
                        display:"flex",
                        alignItems: "center",
                        marginBottom: "4px",
                        marginTop:"-5px"
                    }}>
                        <div style={{
                            display:"flex",
                            WebkitBoxPack:"center",
                            justifyContent:"center",
                            WebkitBoxAlign:"center",
                            alignItems: "center",
                            height: "20px",
                            color: "#F94D63",
                            backgroundColor: "#FFDBE2",
                            fontSize: "1.2rem",
                            fontWeight:"bold",
                            borderRadius:"3px",
                            margin: "0px 4px 0px 0px"
                        }}></div>
                        <div style={{
                            fontSize:"1.2rem",
                            color: "#6D7588",
                            maxWidth: "100%",
                            whiteSpace: "nowrap",
                            textOverflow:"ellipsis",
                            overflow:"hidden"
                        }}>&nbsp;</div>
                    </div>

                </>
            )
        }
    }




    return (
        <div>
            <div className="ps-product" style={{borderRadius:"2%", boxShadow:"0 0px 10px 0 rgba(0, 0, 0, 0.19)", marginBottom:"10px"}}>
                <div className="ps-product__thumbnail">
                    <a href={`/products/`}>
                        <img src={`https://gracetoko.com/storage/products/${product.images[0]}`}
                            onError={(e)=>{e.target.onerror = null; e.target.src=process.env.PUBLIC_URL+"/assets/img/placeholder.png"}}
                            alt={product.name}
                        />
                    </a>
                    { labels.name !== null ? 
                        <span className="ps-product__badge" style={{ "backgroundColor": `${labels.color}`} }>
                            {labels.name}
                        </span> : ''
                    }
                    <ul className="ps-product__actions">
                        <li>
                            <a 
                                className="add-to-cart-button"
                                data-id={product.id}
                                href={void(0)}
                                data-url="https://gracetoko.com/cart/add-to-cart"
                                title="Add To Cart"
                            >
                                <i className="icon-bag2"></i>
                            </a>
                        </li>
                        <li>
                            <a
                                className="js-quick-view-button"
                                href={void(0)}
                                data-url={`'https://gracetoko.com/ajax/quick-view/${link}'`}
                                title="Quick View"
                            >
                                <i className="icon-eye"></i>
                            </a>
                        </li>
                        <li><a
                                className="js-add-to-wishlist-button"
                                href={void(0)}
                                data-url={`/wishlist/${product.id}`}
                                title="Add to Wishlist"
                            >
                                <i className="icon-heart"></i>
                            </a>
                        </li>
                    </ul>
                </div>
                <div className="ps-product__container">
                    <a
                        className="ps-product__vendor"
                        href={`/${stores.key}/${link}`}
                     style={{
                        whiteSpace:"nowrap",
                        overflow:"hidden",
                        textOverflow:"ellipsis",
                        display:"-webkit-box",
                        WebkitLineClamp:"2",
                        WebkitBoxOrient:"vertical"

                     }}>
                        {product.name}
                    </a>
                    <div className="ps-product__content">
                        <a
                        className="ps-product__title"
                        style={{
                            whiteSpace:"nowrap",
                            overflow:"hidden",
                            textOverflow:"ellipsis",
                            display:"-webkit-box",
                            WebkitLineClamp:"2",
                            WebkitBoxOrient:"vertical"
    
                         }}
    
                        href={`/${stores.key}`}
                        >
                        {stores.name}
                        </a>

                        <div className="rating_wrap">
                            <div className="rating">
                                <div className="product_rate" style={{ "width": `${persenStars}%` }} ></div>
                            </div>
                            <span className="rating_num">({reviews.records || 0})</span>
                        </div>
                        <PriceView sale_price={product.sale_price} price = {product.price} />
                    </div>
                </div>
            </div>        
        </div>
    )
}

export default CardsProducts;