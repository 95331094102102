import CryptoJS from "crypto-js";
import axios from "../../hooks/useAxios";

const login = (email, password) => {
    return axios
        .post("/login",
            JSON.stringify({ email, password }),
        )
        .then((response) => {
            // console.log(response.data)
            if (response.data.accessToken) {
                const encdata = CryptoJS.AES.encrypt(response.data.accessToken, process.env.React_APP_SecretKey)
                localStorage.setItem("tjwt", encdata);
                localStorage.setItem("currentAddress", JSON.stringify(response.data.datauser.alamat));
            }
            return response.data
        })
}

const logout = async () => {

    localStorage.removeItem("tjwt");
    localStorage.removeItem("currentAddress");

    try {
        const response = await axios.delete('/logout', {
            withCredentials: true
        })
    } catch (error) {
        console.log(error)
    }

    // const logout_function = async () => {
    //     localStorage.removeItem("tjwt");
    //     try {
    //         const response = await axios.delete('/logout', {
    //             withCredentials: true
    //         })
    //     } catch (error) {
    //         console.log(error)
    //     }
    // }

    // logout_function()

    // return axios
    //     .delete('/logout', {
    //         withCredentials: true
    //     })
    //     .then((response) => {
    //         localStorage.removeItem("tjwt");
    //     })
}

// export const refreshToken = (accessToken) => (dispatch) => {
//     dispatch({
//         type: REFRESH_TOKEN,
//         payload: accessToken,
//     })
// }

export default { login, logout }