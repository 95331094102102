import React, {useState, useEffect} from "react";
import { useLocation } from "react-router-dom";
import ReactPaginate from "react-paginate";
import Header from "../../Components/Header/Header";
import HeaderMobile from "../../Components/Header/HeaderMobile";
import Breadcrumb from "../../Components/Common/Breadcrumb";
import Footer from "../../Components/Footer/Footer";
import Sidebar from "../../Components/SideBar/Sidebar";
import { Brands } from "../../Components/Brands";
import axios from "../../hooks/useAxios";
import WidgetSideBar from "./WidgetSidebar";
import SliderProducts from "../../Components/SliderProducts";
import CardsProducts from "../../Components/CardsProducts";

function Products() {
  const location = useLocation();
  const categories = location.pathname.split("/")[2] || "";
  const [categoriesname, setCategoriesname] = useState("");
  const [brandProducts, setBrandProducts] = useState([]);
  const [productdata, setProductdata] = useState([]);
  const [selectedBrands, setSelectedBrands] = useState(null);
  const [selectedPrice, setSelectedPrice] = useState(null);

  const [sortOrderBy, setSortOrderBy] = useState("");

  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(12);
  const [pages, setPages] = useState(0);
  const [rows, setRows] = useState(0);



  const changePage = ({selected, }) => {
    setPage(selected);
  }

  const getProducts = async() => {
    try {
      const res = await axios.get(`/products?brands=${selectedBrands || ''}&sortby=${sortOrderBy || ''}&categorylink=${categories}&page=${page}&limit=${limit}`);
      if (categories != "") {
        setCategoriesname(res.data.data[0].v_categories_product.name);
      }
      setPage(res.data.current_page);
      setRows(res.data.total_row);
      setPages(res.data.total_page);
      setProductdata(res.data.data)   
      // console.log(res.data);
    } catch (error) {
      console.log(error);
    }
  }

  const getBrands = async () => {
    try {
      const resbrands = await axios.get('/brands?is_featured=1&status=published&limit=6');
      setBrandProducts(resbrands.data);
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(()=>{
    console.log(sortOrderBy);
    getBrands();
    getProducts();
  },[page, selectedBrands, sortOrderBy])


  const arrCumb = [
    {
      "name" : "Products",
      "link" : "products"
    }    
  ]

  if (categories != "") {
    arrCumb.push({
      "name" : categoriesname,
      "link" : categories
    })
  }
  
  
  return (    
    <>
      <Header />
      <HeaderMobile />
      {/* <Breadcrumb /> */}
      <Breadcrumb pagename={arrCumb} />
      <div className="ps-page--shop">
        <div className="ps-container">
          <div className="mt-40">
            <div className="ps-shop-brand">
              {
                brandProducts.map((brand, id) => {
                  return(
                    <a key={id} href={brand.website || "https://gracemebel.com"} title={brand.description || "gracetoko"}>
                      <img
                        src={process.env.PUBLIC_URL+brand.logo}
                        onError={(e)=>{e.target.onerror = null; e.target.src=process.env.PUBLIC_URL+"/assets/img/placeholder.png"}}
                        alt={brand.description || "gracetoko"}
                      />
                    </a>
                  )
                })
              }
            </div>
          </div>
          <div className="ps-layout--shop">
            <div className="ps-layout__left">
              <aside className="widget widget_shop">
                <h4 className="widget-title">Categories</h4>
                <ul className="ps-list--categories">
                  <WidgetSideBar/>
                </ul>
              </aside>

              <aside className="widget widget_shop">
                <h4 className="widget-title">By Brands</h4>
                <figure
                  className="ps-custom-scrollbar mCustomScrollbar _mCS_1"
                  style={{ maxHeight: "350px" }}
                >
                  <div
                    id="mCSB_1"
                    className="mCustomScrollBox mCS-dark mCSB_vertical mCSB_inside"
                    style={{
                      maxHeight: "none",
                      tabIndex: "0",
                      marginRight: "25px",
                    }}
                  >
                    <div
                      id="mCSB_1_container"
                      className="mCSB_container"
                      style={{
                        position: "relative",
                        top: "0",
                        left: "0",
                        overflow: "hidden",
                        height: "auto",
                        width: "auto",
                      }}
                      dir="ltr"
                      >
                      <Brands selectedBrands={selectedBrands} onClickBrands={setSelectedBrands} />
                    </div>
                  </div>
                </figure>
              </aside>

              {/* <aside className="widget widget_shop">
                <h4 className="widget-title">By Price</h4>
                <figure>
                  <div id="nonlinear"></div>
                  <p className="ps-slider__meta">
                    Price:
                    <span className="ps-slider__value">
                      $<span className="ps-slider__min"></span>
                    </span>
                    -
                    <span className="ps-slider__value">
                      $<span className="ps-slider__max"></span>
                    </span>
                  </p>
                </figure>
              </aside> */}
            </div>

            <div className="ps-layout__right">
              <div className="ps-block--shop-features">
                  <div className="ps-block__header">
                      <h3>Recommended Items</h3>
                  </div>
                  <div className="ps-block__content">
                    <SliderProducts selectedCategories={categories}/>              
                  </div>
              </div>
              <div className="ps-shopping ps-tab-root">
                <div className="ps-shopping__header">
                  <p>
                    <strong> {rows}</strong> Products found
                  </p>
                  <div className="ps-shopping__actions">
                    <select onChange={(e) => {
                      const selectedOrderBy = e.target.value;
                      setSortOrderBy(selectedOrderBy)
                    }} data-placeholder="Sort Items" >
                      <option value="slates">Sort by sslatest</option>
                      <option value='srating'>Sort by average rating</option>
                      <option value='slohi'>Sort by price: low to high</option>
                      <option value='shilo'>Sort by price: high to low</option>
                      {/* <option>Sort by popularity</option> */}
                    </select>
                  </div>
                </div>
                <div className="ps-tabs">
                    <div className="ps-shopping-product">
                      <div className="row">
                          {
                            productdata.map((products)=> (
                                <div key={products.id} className="col-xl-2 col-lg-4 col-md-4 col-sm-6 col-6">
                                  <CardsProducts data={products}/>
                                </div>
                            ))
                          }
                        </div>
                    </div>
                    <div className="ps-pagination" style={{paddingTop:"1px"}}>
                      <ReactPaginate
                        pageCount={pages}
                        onPageChange={changePage} 
                        activeLinkClassName={"active"}
                        containerClassName={"pagination"}
                        pageClassName={'page-item'}
                        breakLabel={'...'}
                        pageLinkClassName={'page-item'}
                        activeClassName={'active hidden'}
                      />
                    </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className="modal"
            id="shop-filter-lastest"
            tabIndex="-1"
            role="dialog"
          >
            <div className="modal-dialog modal-dialog-centered" role="document">
              <div className="modal-content">
                <div className="modal-body">
                  <div className="list-group">
                    <a
                      className="list-group-item list-group-item-action"
                      href="#"
                    >
                      Sort by
                    </a>
                    <a
                      className="list-group-item list-group-item-action"
                      href="#"
                    >
                      Sort by average rating
                    </a>
                    <a
                      className="list-group-item list-group-item-action"
                      href="#"
                    >
                      Sort by latest
                    </a>
                    <a
                      className="list-group-item list-group-item-action"
                      href="#"
                    >
                      Sort by price: low to high
                    </a>
                    <a
                      className="list-group-item list-group-item-action"
                      href="#"
                    >
                      Sort by price: high to low
                    </a>
                    <a
                      className="list-group-item list-group-item-action text-center"
                      href="#"
                      data-dismiss="modal"
                    >
                      <strong>Close</strong>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
      <Sidebar />
      <div id="back2top">
        <i className="icon icon-arrow-up"></i>
      </div>
      <div className="ps-site-overlay"></div>
    </>
  );


}

// function getnamearray(data) {
//   let jcategories = JSON.parse(data);
//   return jcategories.name
// }

export default Products;
