import React, { useState, useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { Modal, ModalBody, ModalHeader, Button, Form, Input } from 'reactstrap'
import { useForm, Controller } from 'react-hook-form'
import MapModal from "../../Components/Maps/Maps.js";
// import { useSelector } from "react-redux";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import SelectShipdeo from "../../Components/Select/selectShipdeo";
import { errorPopup, successPopup } from "../../Components/PopupAlert";
import "../../Components/address.css";

function AddressModal({ modalParrent, handleCloseModal, data, defaultUser, handleRefresh }) {

    const axiosPrivate = useAxiosPrivate();
    const location = useLocation();
    const [mainAddress, setMainAddress] = useState(false);
    const [shipdeodata, setShipdeodata] = useState(null);
    const [mapModal, setMapModal] = useState(false);
    const [isAdd, setIsAdd] = useState(true);
    const [idrecord, setIdrecord] = useState(0);
    const [formatedAlamat, setFormatedAlamat] = useState(null);
    const [gmapData, setGmapData] = useState(null);

    const showMapModal = () => {
        setMapModal(true)
    }

    const closeMapModel = () => {
        setMapModal(false)
    }


    const handleMainAddress = () => {
        setMainAddress(!mainAddress);
    }

    const {
        setValue,
        control,
        setError,
        handleSubmit,
        formState: { errors }
    } = useForm();

    const onSelected = (shipdeo_hasil) => {
        setShipdeodata(shipdeo_hasil)
    }

    const ShowMapLabel = (dataMap) => {
        if (dataMap.q !== undefined) {
            var kolom = JSON.parse(dataMap.q).formated_address;
            if (kolom !== null) {
                return (
                    kolom
                )
            } else {
                return (
                    null
                )
            }
        } else {
            return (
                null
            )

        }

    }

    const onSubmit = async data => {
        var map_latlng = [];
        let map_formatted_address
        var lng, lat;
        var AddresssGmap = 0;
        const gmap = localStorage.getItem("CustomerAddressInput")
        if (gmap != null) {
            let jsonMap = JSON.parse(gmap)
            if (formatedAlamat !== null) {
                lat = jsonMap.geometry.location.lat;
                lng = jsonMap.geometry.location.lng;
                map_formatted_address = (jsonMap.formatted_address);
                AddresssGmap = 1;
            } else {
                if (gmapData != null) {
                    AddresssGmap = 1;
                    lat = gmapData.lat;
                    lng = gmapData.lng;
                    map_formatted_address = (gmapData.formated_address);
                }
            }
        }
        if (AddresssGmap !== 0) {
            map_latlng = {
                "formated_address": map_formatted_address,
                "lat": lat,
                "lng": lng
            }
        } else {
            map_latlng = {}
        }

        if (shipdeodata === null) {
            errorPopup("Silahkan pilih kecamatan dan kota dahulu!", 30)
            return
        }

        // // console.log(shipdeodata);
        if (Object.values(data).every(field => field.length > 0)) {

            if (isAdd) {
                const address = {
                    "name": data.fullname,
                    "nomorhp": data.nomorhp,
                    "alamatlengkap": data.alamatlengkap,
                    "shipdeodata": shipdeodata.value,
                    "label": data.labelalamat,
                    "catatan": data.catatan,
                    "mainaddress": mainAddress,
                    "gmap": map_latlng,
                    "customer_id": defaultUser,
                }
                try {
                    await axiosPrivate.post("/customeraddress", address)
                    handleCloseModal();
                    successPopup("Alamat berhasil ditambahkan");
                    handleRefresh();
                } catch (error) {
                    console.log("error post : ", error);
                }
            } else {
                const address = {
                    "name": data.fullname,
                    "nomorhp": data.nomorhp,
                    "alamatlengkap": data.alamatlengkap,
                    "shipdeodata": shipdeodata.value,
                    "label": data.labelalamat,
                    "catatan": data.catatan,
                    "mainaddress": mainAddress,
                    "gmap": map_latlng,
                    "customer_id": defaultUser,
                    "id": idrecord
                }
                try {
                    await axiosPrivate.post("/customereditadd", address)
                    handleCloseModal();
                    successPopup("Alamat berhasil dirubah");
                    handleRefresh();
                } catch (error) {
                    console.log("error post : ", error);
                }
            }
        } else {
            for (const key in data) {
                if (data[key].length === 0) {
                    setError(key, {
                        type: 'manual'
                    })
                }
            }
        }
    }

    useEffect(() => {
        setFormatedAlamat(null)
        setGmapData(null)
        if (Object.keys(data).length !== 0) {
            setValue("nomorhp", data.phone)
            setValue("fullname", data.name)
            setValue("labelalamat", data.label_address)
            setValue("alamatlengkap", data.address)
            setValue("catatan", data.notes)
            setMainAddress(data.is_default)
            var dataAlamat = {
                value: data.village_code + ":" + data.subdistrict_code + ":" + data.city_code + ":" + data.province_code + ":" + data.village_name + ":" + data.subdistrict_name + ":" + data.city_name + ":" + data.province_name + ":" + data.zip_code,
                label: data.village_name + ":" + data.subdistrict_name + ":" + data.city_name + ":" + data.province_name + ":" + data.zip_code
            }
            setShipdeodata(dataAlamat)
            // setShipdeodata()
            // value "36.03.17.2003:
            //        36.03.17:
            //        36.03:
            //        36:Kadu:Curug:Tangerang:BANTEN:15810"
            // label "Kadu Curug Tangerang BANTEN (15810)"
            setIsAdd(false);
            setIdrecord(data.id)
            var datalatlong = JSON.parse(data.gmap)
            if (Object.keys(datalatlong).length > 0) {
                var latlong = {
                    formated_address: datalatlong.formated_address,
                    lat: datalatlong.lat,
                    lng: datalatlong.lng
                }
                localStorage.setItem("CustomerAddressInput", JSON.stringify(latlong));
                setGmapData(latlong)
            } else {
                setGmapData(null)
            }
        } else {
            localStorage.setItem("CustomerAddressInput", localStorage.getItem("currentlocation"));
            setIsAdd(true);
            setMainAddress(false);
            setValue("fullname", "")
            setValue("nomorhp", "")
            setValue("labelalamat", "")
            setValue("alamatlengkap", "")
            setValue("catatan", "")
        }
    }, [data])

    return (
        <div>
            <Modal
                style={
                    {
                        top: "120px",
                        zIndex: "1999",
                    }
                }
                size="lg"
                isOpen={modalParrent}
                // toggle={() => setModalParrent(!modalParrent)}
                backdrop="static"
            >
                <ModalHeader className='bg-transparent' style={{ borderBottom: "0px" }} toggle={() => handleCloseModal()}></ModalHeader>
                <ModalBody className='px-5 pb-2' style={{ overflowY: "auto", height: "55vh" }}>
                    <div className='text-center mb-2'>
                        <h3 className='mb-1'>{isAdd ? "Tambah Alamat" : "Rubah Alamat"}</h3>
                    </div>
                    <Form>
                        <div className="form-group" style={{ padding: "10px", marginBottom: "5px" }}>
                            <label style={{ marginBottom: "10px", fontWeight: "700" }} htmlFor="fullname">Nama Penerima</label>
                            <Controller
                                control={control}
                                defaultValue=""
                                id='fullname'
                                name='fullname'
                                render={({ field }) => (
                                    <Input style={{ borderRadius: "10px", height: "45px" }}
                                        // value={data.name}
                                        placeholder='Nama Lengkap'
                                        invalid={errors.fullname && true}
                                        {...field}
                                    />
                                )}
                            />
                        </div>
                        <div className="form-group" style={{ padding: "10px", marginBottom: "5px" }}>
                            <label style={{ marginBottom: "10px", fontWeight: "700" }} htmlFor="nomorhp">Nomor HP</label>
                            <Controller
                                // defaultValue={<i class="fas fa-phone-volume    "></i>}
                                control={control}
                                id='nomorhp'
                                name='nomorhp'
                                render={({ field }) => (
                                    <Input style={{ borderRadius: "10px", height: "45px" }}
                                        value={data.phone}
                                        placeholder='Nomor HP'
                                        invalid={errors.nomorhp && true}
                                        {...field}
                                    />
                                )}
                            />
                        </div>
                        <div className="form-group" style={{ padding: "10px", marginBottom: "5px" }}>
                            <label style={{ marginBottom: "10px", fontWeight: "700" }} htmlFor="labelalamat">Label Alamat</label>
                            <Controller
                                control={control}
                                id='labelalamat'
                                name='labelalamat'
                                render={({ field }) => (
                                    <Input style={{ borderRadius: "10px", height: "45px" }}
                                        placeholder='Label Alamat (Rumah/Kantor/Toko)'
                                        invalid={errors.labelalamat && true}
                                        {...field}
                                    />
                                )}
                            />
                        </div>
                        <div className="form-group" style={{ padding: "10px", marginBottom: "5px" }}>
                            <label style={{ marginBottom: "10px", fontWeight: "700" }} htmlFor="kotakecamatan">Kota & Kecamatan</label>
                            <SelectShipdeo onSelected={onSelected} datashipdeo={data} />
                        </div>
                        <div className="form-group" style={{ padding: "10px", marginBottom: "5px" }}>
                            <label style={{ marginBottom: "10px", fontWeight: "700" }} htmlFor="kotakecamatan">Alamat Lengkap</label>
                            <Controller
                                // defaultValue=''
                                control={control}
                                id='alamatlengkap'
                                name='alamatlengkap'
                                render={({ field }) => (
                                    // <Input type='textarea' name='text' id='exampleText' rows='3' placeholder='Textarea' />
                                    <Input style={{ borderRadius: "10px" }}
                                        type='textarea' name='text' id='exampleText' rows='3' placeholder='Textarea'
                                        placeholder='Alamat lengkap / RT/RW/Nama Jalan'
                                        invalid={errors.alamatlengkap && true}
                                        {...field}
                                    />
                                )}
                            />
                        </div>
                        <div className="form-group" style={{ padding: "10px", marginBottom: "5px" }}>
                            <label style={{ marginBottom: "10px", fontWeight: "700" }} htmlFor="catatan">Catatan (opsional)</label>
                            <Controller
                                defaultValue=''
                                control={control}
                                id='catatan'
                                name='catatan'
                                render={({ field }) => (
                                    <Input style={{ borderRadius: "10px", height: "45px" }}
                                        {...field}
                                    />
                                )}
                            />

                        </div>
                        <div className="form-group" style={{ padding: "10px", marginBottom: "5px" }} >
                            {/* <Button type="button" variant="outline-primary" onClick={showMapModal} /> */}
                            <article className="blu-ticker content__geoLocationInfo" style={{ cursor: "pointer" }} onClick={showMapModal}>
                                <span className="blu-ticker__bg"></span>
                                <i className="blu-ticker__icon"></i>
                                <div className="blu-ticker__msg">
                                    <div className="ticker__content">
                                        <div>
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                fill="#858585"
                                                preserveAspectRatio="xMidYMid meet"
                                                width="24"
                                                height="24"
                                                viewBox="0 0 24 24">
                                                <path
                                                    d="M5 11.1818C5 7.11464 7.45455 3 12.3636 3C17.2727 3 19.7273 7.11545 19.7273 11.1818C19.7273 16.0909 14.8182 21 12.3636 21C9.90909 21 5 16.0909 5 11.1818ZM9.09091 10.3636C9.09091 12.171 10.5563 13.6364 12.3636 13.6364C14.1718 13.6364 15.6364 12.1718 15.6364 10.3636C15.6364 8.55627 14.171 7.09091 12.3636 7.09091C10.5563 7.09091 9.09091 8.55627 9.09091 10.3636Z">
                                                </path>
                                            </svg>
                                            {" Lokasi Map "}
                                            <span style={{ display: "block", overflowWrap: "break-word", marginLeft: "5px" }}>
                                                <ShowMapLabel q={data.gmap} />
                                                <MapModal mapModal={mapModal} onClose={closeMapModel} setFormatedAlamat={setFormatedAlamat} formMap={location} />

                                            </span>
                                        </div>

                                        {/* {

                                            data.gmap !== null || data.gmap == undefined ? JSON.parse(data.gmap).formated_address : "Atur titik lokasi biar lebih akurat dan bisa pakai metode pengiriman cepat."

                                            // data.gmap == null ? <p>{"Pilih Map"}</p> : <p>{JSON.parse(data.gmap).formated_address}</p>
                                        } */}

                                    </div>
                                </div>
                            </article>

                        </div>



                        <div className="form-group" style={{ padding: "10px", marginBottom: "5px" }}>
                            <div className="ps-checkbox">
                                <input
                                    id="isDefault"
                                    type="checkbox"
                                    name="isDefault"
                                    checked={mainAddress} onChange={handleMainAddress}
                                />
                                <label htmlFor="isDefault" style={{ fontWeight: "700" }}>Jadikan alamat utama</label>
                            </div>
                        </div>


                        <div className="form-group">
                            <Button type="button" onClick={handleSubmit(onSubmit)} size="lg" style={{ height: "40px" }} block color="primary">Simpan Alamat</Button>{' '}
                            {/* <button type="submit">Add a new address</button> */}
                        </div>
                    </Form>
                </ModalBody>
            </Modal>

        </div>
    )
}

export default AddressModal