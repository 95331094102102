import React, { useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import Header from "../Components/Header/Header";
import HeaderMobile from "../Components/Header/HeaderMobile";
import Breadcrumb from "../Components/Common/Breadcrumb";
import Footer from "../Components/Footer/Footer";

const Resetpassword = () => {
  const [email, setEmail] = useState("");

  const history = useNavigate();

  return (
    <>
      <Header />
      <HeaderMobile />
      <Breadcrumb />
      <div className="ps-container">
        <div className="mt-40 mb-40">
          <div className="ps-my-account">
            <div className="container">
              <form
                className="ps-form--account ps-tab-root"
                method="POST"
                action="https://gracetoko.com/password/email"
              >
                <input
                  type="hidden"
                  name="_token"
                  value="5bJAFaYL3xAfMKpMsyu71wwlxAvAIVjqPAijyXBU"
                />
                <div className="ps-form__content">
                  <h4>Reset Password</h4>
                  <div className="form-group">
                    <input
                      className="form-control"
                      type="email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      placeholder="Your Email"
                    />
                  </div>

                  <div className="form-group submit">
                    <button className="ps-btn ps-btn--fullwidth" type="submit">
                      Send Password Reset Link
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Resetpassword;
