import React from "react";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import "./Loading.css";
import { Oval } from 'react-loader-spinner'


const Loading = () => {

    return (
        <div className="fp-container">
            <div className="fp-loader">
                <Oval
                    height={100}
                    width={100}
                    color="#00BFFF"
                    ariaLLabel="Please wait..."
                />
            </div>
        </div>
    )
}

export default Loading;