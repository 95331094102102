import React  from "react";
import useFetch from "../../hooks/useFetch";

function Breadcrumb(props) {


  const product_id = props.pagename[0]?.product_id || null;
  const product_name = props.pagename[0]?.product_name || null;

  if (props.pagename === undefined || props.pagename === null) {
    var pagedata = [{"name" : "undefine index", "link" : "/"}]
    var dataCount = 1;
  } else {
    var pagedata = props.pagename;
    var dataCount = pagedata.length;
  }


  const {data, loading, error} = useFetch(`/productcategories/${product_id}`)
  // console.log(data);


  return (
    <div className="ps-breadcrumb">
      <div className="ps-container">
        <ul className="breadcrumb">
          <li>
            <a href="/">Home</a>
          </li>



          {
            data && 
            data.map((categories,index) => (
              <li key={index}>
                <a href={`/product-categories/${categories.link}`} >
                  {categories.name}
                </a>
              </li>
          ))

  

          }

          {
            pagedata &&
            pagedata.map((item, index) => {
              if (index+1 === pagedata.length) {
                return <li key={index}>{item.name}</li>
              } else {
                return <li key={index}><a href={item.link}>{item.name}</a></li>
              }
            })
          }

          {/* {pagedata.map((pageid,index) => (
            <li key={index}>
              <a a href={pageid.link}>{pageid.name}</a>
            </li>
          ))
          } */}
        </ul>
      </div>
    </div>
  );
}

export default Breadcrumb;
