import React from "react";

function Widget2Promo() {

    return (
        <div>
            <div className="ps-home-ads mt-40 mb-40">
                <div className="ps-container">
                    <div className="row">
                        <div className="col-lg-6">
                            <div className="ps-collection">
                                <div>
                                    <a href="https://martfury.botble.com/ads-click/Q9YDUIC9HSWS" target="_blank">
                                        <img src="https://martfury.botble.com/storage/promotion/6.jpg" alt="Homepage big 1" style={{ 'maxWidth': "100%"}} />
                                    </a>
                                </div>
                            </div>
                        </div> 
                        <div className="col-lg-6">
                            <div className="ps-collection">
                                <div>
                                    <a href="https://martfury.botble.com/ads-click/IZ6WU8KUALYE" target="_blank">
                                        <img src="https://martfury.botble.com/storage/promotion/9.jpg" alt="Homepage big 2" style={{ 'maxWidth': "100%"}} />
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )

}

export default Widget2Promo