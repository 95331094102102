import React, { useState, useEffect, useCallback } from 'react'
import CategoriesMenu from '../../pages/CategoriesMenu';
import CartMini from '../Common/CartMini';
import SearchForm from '../SearchForm';
import { useSelector, useDispatch } from 'react-redux';
import { logout } from '../../config/actions/auth';
// import jwtDecode from 'jwt-decode';

const MainHeader = (props) => {

    const [sticky, setSticky] = useState("header header--1");

    const { isLoggedIn } = useSelector(state => state.userReducer);
    const { userLogin } = useSelector(state => state.userReducer);
    // const { accessToken } = useSelector(state => state.userReducer);
    // const { errorHandler } = props;

    // if (isLoggedIn) {
    //     console.log("Data User Login :", userLogin);
    //     console.log("Sekarang : ", Date.now())
    //     console.log("Expired : ", jwtDecode(accessToken).exp * 1000)
    //     console.log("Access Token : ", accessToken)
    //     if (jwtDecode(accessToken).exp * 1000 < Date.now()) {
    //         console.log("Time Out");
    //     } else {
    //         console.log("Exist")
    //     }
    // }


    const dispatch = useDispatch();

    const isSticky = () => {
        const scrollTop = window.scrollY;
        const stickyClass = scrollTop >= 20 ? "header header--1 header--sticky" : "header header--1";
        setSticky(stickyClass);
    };


    const logOut = useCallback(() => {
        dispatch(logout());
        window.location.reload()
    }, [dispatch]);


    useEffect(() => {
        window.addEventListener("scroll", isSticky);
        return () => {
            window.removeEventListener("scroll", isSticky);
        };
    }, []);

    class LoginStatus extends React.Component {
        render() {
            if (isLoggedIn === true) {
                return (
                    <>
                        <a href="/customer/overview" style={{ color: "white", fontWeight: "500" }}>{userLogin.name}</a>
                        <a href="#" style={{ color: "white", fontWeight: "500" }} onClick={logOut}>
                            Logout
                        </a>
                    </>
                );
            } else {
                return (
                    <>
                        <a href="/login" style={{ color: "white", fontWeight: "600" }}  >Login</a>
                        <a href="/register" style={{ color: "white", fontWeight: "600" }}>Register</a>
                    </>
                );
            }
        }
    }


    // const logoutclick = async (e) => {
    //     e.preventDefault();
    //     await logout();
    //     window.location.reload();
    // };

    return (
        <>
            <header className={sticky} data-sticky="true">
                <div className="header__top">
                    <div className="ps-container">
                        <div className="header__left">
                            <CategoriesMenu />
                            <a className="ps-logo" href="http://localhost:8000">
                                {/* <img src="http://localhost:8000/storage/general/logo.png" alt="GraceToko - Online Ecommerce system" height="40" /> */}
                            </a>
                        </div>
                        <div className="header__center">
                            <SearchForm typeBrowser="web" />
                        </div>
                        <div className="header__right">
                            <div className="header__actions">
                                <a className="header__extra btn-wishlist" href="http://localhost:8000/wishlist">
                                    <i style={{ color: "white" }} className="icon-heart"></i>
                                    <span style={{ backgroundColor: "#E02954" }}>
                                        <i style={{ color: "white", backgroundColor: "#E02954" }}>0</i>
                                    </span>
                                </a>



                                <CartMini />
                                <div className="ps-block--user-header">
                                    <div className="ps-block__left">
                                        <i style={{ color: "white" }} className="icon-user"></i>
                                    </div>
                                    <div className="ps-block__right">


                                        <LoginStatus />
                                        {/* <a href="/login" style={{color:"white",fontWeight:"500"}}>Login</a>
                                <a href="/register" style={{color:"white",fontWeight:"500"}}>Register</a> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <nav className="navigation">
                    <div className="ps-container">
                        <div className="navigation__left">
                            <CategoriesMenu />
                        </div>
                        <div className="navigation__right">
                            <ul className="menu">
                                <li className="  ">
                                    <a href="/">
                                        Store location
                                    </a>
                                </li>
                            </ul>
                            <ul className="navigation__extra">
                                <li><a href="/login">Sell On GraceToko</a></li>
                                <li><a href="/orders/tracking">Track your order</a></li>
                            </ul>
                        </div>
                    </div>
                </nav>
            </header>
        </>
    )
}

export default MainHeader