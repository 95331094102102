import React, { useState, useEffect, Component } from 'react'
import ReactPaginate from "react-paginate";
import axios from '../../hooks/useAxios';
import Avatar from "react-avatar";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";

export const ReviewList = ({datalist, nameproduct}) => {
    let idproduct = datalist ;
    let stars = 0;
    const [page, setPage] = useState(0);
    const [limit, setLimit] = useState(10);
    const [pages, setPages] = useState(0);
    const [rows, setRows] = useState(0);
    const [rvdata, setRvdata] = useState(null);
    const [productid, setProductid] = useState(0);
    const [showModal, setShowModal] = useState(false);
    const [indexOfImages, setIndexOfImages] = useState(0); 

    const changePage = ({selected}) => {
        setPage(selected);
    }

    const openModalAndSetIndex = (index) => {
        console.log(index);
        setIndexOfImages(index);
        setShowModal(true);
        return;
    }

    const getRv = () => {
        axios.get(`/review?idproduct=${idproduct}&page=${page}&limit=${limit}`)
        .then((response) => {
            const data = response.data
            setRvdata(data);
            setPage(data.page);
            setPages(data.totalPages);
            setRows(data.totalRows);
            
        })
        .catch(error=>console.error('error'))
    }

    useEffect(()=>{
        getRv();
    },[page])
    
  return (
    <>
        <div className="row">
        <div className="col-12">
            <div className="block--product-reviews">
                <div className="block__header d-md-flex align-items-center justify-content-between">
                    <div className="pb-4 pb-md-0">
                    <h2>
                        {rows} review(s) for &quot;{nameproduct}&quot;
                    </h2>
                    </div>
                    <div className="ps-review__filter-by-star d-flex align-items-center justify-content-end">
                    <div className="px-2 d-flex align-items-center">
                        <i className="icon-funnel"></i>
                        <span>Filter:</span>
                    </div>
                    <div className="ps-review__filter-select">
                        <select className="ps-select">
                        <option value="0">All Star</option>
                        <option value="1">1 Star</option>
                        <option value="2">2 Star</option>
                        <option value="3">3 Star</option>
                        <option value="4">4 Star</option>
                        <option value="5">5 Star</option>
                        </select>
                    </div>
                    </div>
                </div>
                <div className='block__content'>
                    {
                        rvdata && 
                        rvdata.data.map((review, index)=>(
                            <div key={review.id} className="block--review">
                                <div className="block__header">
                                    <div className="block__image">
                                        <Avatar name={review.ec_customer?.name} size="60" />
                                    </div>
                                    <div className="block__info">
                                        <div className="rating_wrap">
                                            <div className="rating">
                                                <ShowStars star={review.star}/>
                                            </div>
                                        </div>
                                        <div className="my-2">
                                            <span className="d-block lh-1">
                                                <strong>{review.ec_customer?.name}</strong> 
                                            </span>
                                            <small className="text-secondary lh-1">6 months ago{review.createdAt}</small>
                                        </div>
                                        <div className="block__content">
                                            <p>{review.comment}</p>
                                        </div>
                                        <ReviewImages imgdata={review.images} />
                                    </div>
                                </div>
                            </div>
                        ))
                    }
                    
                </div>
            </div>
        </div>
       </div>

        <div className="ps-pagination" style={{paddingTop:"1px"}}>
            <ReactPaginate
                pageCount={pages}
                onPageChange={changePage} 
                activeLinkClassName={"active"}
                containerClassName={"pagination"}
                pageClassName={'page-item'}
                breakLabel={'...'}
                pageLinkClassName={'page-item'}
                activeClassName={'active hidden'}
            />
        </div>

    </>
  )

  function ReviewImages(imgdata) {
    let reviewImages = JSON.parse(imgdata.imgdata)
    return (
        <>
            <div className="block__images">
                {
                    reviewImages.map((img, index) => (
                        <a key={index} href={void(0)}>
                            <img src={`/storage/reviews/${img}`}
                                onError={(e)=>{e.target.onerror = null; e.target.src=process.env.PUBLIC_URL+"/assets/img/placeholder.png"}}
                                alt={`storage/reviews/${img}`}
                                // alt={img.customerNote} 
                                onClick={() => {openModalAndSetIndex(index)}}
                                className="img-responsive rounded h-100" 
                            />
                        </a>
                    ))
                }
            </div>
            {showModal && (
            <Lightbox
            mainSrc={reviewImages[indexOfImages]}
            nextSrc={reviewImages[(indexOfImages + 1) % reviewImages.length]}
            prevSrc={
                reviewImages[
                (indexOfImages + reviewImages.length - 1) % reviewImages.length
                ]
            }
            onCloseRequest={() => setShowModal(false)}
            onMovePrevRequest={() =>
                setIndexOfImages(
                (indexOfImages + reviewImages.length - 1) % reviewImages.length
                )
            }
            onMoveNextRequest={() =>
                setIndexOfImages(
                (indexOfImages + reviewImages.length + 1) % reviewImages.length
                )
            }
            />
            )}
        </>
    )     
  }


  function ShowStars(star) {
    let nilai = parseInt(star.star)
    let stars = Math.ceil((nilai * 100) / 5);
    return (
        <div className="product_rate" style={{"width": `${stars}%`}}>
        </div>
    )
  }

}
