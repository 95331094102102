import React, { useState, useEffect } from 'react'
import { useSelector } from "react-redux";
import { useLocation } from 'react-router-dom'
import { Modal, ModalBody, ModalHeader, Button, Form, Input } from 'reactstrap'
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import AddressModal from './AddressModal';
import "./_AddressModal.css";

function ListAddressModal({ modalListAddress, handleCloseListAddressModal }) {

    const axiosPrivate = useAxiosPrivate();
    const location = useLocation();
    const { userLogin } = useSelector(state => state.userReducer);
    const [cardData, setCardData] = useState([]);
    const [currentAddress, setCurrentAddress] = useState(localStorage.getItem("currentAddress") || null);
    const [modalParrent, setModalParrent] = useState(false);
    const [formData, setFormData] = useState({})

    const handleOpenModal = () => {
        setModalParrent(true)
    }

    const handleCloseModal = () => {
        setModalParrent(false)
    }

    function setDataAlamat(data) {
        console.log(data);
    }

    const getAddresses = async () => {
        try {
            const response = await axiosPrivate.get(`/getcustomeraddress?user=${userLogin.userId}&page=0&limit=100`);
            const result = response.data;
            // console.log(result.data);
            setCardData(result.data);
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        getAddresses();
    }, [])

    const SelectButton = ({ data }) => {
        var idDefault = 0;
        if (currentAddress != null) {
            let curAddress = JSON.parse(currentAddress);
            idDefault = curAddress.id;
        }
        if (data.id === idDefault) {
            return (
                <div className="card-link-wrapper">
                    <button disabled style={{ border: "none", cursor: "no-drop" }} className="card-link-default">Alamat Utama</button>
                </div>
            )
        } else {
            return (
                <div className="card-link-wrapper">
                    <button style={{ border: "none" }} onClick={() => setDataAlamat(data)} className="card-link">Gunakan Alamat ini</button>
                </div>
            )
        }
    }

    const Card = ({ data }) => {
        // console.log(data)
        let gmap;

        if (data.gmap != null) {
            gmap = JSON.parse(data.gmap);
        }

        return (
            <li className='card'>
                <div>
                    <div className='card-title'>
                        {data.name}
                    </div>
                    <div className='card-content'>
                        {data.address}
                    </div>
                    <div style={{ display: "flex", marginBottom: "20px" }}>
                        <div>
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="#858585"
                                preserveAspectRatio="xMidYMid meet"
                                width="18"
                                height="18"
                                viewBox="0 0 24 24">
                                <path
                                    d="M5 11.1818C5 7.11464 7.45455 3 12.3636 3C17.2727 3 19.7273 7.11545 19.7273 11.1818C19.7273 16.0909 14.8182 21 12.3636 21C9.90909 21 5 16.0909 5 11.1818ZM9.09091 10.3636C9.09091 12.171 10.5563 13.6364 12.3636 13.6364C14.1718 13.6364 15.6364 12.1718 15.6364 10.3636C15.6364 8.55627 14.171 7.09091 12.3636 7.09091C10.5563 7.09091 9.09091 8.55627 9.09091 10.3636Z">
                                </path>
                            </svg>
                        </div>
                        <div className='card-map'>
                            {
                                data.gmap !== null ? gmap.formated_address : "Atur titik lokasi biar lebih akurat dan bisa pakai metode pengiriman cepat."
                            }
                        </div>
                    </div>
                    <div className='card-ext'>
                        {data.subdistrict_name}{" "}{data.city_name}{", "}{data.province_name} {"("}{data.zip_code}{")"}
                    </div>
                </div>
                <SelectButton data={data} />
            </li >
        )
    }

    return (
        <div>
            <Modal
                style={
                    {
                        top: "120px",
                        zIndex: "1999",
                    }
                }
                size="lg"
                isOpen={modalListAddress}
                // toggle={() => setModalParrent(!modalParrent)}
                backdrop="static"
            >
                <ModalHeader className='bg-transparent' style={{ borderBottom: "0px" }} toggle={() => handleCloseListAddressModal()}>
                    <span style={{ position: "absolute", fontSize: "15px", width: "200px", marginLeft: "10px" }}>Mau dikirim kemana?</span>
                </ModalHeader>
                <ModalBody>
                    <div className='container-card'>
                        <ul className='cards'>
                            {
                                cardData.map((card) => (
                                    <Card data={card} key={card.id} />)
                                )
                            }
                            <li className='card'
                                onClick={() => {
                                    setFormData({})
                                    setModalParrent(true)
                                }}
                                style={{ cursor: "pointer" }}
                            >
                                <div>
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="#858585"
                                        preserveAspectRatio="xMidYMid meet"
                                        width="100%"
                                        height="100%"
                                        viewBox="-40 -40 100 100">
                                        <path
                                            d="M13.68,9.448h-3.128V6.319c0-0.304-0.248-0.551-0.552-0.551S9.448,6.015,9.448,6.319v3.129H6.319
								c-0.304,0-0.551,0.247-0.551,0.551s0.247,0.551,0.551,0.551h3.129v3.129c0,0.305,0.248,0.551,0.552,0.551s0.552-0.246,0.552-0.551
								v-3.129h3.128c0.305,0,0.552-0.247,0.552-0.551S13.984,9.448,13.68,9.448z M10,0.968c-4.987,0-9.031,4.043-9.031,9.031
								c0,4.989,4.044,9.032,9.031,9.032c4.988,0,9.031-4.043,9.031-9.032C19.031,5.012,14.988,0.968,10,0.968z M10,17.902
								c-4.364,0-7.902-3.539-7.902-7.903c0-4.365,3.538-7.902,7.902-7.902S17.902,5.635,17.902,10C17.902,14.363,14.364,17.902,10,17.902
								z"></path>
                                    </svg>

                                </div>
                            </li>
                        </ul>
                    </div>
                </ModalBody>

            </Modal>

            <AddressModal modalParrent={modalParrent} handleCloseModal={handleCloseModal} data={formData} defaultUser={userLogin.userId} />

        </div>
    )
}

export default ListAddressModal