import React, { useEffect, useState, useCallback } from "react";
// import jwtDecode from "jwt-decode";
// import { useNavigate } from "react-router-dom";
// import PropTypes from "prop-types";
import Anchor from "../Common/Anchor";
// import SearchProducts from "../SearchProducts";
import CategoriesMenu from "../../pages/CategoriesMenu"
import CartMini from "../Common/CartMini";
// import useLogout from "../../hooks/useLogout";
import { useSelector, useDispatch } from 'react-redux';
import { logout } from "../../config/actions/auth";
import SearchForm from "../SearchForm";

function Header() {
  // const navigate = useNavigate();
  // const logout = useLogout();
  const { isLoggedIn } = useSelector(state => state.userReducer);
  const { userLogin } = useSelector(state => state.userReducer);
  // const { accessToken } = useSelector(state => state.userReducer);

  const dispatch = useDispatch();

  const [sticky, setSticky] = useState("header header--standard header--market-place-1");


  // if (isLoggedIn) {
  //   console.log("Data User Login :", userLogin);
  //   console.log("Sekarang : ", Date.now())
  //   console.log("Expired : ", jwtDecode(accessToken).exp * 1000)
  //   console.log("Access Token : ", accessToken)
  //   if (jwtDecode(accessToken).exp * 1000 < Date.now()) {
  //     console.log("Time Out");
  //   } else {
  //     console.log("Exist")
  //   }
  // }



  const isSticky = () => {
    const scrollTop = window.scrollY;
    const stickyClass = scrollTop >= 20 ? "header header--standard header--market-place-1 header--sticky" : "header header--standard header--market-place-1";
    setSticky(stickyClass);
  };

  useEffect(() => {
    window.addEventListener("scroll", isSticky);
    return () => {
      window.removeEventListener("scroll", isSticky);
    };
  }, []);


  const logOut = useCallback(() => {
    dispatch(logout());
    // window.location.reload()
  }, [dispatch]);

  // const logoutclick = async (e) => {

  //   e.preventDefault();
  //   await logout();
  //   window.location.reload();
  //   // logout();
  //   // try {
  //   //   const apiurl = process.env.React_App_Gracetoko_Api;
  //   //   await axios.delete(apiurl+ "/logout");
  //   //   // history.push("/");

  //   //   window.location.reload();
  //   // } catch (error) {
  //   //   console.log(error);
  //   // }
  // };

  class LoginStatus extends React.Component {
    render() {
      if (isLoggedIn) {
        return (
          <>
            <a href="/customer/overview" style={{ color: "white", fontWeight: "600" }}>{userLogin.name}</a>
            <a href="#" style={{ color: "white", fontWeight: "600" }} onClick={logOut}>
              Logout
            </a>
          </>
        );
      } else {
        return (
          <>
            <a href="/login" style={{ color: "white", fontWeight: "600" }}  >Login</a>
            <a href="/register" style={{ color: "white", fontWeight: "600" }}>Register</a>
          </>
        );
      }
    }
  }

  return (
    <header
      className={sticky} data-sticky="true"
    >
      <div className="header__top" style={{ backgroundColor: "#2878FE" }}>
        <div className="container">
          <div className="header__left">
            <p style={{ color: "whitesmoke" }}>Welcome to Gracetoko.com Online store</p>
          </div>
          <div className="header__right">
            <ul className="header__top-links" >
              <li>
                <Anchor href="#" style={{ color: "whitesmoke" }}>Store Location</Anchor>
              </li>
              <li>
                <Anchor href="#" style={{ color: "whitesmoke" }} >Track Your Order</Anchor>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="header__content" style={{ backgroundColor: "#2878FE" }}>
        <div className="container">
          <div className="header__content-left">
            <a className="ps-logo" href="/">
              <img
                src={process.env.PUBLIC_URL + "/assets/img/logo.png"}
                alt=""
              />
            </a>
          </div>
          <div className="header__content-center">
            {/* <SearchProducts type="web" /> */}
            <SearchForm typeBrowser="web" />

          </div>
          <div className="header__content-right">
            <div className="header__actions">
              <a className="header__extra" style={{ color: "whitesmoke" }} href="#">
                <i className="icon-heart"></i>
                <span style={{ backgroundColor: "#E02954" }}>
                  <i style={{ color: "white", backgroundColor: "#E02954" }}>0</i>
                </span>
              </a>

              <CartMini />

              <div className="ps-block--user-header">
                <div className="ps-block__left" >
                  <i className="icon-user" style={{ color: "white" }}></i>
                </div>
                <div className="ps-block__right">
                  <LoginStatus />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <nav className="navigation">
        <div className="container">
          <div className="navigation__left">
            {/* <CategoriesMenu
              categories={categories}
              categoriesDetail={categoriesDetail}
            /> */}
            <CategoriesMenu />
          </div>
          <div className="navigation__right">
            <ul className="menu">
              <li className="menu-item">
                <a href="/">Link barang Favorits</a>
              </li>
            </ul>
            <div className="ps-block--header-hotline inline">
              <p>
                <i className="icon-telephone"></i>Hotline:
                <strong> 1-800-234-5678</strong>
              </p>
            </div>
          </div>
        </div>
      </nav>
    </header>
  );
}

// Header.propTypes = {};

export default Header;
