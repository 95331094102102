import React, { useEffect, useState } from "react";
import WidgetSideBarItem from "./WidgetSidebarItem";
import axios from "../../hooks/useAxios";


export default function WidgetSideBar() {

    const [widgetCategories, setWidgetCategories] = useState([]);
    const getWidgetsCategories = async () => {
      try {
        const response = await axios.get("/widgetsdetail/ProductCategoriesWidget/bottom_footer_sidebar");
        setWidgetCategories(response.data);
      } catch (error) {
        console.log(error);
      }
    };

    useEffect(()=>{
        getWidgetsCategories()
    },[])

    return (
        <div className="sidebar">
          { widgetCategories.map((item, index) => <WidgetSideBarItem key={index} item={item} />) }
        </div>
    )

    // return (
    //     {
    //         <>
    //             widgetCategories.map((item, index) => <WidgetSideBarItem key={index} item={item}) />
    //         </>
    //     }
    // )
  

}