import React from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import CustomerSideBar from "./SideBar";
import Header from "../../Components/Header/Header";
import HeaderMobile from "../../Components/Header/HeaderMobile";
import Breadcrumb from "../../Components/Common/Breadcrumb";
import Sidebar from "../../Components/SideBar/Sidebar";
import ProductFooter from "../../Components/Footer/ProductFooter";
import useFetchToken from "../../hooks/useFetchToken";

const EditAccount = () => {

    const history = useNavigate();
    const { data, error } = useFetchToken("/customervendor/")
    const arrCumb = [
        {
          "name" : "Edit profile",
          "link" : "customer/overview"
        }
    ]

  return (
      <>
      <Header />
      {/* email_account={user.email} name_account={user.name} /> */}
      <HeaderMobile />
      <Breadcrumb pagename={arrCumb}/>
      <Sidebar />
      <section className="ps-section--account crop-avatar customer-page">
        <div className="container">
          <div className="row">    
              <CustomerSideBar pageid="editaccount"/>
              <div className="col-lg-8">
                 <div className="ps-section__right">
                  <form method="POST" action="http://localhost:8000/customer/edit-account" acceptCharset="UTF-8" className="ps-form--account-setting">
                      <input name="_token" type="hidden" value={data.remember_token} />
                      <ul className="nav nav-tabs ">
                          <li className="nav-item">
                              <a href="#tab_profile" className="nav-link active" data-toggle="tab">Profile </a>
                          </li>              
                      </ul>
                      <div className="tab-content mx-2 my-4">
                          <div className="tab-pane active" id="tab_profile">
                              <div className="ps-form__content">
                                  <div className="form-group">
                                      <label htmlFor="name">Full Name:</label>
                                      <input type="text" className="form-control" name="name" value={data.name} />
                                  </div>
                                  <div className="form-group ">
                                      <label htmlFor="dob">Date of birth:</label>
                                      <input type="text" className="form-control" name="dob" value={data.dob === null ? '' : data.dob} />
                                  </div>
                                  <div className="form-group ">
                                      <label htmlFor="email">Email:</label>
                                      <input type="text" className="form-control" disabled="disabled" value={data.email} name="email"  />
                                  </div>
                                  <div className="form-group ">
                                      <label htmlFor="phone">Phone</label>
                                      <input type="text" className="form-control" name="phone" placeholder="Phone" value={data.phone === null ? '' : data.phone } />
                                  </div>    
                              </div>
                          </div>    
                      </div>
                      <div className="form-group text-center">
                          <div className="form-group submit">
                              <button className="ps-btn">Update</button>
                          </div>
                      </div>
                  </form>
                </div>                
              </div>
          </div>
        </div>
      </section>

      </>
  )
}

export default EditAccount