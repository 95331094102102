import "./styles.css";
import { useState } from "react";
import {
    animated,
    useSpring,
    config,
    useSpringRef,
    useChain
} from "react-spring";

function Checkbox({ typeCheck, valueCheck, currentCheckState, onChangeCheckState }) {
    const [typeCheckBox, setTypeCheckBox] = useState(typeCheck);
    const [valueCheckBox, setValueCheckBox] = useState(valueCheck);

    let checkState = false;

    if (currentCheckState != undefined) {
        checkState = currentCheckState > 0 ? true : false;
    }

    const [isChecked, setIsChecked] = useState(checkState);

    const checkboxAnimationRef = useSpringRef();
    const checkboxAnimationStyle = useSpring({
        backgroundColor: isChecked ? "#2878FE" : "#fff",
        borderColor: isChecked ? "#2878FE" : "#2878FE",
        config: config.gentle,
        ref: checkboxAnimationRef
    });

    const [checkmarkLength, setCheckmarkLength] = useState(null);

    const checkmarkAnimationRef = useSpringRef();
    const checkmarkAnimationStyle = useSpring({
        x: isChecked ? 0 : checkmarkLength,
        config: config.gentle,
        ref: checkmarkAnimationRef
    });

    const hanldeChange = () => {
        onChangeCheckState(typeCheckBox, valueCheck, (!isChecked ? "Selected" : "Unselect"));
        // console.log("Type Check :", typeCheckBox);
        // console.log("Value Check : ", typeCheckBox);
        // console.log("Status Check :", !isChecked ? "Selected" : "Unselect")
    }

    useChain(
        isChecked
            ? [checkboxAnimationRef, checkmarkAnimationRef]
            : [checkmarkAnimationRef, checkboxAnimationRef],
        [0, 0.1]
    );

    return (
        <label style={{ verticalAlign: "middle" }} >
            <input
                type="checkbox"
                defaultChecked={checkState}
                onChange={() => {
                    setIsChecked(!isChecked);
                    hanldeChange();
                }}
            />
            <animated.svg
                style={checkboxAnimationStyle}
                className={`checkbox ${isChecked ? "checkbox--active" : ""}`}
                // This element is purely decorative so
                // we hide it for screen readers
                aria-hidden="true"
                viewBox="0 0 15 11"
                fill="none"
            >
                <animated.path
                    d="M1 4.5L5 9L14 1"
                    strokeWidth="2"
                    stroke="#fff"
                    ref={(ref) => {
                        if (ref) {
                            setCheckmarkLength(ref.getTotalLength());
                        }
                    }}
                    strokeDasharray={checkmarkLength}
                    strokeDashoffset={checkmarkAnimationStyle.x}
                />
            </animated.svg>
        </label>
    );
}

export default Checkbox;
