import React, { useState } from 'react'
import Sanitized from '../../Components/Sanitized'
import { ReviewList } from './ReviewList';
import { ImagesReviews } from './ImagesReviews';

// import TabNav from '../../Components/TabNav';
// import Tab from '../../Components/Tab';

export const ContentProduct = ({product}) => {

    const [selected, setSelected] = useState("Description");
    const handleClick = (tab) => {
        setSelected(tab);
    }

  return (
    <>
        <div className="ps-product__content ps-tab-root">
            <ul className="ps-tab-list">
            <li className={selected === "Description" ? "active" : ""}>
                <a href={void(0)} onClick={() => handleClick("Description")}>Description</a>
            </li>
            <li className={selected === "Reviews" ? "active" : ""}>
                <a href={void(0)} onClick={() => handleClick("Reviews")}>Reviews ({product[0]?.reviews.records})</a>
            </li>
            <li className={selected === "Vendor" ? "active" : ""}>
                <a href={void(0)} onClick={() => handleClick("Vendor")}>Vendor</a>
            </li>
            <li className={selected === "faq" ? "active" : ""}>
                <a href={void(0)} onClick={() => handleClick("faq")}>Questions and Answers</a>
            </li>
            </ul>
            <div className="ps-tabs">
                <div className={selected === "Description" ? "ps-tab active " : "ps-tab "} id="tab-description">
                    <div className="ps-document">
                    <div>
                        <Sanitized html={product[0]?.content} />
                    </div>
                    <br />
                    <div className="facebook-comment">
                        <div
                        className="fb-comments"
                        data-href={`/${product[0]?.slugs_key}`}
                        data-numposts="5"
                        data-width="100%"
                        ></div>
                    </div>
                    </div>
                </div>
                <div className={selected ==="Reviews" ? "ps-tab active " : "ps-tab"} id="tab-reviews">
                    <ImagesReviews dataproduct={product[0]?.id} nameproduct={product[0]?.name} />
                    {
                        product[0]?.id > 0 && 
                        <ReviewList datalist={product[0]?.id} nameproduct={product[0]?.name} />
                    }
                </div>
                <div className={selected ==="Vendor" ? "ps-tab active " : "ps-tab"} id="tab-vendor">
                    <h4>{product[0]?.mp_stores.name}</h4>
                    <div>
                        {product[0]?.mp_stores.name}
                    </div>

                    <a
                    href={`/${product[0]?.mp_stores.key}`}
                    className="more-products"
                    >
                    More Products from Global Office
                    </a>
                </div>
                <div className={selected ==="faq" ? "ps-tab active " : "ps-tab"} id="tab-faq">
                    <div className="accordion" id="faq-accordion">
                    <div className="card">
                        <div className="card-header" id="heading-faq-0">
                        <h2 className="mb-0">
                            <button
                            className="btn btn-link btn-block text-left "
                            type="button"
                            data-toggle="collapse"
                            data-target="#collapse-faq-0"
                            aria-expanded="true"
                            aria-controls="collapse-faq-0"
                            >
                            What Shipping Methods Are Available?
                            </button>
                        </h2>
                        </div>

                        <div
                        id="collapse-faq-0"
                        className="collapse  show "
                        aria-labelledby="heading-faq-0"
                        data-parent="#faq-accordion"
                        >
                        <div className="card-body">
                            Ex Portland Pitchfork irure mustache. Eutra
                            fap before they sold out literally. Aliquip
                            ugh bicycle rights actually mlkshk, seitan
                            squid craft beer tempor.
                        </div>
                        </div>
                    </div>
                    <div className="card">
                        <div className="card-header" id="heading-faq-1">
                        <h2 className="mb-0">
                            <button
                            className="btn btn-link btn-block text-left  collapsed "
                            type="button"
                            data-toggle="collapse"
                            data-target="#collapse-faq-1"
                            aria-expanded="true"
                            aria-controls="collapse-faq-1"
                            >
                            Do You Ship Internationally?
                            </button>
                        </h2>
                        </div>

                        <div
                        id="collapse-faq-1"
                        className="collapse "
                        aria-labelledby="heading-faq-1"
                        data-parent="#faq-accordion"
                        >
                        <div className="card-body">
                            Hoodie tote bag mixtape tofu. Typewriter jean
                            shorts wolf quinoa, messenger bag organic
                            freegan cray.
                        </div>
                        </div>
                    </div>
                    <div className="card">
                        <div className="card-header" id="heading-faq-2">
                        <h2 className="mb-0">
                            <button
                            className="btn btn-link btn-block text-left  collapsed "
                            type="button"
                            data-toggle="collapse"
                            data-target="#collapse-faq-2"
                            aria-expanded="true"
                            aria-controls="collapse-faq-2"
                            >
                            How Long Will It Take To Get My Package?
                            </button>
                        </h2>
                        </div>

                        <div
                        id="collapse-faq-2"
                        className="collapse "
                        aria-labelledby="heading-faq-2"
                        data-parent="#faq-accordion"
                        >
                        <div className="card-body">
                            Swag slow-carb quinoa VHS typewriter pork
                            belly brunch, paleo single-origin coffee Wes
                            Anderson. Flexitarian Pitchfork forage,
                            literally paleo fap pour-over. Wes Anderson
                            Pinterest YOLO fanny pack meggings, deep v
                            XOXO chambray sustainable slow-carb raw denim
                            church-key fap chillwave Etsy. +1 typewriter
                            kitsch, American Apparel tofu Banksy Vice.
                        </div>
                        </div>
                    </div>
                    <div className="card">
                        <div className="card-header" id="heading-faq-3">
                        <h2 className="mb-0">
                            <button
                            className="btn btn-link btn-block text-left  collapsed "
                            type="button"
                            data-toggle="collapse"
                            data-target="#collapse-faq-3"
                            aria-expanded="true"
                            aria-controls="collapse-faq-3"
                            >
                            What Payment Methods Are Accepted?
                            </button>
                        </h2>
                        </div>

                        <div
                        id="collapse-faq-3"
                        className="collapse "
                        aria-labelledby="heading-faq-3"
                        data-parent="#faq-accordion"
                        >
                        <div className="card-body">
                            Fashion axe DIY jean shorts, swag kale chips
                            meh polaroid kogi butcher Wes Anderson
                            chambray next level semiotics gentrify yr.
                            Voluptate photo booth fugiat Vice. Austin sed
                            Williamsburg, ea labore raw denim voluptate
                            cred proident mixtape excepteur mustache. Twee
                            chia photo booth readymade food truck, hoodie
                            roof party swag keytar PBR DIY.
                        </div>
                        </div>
                    </div>
                    <div className="card">
                        <div className="card-header" id="heading-faq-4">
                        <h2 className="mb-0">
                            <button
                            className="btn btn-link btn-block text-left  collapsed "
                            type="button"
                            data-toggle="collapse"
                            data-target="#collapse-faq-4"
                            aria-expanded="true"
                            aria-controls="collapse-faq-4"
                            >
                            Is Buying On-Line Safe?
                            </button>
                        </h2>
                        </div>

                        <div
                        id="collapse-faq-4"
                        className="collapse "
                        aria-labelledby="heading-faq-4"
                        data-parent="#faq-accordion"
                        >
                        <div className="card-body">
                            Art party authentic freegan semiotics jean
                            shorts chia cred. Neutra Austin roof party
                            Brooklyn, synth Thundercats swag 8-bit photo
                            booth. Plaid letterpress leggings craft beer
                            meh ethical Pinterest.
                        </div>
                        </div>
                    </div>
                    </div>
                </div>
            </div>
        </div>
    </>
  )
}
