import React, { useState, useEffect } from "react";
import axios from "axios";
// import CategoriesSubMenuMobile from "./CategoriesSubMenuMobile";

const CategoriesMenuMobile = () => {
  const [categories, setCategories] = useState([]);
  const [categoriesDetail, setCategoriesDetail] = useState([]);
  const [isActive, setActive] = useState(false);
  const apiurl = process.env.React_App_Gracetoko_Api;


  const getCategories = async () => {
    try {
      const response = await axios.get(apiurl + "/categories");
      // const mydata = response.data;
      setCategories(response.data);
      setCategoriesDetail(response.data);
    } catch (error) {
      console.log(error.response);
    }
  };

  const filterItems = (value) => {
    const newItem = categoriesDetail.filter((newVal) => {
      return newVal.parent_id === value;
    });
    return newItem;
  };

  const handleToggles = () => {
    setActive(!isActive);
  };

  useEffect(() => {
    getCategories();
  }, []);

  return (
    <>
      <div className="ps-panel__content">
        <ul className="menu--mobile">
          {categories
            .filter((headerCategories) => headerCategories.parent_id === 0)
            .map((headerCategories) => (
              <li
                key={headerCategories.id}
                className={
                  filterItems(headerCategories.id).length === 0
                    ? ""
                    : "menu-item-has-children has-mega-menu"
                }
              >
                <a href="/">{headerCategories.name}</a>
                <span
                  onClick={handleToggles}
                  className={
                    filterItems(headerCategories.id).length === 0
                      ? ""
                      : isActive
                        ? "sub-toggle active"
                        : "sub-toggle"
                  }
                ></span>
                {/* <div
                  className={isActive ? " sub-toggle active" : "sub-toggle"}
                  onClick={handleToggle}
                ></div> */}
              </li>
            ))}
          <li className="menu-item-has-children has-mega-menu">
            <a href="https://gracetoko.com/product-categories/electronics">
              ELECTRONICS
            </a>
            <div className="sub-toggle"></div>
            {/* <span className="sub-toggle"></span> */}
            {/* <div className="mega-menu">
              <div className="mega-menu__column">
                <a href="https://gracetoko.com/product-categories/consumer-electronic">
                  <h4>
                    CONSUMER ELECTRONIC <span className="sub-toggle"></span>
                  </h4>
                </a>
                <ul className="mega-menu__list">
                  <li>
                    <a href="https://gracetoko.com/product-categories/home-audio-theaters">
                      HOME AUDIO &amp; THEATERS
                    </a>
                  </li>
                </ul>
              </div>
              <div className="mega-menu__column">
                <a href="https://gracetoko.com/product-categories/accessories-parts">
                  <h4>
                    Accessories &amp; Parts<span className="sub-toggle"></span>
                  </h4>
                </a>
                <ul className="mega-menu__list">
                  <li>
                    <a href="https://gracetoko.com/product-categories/digital-cables">
                      Digital Cables
                    </a>
                  </li>
                </ul>
              </div>
            </div> */}
          </li>
          <li className="menu-item-has-children has-mega-menu">
            <a href="https://gracetoko.com/product-categories/electronics">
              ELECTRONICS
            </a>
            <div className="sub-toggle"></div>
            {/* <span className="sub-toggle"></span> */}
            {/* <div className="mega-menu">
              <div className="mega-menu__column">
                <a href="https://gracetoko.com/product-categories/consumer-electronic">
                  <h4>
                    CONSUMER ELECTRONIC <span className="sub-toggle"></span>
                  </h4>
                </a>
                <ul className="mega-menu__list">
                  <li>
                    <a href="https://gracetoko.com/product-categories/home-audio-theaters">
                      HOME AUDIO &amp; THEATERS
                    </a>
                  </li>
                </ul>
              </div>
              <div className="mega-menu__column">
                <a href="https://gracetoko.com/product-categories/accessories-parts">
                  <h4>
                    Accessories &amp; Parts<span className="sub-toggle"></span>
                  </h4>
                </a>
                <ul className="mega-menu__list">
                  <li>
                    <a href="https://gracetoko.com/product-categories/digital-cables">
                      Digital Cables
                    </a>
                  </li>
                </ul>
              </div>
            </div> */}
          </li>
          <li>
            <a href="https://gracetoko.com/product-categories/clothing">
              Clothing
            </a>
          </li>

          {/* {categories.map((cate, index) => (
            <li
              key={cate.id}
              className={
                filterItems(cate.id).length === 0
                  ? ""
                  : "menu-item-has-children has-mega-menu"
              }
            >
              <a href="/">{cate.name}</a>
              <span
                key={cate.id}
                className={
                  filterItems(cate.id).length === 0 ? "" : "sub-toggle"
                }
              ></span>
              <div className="mega-menu">
                <div className="mega-menu_column">
                  <a href="/">
                    <h4>
                      CONSUMER ELECTRONIC <span className="sub-toggle"></span>
                    </h4>
                  </a>
                  <ul className="mega-menu__list">
                    <li>
                      <a href="https://gracetoko.com/product-categories/home-audio-theaters">
                        HOME AUDIO &amp; THEATERS
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </li>
          ))} */}
        </ul>
      </div>
    </>
  );
};

export default CategoriesMenuMobile;
