import React, { useState, useEffect } from "react";
import Footer from "../Components/Footer/Footer";
import Banner from "../Components/Common/Banner";
import Sidebar from "../Components/SideBar/Sidebar";
import NewsLetter from "../Components/Common/NewsLetter";
import BlockCategories from "../Components/Common/BlockCategories";
import WidgetPromo from "../Components/Common/WidgetPromo";
import Widget2Promo from "../Components/Common/Widget2Promo";
import BlockFeatured from "../Components/Common/BlockFeatured";
import PromotionHeader from "../Components/Common/PromotionHeader";
import Features from "../Components/Common/Features"
import MainHeader from "../Components/Header/MainHeader";
import HeaderMobile from "../Components/Header/HeaderMobile";
import DealOfDay from "../Components/Common/DealOfDay";
import { ProductListDown } from "../Components/Common/ProductListDown";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export const Home = () => {

  const [latitude, setLatitude] = useState("");
  const [longitude, setLongitude] = useState("");
  const loginValidInfo = localStorage.getItem("loginValid") || false;


  useEffect(() => {
    if (loginValidInfo) {
      toast.success("Login berhasil", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      localStorage.removeItem("loginValid");

    }
    localStorage.removeItem("currentlocation");
    localStorage.removeItem("datamap");
    navigator.geolocation.getCurrentPosition((position) => {
      setLatitude(position.coords.latitude);
      setLongitude(position.coords.longitude);
      localStorage.setItem(
        "currentlocation",
        JSON.stringify({
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        })
      );
    });
  })

  return (
    <>
      <PromotionHeader />
      <MainHeader />
      <HeaderMobile />
      <div id="homepage-1">
        <Banner />
        <Features />
        <DealOfDay />
        <div className="ps-top-categories mt-40 mb-40">
          <div className="ps-container">
            <h3>Top Categories</h3>
            <BlockCategories />
          </div>
        </div>
        <WidgetPromo />
        <BlockFeatured />
        <Widget2Promo />
        <ProductListDown />
        <NewsLetter />
      </div>
      <Footer />
      <Sidebar />
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />

    </>
  );
};

export default Home;
