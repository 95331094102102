import React, { useState } from 'react'
import "./product-image-slider.scss"
import PropTypes from 'prop-types'
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Thumbs } from 'swiper';

const SlidersProducts = props => {
    const [activeThumb, setActiveThumb] = useState()
    // console.log(props);
  return (
    <>
    <Swiper
        loop={true}
        spaceBetween={10}
        navigation={true}
        thumbs={{swiper: activeThumb}}
        modules={[Navigation, Thumbs]}
        grabCursor={true}
        className='product-images-slider'
    >
        {

            props.images.map((item, index) =>  (
                <SwiperSlide key={index}>
                    <img src={item} alt="product images" />
                </SwiperSlide>
            ))


        }
        
    </Swiper>
    <Swiper
        loop={true}
        onSwiper={setActiveThumb}
        spaceBetween={10}
        slidesPerView={4}
        modules={[Navigation, Thumbs]}
        className='product-images-slider-thumbs'
    >
        {
            props.images.map((item, index) => (
                <SwiperSlide key={index}>
                    <div className='product-images-slider-thumbs-wrapper'>
                        <img src={item} alt="product images" />
                    </div>
                </SwiperSlide>
            ))

        }
    </Swiper>
    </>
  )
}

SlidersProducts.propTypes = {
    images: PropTypes.array.isRequired
}

export default SlidersProducts
