import React from 'react'
import useFetch from '../../hooks/useFetch'

const CategoriesMaps = ({product_id}) => {

    const {data, loading, error} = useFetch(`/productcategories/${product_id}`)
    // console.log(data);

  return (
    <>
    {
        data &&
        data.map((categories,index) => (
            <a href={`/product-categories/${categories.link}`} key={index}>
              {categories.name} {' / '}
            </a>
        ))
    }
    </>
  )
}

export default CategoriesMaps