import { useState, useEffect } from 'react';
import Select, { components } from 'react-select';
import AsyncSelect from 'react-select/async';
import { Card, CardHeader, CardTitle, CardBody, Row, Col, Label } from 'reactstrap';
import axios from '../../hooks/useAxios';

const SelectShipdeo = ({ onSelected, datashipdeo }) => {
    const [query, setQuery] = useState('');
    const [selectedDBVal, setSelectedDBVal] = useState(null);
    const [value, setValue] = useState();

    const handleDBInputChange = newValue => {
        setQuery(newValue)
    }

    const handleDBChange = value => {
        // console.log(value);
        onSelected(value);
        setSelectedDBVal(value)
    }

    const loadOptionsDB = async (inputvalue) => {

        if (inputvalue.length > 4) {
            let _token = await axios.get("/shipdeoToken");
            let data = {
                'token': _token.data.data.accessToken,
                'input': inputvalue
            };
            try {
                const response = await axios.post("/shipdeoPostalCode", data);
                const mydata = response.data;
                let hasil = mydata.data.data;
                let alamat = [];
                hasil.map((dt) => {
                    alamat.push(
                        {
                            value: dt.village_code + ":" + dt.subdistrict_code + ":" + dt.city_code + ":" + dt.province_code + ":" +
                                dt.village_name + ":" + dt.subdistrict_name + ":" + dt.city_name + ":" + dt.province_name + ":" + dt.postal_code,
                            label: dt.village_name + " " + dt.subdistrict_name + " " + dt.city_name + " " + dt.province_name + " (" + dt.postal_code + ")"
                        }
                    )
                })
                return alamat;
            } catch (error) {
                console.log(error);
            }
        }
    }

    useEffect(() => {
        if (Object.keys(datashipdeo).length !== 0) {
            setSelectedDBVal({
                value: datashipdeo.village_code + ":" + datashipdeo.subdistrict_code + ":" + datashipdeo.city_code + ":" + datashipdeo.province_code + ":" +
                    datashipdeo.village_name + ":" + datashipdeo.subdistrict_name + ":" + datashipdeo.city_name + ":" + datashipdeo.province_name + ":" + datashipdeo.zip_code,
                label: datashipdeo.village_name + " " + datashipdeo.subdistrict_name + " " + datashipdeo.city_name + " " + datashipdeo.province_name + " (" + datashipdeo.zip_code + ")"

            })
        }
    }, [])

    return (
        <>
            <AsyncSelect
                defaultOptions
                isClearable={true}
                value={selectedDBVal}
                name='db-react-select'
                className='react-select'
                classNamePrefix='select'
                onChange={handleDBChange}
                loadOptions={loadOptionsDB}
                onInputChange={handleDBInputChange}
            />
        </>
    )
}

export default SelectShipdeo;


