import { useState, useEffect } from "react";
import axios from "./useAxios";
import jwt_decode from "jwt-decode";
import tokenService from "../config/actions/token.service";
import { useDispatch } from "react-redux";
import { refreshToken } from "../config/actions/auth";

const useAuthtoken = () => {

  const localToken = tokenService.getLocalAccessToken();
  const expiredToken = jwt_decode(localToken).exp;
  const dispatch = useDispatch();

  const [datalogin, setDatalogin] = useState([]);
  const [errorlogin, setErrorlogin] = useState(false);
  const [tokenlogin, setTokenlogin] = useState(null);

  useEffect(() => {

    const reUseAuthToken = async () => {
      const axiosJWT = axios.create(); // bebas variable

      axiosJWT.interceptors.request.use(
        async (config) => {
          if (expiredToken * 1000 < Date.now()) {
            const response = await axios.get("/token");
            config.headers.Authorization = `Bearer ${response.data.accessToken}`;
            // const decoded = jwt_decode(response.data.accessToken);
            tokenService.updateLocalAccessToken(response.data.accessToken);
            await dispatch(refreshToken(response.data.accessToken));
          }
          return config;
        },
        (error) => {
          setErrorlogin(error);
          return Promise.reject(error);
        }
      );

      try {
        const response = await axiosJWT.get("/token");
        if (response.data.result === "OK") {
          setTokenlogin(response.data.accessToken);
          const decoded = jwt_decode(response.data.accessToken);
          setDatalogin(decoded);
        }
      } catch (error) {
        setErrorlogin(error)
      }
    }

    reUseAuthToken();
  }, [setDatalogin]);

  return { datalogin, tokenlogin, errorlogin };

};

export default useAuthtoken;