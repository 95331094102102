import React, { useState } from "react";
import { useNavigate, Redirect } from "react-router-dom";
// import CustomerSideBar from "./SideBar";
// import Header from "../../Components/Header/Header";
// import HeaderMobile from "../../Components/Header/HeaderMobile";
// import Breadcrumb from "../../Components/Common/Breadcrumb";
// import Sidebar from "../../Components/SideBar/Sidebar";
// import ProductFooter from "../../Components/Footer/ProductFooter";
import jwt_decode from "jwt-decode";
import { useFormik } from "formik";
import * as Yup from "yup";
import MapModal from "../../Components/Maps/Maps.js";
import { useSelector } from "react-redux";

const CreateAddress = () => {


    const { userLogin } = useSelector(state => state.userReducer);


    // const history = useNavigate();
    // const arrCumb = [
    //     {
    //         "name": "Address book",
    //         "link": "customer/address"
    //     }
    // ]

    const formik = useFormik({
        initialValues: {
            fullname: "",
            emailAddress: "",
            phone: "",
            fulladdress: "",
            isDefault: false,
        },
        validationSchema: Yup.object({
            fullname: Yup.string()
                .max(50, "Must be 20 Character or less")
                .required("Required"),
            emailAddress: Yup.string().email("Invalid email address").required("Required"),
            phone: Yup.string().required("Required"),
            fulladdress: Yup.string().required("Required"),
            isDefault: Yup.boolean(),
        }),
        onSubmit: async (values, { setSubmitting }) => {
            console.log(values)
        }
    })

    return (
        <>
            {/* <Header />
            <HeaderMobile />
            <Breadcrumb pagename={arrCumb} />
            <Sidebar /> */}
            <form onSubmit={formik.handleSubmit}>
                <div className="ps-form__header"><h3>Create Addressxxx</h3></div>
                <div className="ps-form__content">
                    <div className="form-group">
                        <label htmlFor="name">Pilih Lokasi: {userLogin.name}</label>
                        <MapModal />
                    </div>
                    <div className="form-group">
                        <label htmlFor="name">Full Name:</label>
                        <input
                            type="text"
                            className="form-control"
                            name="fullname"
                            value={formik.values.fullname}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                        />
                        {formik.touched.fullname && formik.errors.fullname ? (
                            <span className="text-danger">{formik.errors.fullname}</span>
                        ) : null}
                    </div>
                    <div className="form-group">
                        <label htmlFor="email">Email:</label>
                        <input
                            type="text"
                            className="form-control"
                            name="emailAddress"
                            value={formik.values.emailAddress}
                            onBlur={formik.handleBlur}
                            onChange={formik.handleChange}
                        />
                        {formik.touched.emailAddress && formik.errors.emailAddress ? (
                            <span className="text-danger">{formik.errors.emailAddress}</span>
                        ) : null}
                    </div>
                    <div className="form-group">
                        <label htmlFor="phone">Phone:</label>
                        <input
                            type="text"
                            className="form-control"
                            name="phone"
                            value={formik.values.phone}
                            onBlur={formik.handleBlur}
                            onChange={formik.handleChange}
                        />
                        {formik.touched.phone && formik.errors.phone ? (
                            <span className="text-danger">{formik.errors.phone}</span>
                        ) : null}
                    </div>
                    <div className="form-group">
                        <label htmlFor="address">Address:</label>
                        <textarea
                            className="form-control"
                            name="fulladdress"
                            value={formik.values.fulladdress}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            rows="3"
                        />
                    </div>
                    <div className="form-group">
                        <div className="ps-checkbox">
                            <input
                                id="default_address"
                                type="checkbox"
                                name="isDefault"
                                value={formik.values.isDefault}
                                onChange={formik.handleChange}
                            />
                            <label htmlFor="default_address">Use this address as default</label>
                        </div>
                    </div>
                    <div className="form-group">
                        <button className="ps-btn ps-btn--sm" type="submit">Add a new address</button>
                    </div>
                </div>
            </form>
            {/* <CustomerSideBar pageid="address" /> */}
            {/* <section className="ps-section--account crop-avatar customer-page">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-8">
                            <div className="ps-section__right">
                            </div>
                        </div>
                    </div>
                </div>
            </section> */}

        </>
    )
}

export default CreateAddress