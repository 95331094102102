import React, { useState, Component } from 'react'
import useFetch from '../../hooks/useFetch'
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";
import ProgressBar from '../../Components/ProgressBar';

export const ImagesReviews = ({dataproduct, nameproduct}) => {

    // const [model, setModel] = useState(false);
    // const [tempdata, setTempdata] = useState([]);
    let counterImages = 0;
    const [showModal, setShowModal] = useState(false);
    const [indexOfImages, setIndexOfImages] = useState(0);
    
    const openModalAndSetIndex = (index) => {
        console.log(index);
        setIndexOfImages(index);
        setShowModal(true);
        return;
    }
    
    // const getData = (img, title, desc) => {
    //     let tempData = [img, title, desc];
    //     setTempdata(item => [1, ...tempData]);
    //     return setModel(true);
    // }
    
    let idproduct = dataproduct ;
    let imagesCounter = 0;
    let images = [];
    let captions = [];
    let records = 0;
    let star1 = 0;
    let star2 = 0;
    let star3 = 0;
    let star4 = 0;
    let star5 = 0;
    let persenStars = 0;
    let totalStars = 0;
    let valueStars = 0;
    let nstar1 = 0;
    let nstar2 = 0;
    let nstar3 = 0;
    let nstar4 = 0;
    let nstar5 = 0;

    const {data, loading, error} = useFetch(`/reviews/${idproduct}`);

    if (data.length > 0) {
        let customerName 
        let customerNote
        let customerStar
        records = data.length;

        data.forEach(obj => {
            Object.entries(obj).forEach(([key, value]) => {
                if (key == 'comment') {
                    customerNote = value || '';
                }
                if (key == 'star') {
                    customerStar = value || 0
                    switch (customerStar) {
                        case 1: 
                          star1 ++;
                          break;
                        case 2: 
                          star2 ++;
                          break;
                        case 3: 
                          star3 ++;
                          break;
                        case 4: 
                          star4 ++;
                          break;
                        case 5: 
                          star5 ++;
                          break;
                    }
                }
                if (obj.ec_customer != null) {
                    customerName = obj.ec_customer.name
                } else {
                    customerName = "N/A"
                }
                if (key=='images') {
                    if (key == 'ec_customer') {
                        if (value != null) {
                            customerName = value.name
                        } else {
                            customerName = "N/A"
                        }
                    }
                    let imgs = JSON.parse(value);
                    for (var i=0; i < imgs.length; i++) {
                        let img = imgs[i]
                        images.push({img,customerNote, customerStar, customerName});
                        captions.push(customerNote + " (" + customerName+")");
                    }
                    imagesCounter = imagesCounter + imgs.length;
                }
              });
        })

        totalStars = (star1 * 1) + (star2 * 2) + (star3 * 3) + (star4 * 4) + (star5 * 5);
        valueStars = totalStars / records;
        persenStars = (valueStars * 100) / 5

        nstar1 = star1 / records * 100;
        nstar2 = star2 / records * 100;
        nstar3 = star3 / records * 100;
        nstar4 = star4 / records * 100;
        nstar5 = star5 / records * 100;
        
    }



  return (
    <>
        <div className="row">
            <div className="col-lg-4 col-sm-4">
                <div className="ps-block--average-rating">
                    <div className="ps-block__header">
                        <h3>{valueStars.toFixed(2)}</h3>
                        <div className="rating_wrap">
                            <div className="rating">
                                <div
                                className="product_rate"
                                style={{ width: `${Math.ceil(persenStars)}%` }}
                                ></div>
                            </div>
                            <span className="rating_num">
                                ({records} reviews)
                            </span>
                        </div>
                    </div>
                <div
                    className="ps-block__star" >
                    <span>5 Star</span>
                    <div style={{
                        "position": "relative",
                        "height": "10px",
                        "width": "100%",
                        "maxWidth": "230px",
                        "backgroundColor": "#f1f1f1",
                    }}>
                        <ProgressBar bgcolor="orange" progress={nstar5}  height={10} />
                    </div>
                    <span>{star5}</span>
                </div>
                <div className="ps-block__star ">
                    <span>4 Star</span>
                    <div style={{
                        "position": "relative",
                        "height": "10px",
                        "width": "100%",
                        "maxWidth": "230px",
                        "backgroundColor": "#f1f1f1",
                    }}>
                        <ProgressBar bgcolor="orange" progress={nstar4}  height={10} />
                    </div>
                    <span>{star4}</span>
                </div>
                <div
                    className="ps-block__star ">
                    <span>3 Star</span>
                    <div style={{
                        "position": "relative",
                        "height": "10px",
                        "width": "100%",
                        "maxWidth": "230px",
                        "backgroundColor": "#f1f1f1",
                    }}>
                        <ProgressBar bgcolor="orange" progress={nstar3}  height={10} />
                    </div>
                    <span>{star3}</span>
                </div>
                <div className="ps-block__star">
                    <span>2 Star</span>
                    <div style={{
                        "position": "relative",
                        "height": "10px",
                        "width": "100%",
                        "maxWidth": "230px",
                        "backgroundColor": "#f1f1f1",
                    }}>
                        <ProgressBar bgcolor="orange" progress={nstar2}  height={10} />
                    </div>
                    <span>{star2}</span>
                </div>
                <div
                    className="ps-block__star ">
                    <span>1 Star</span>
                    <div style={{
                        "position": "relative",
                        "height": "10px",
                        "width": "100%",
                        "maxWidth": "230px",
                        "backgroundColor": "#f1f1f1",
                    }}>
                        <ProgressBar bgcolor="orange" progress={nstar1}  height={10} />
                    </div>
                    <span>{star1}</span>
                </div>
                </div>
            </div>
        </div>

        <div className="my-3">
            <h4>Images from customer ({imagesCounter})</h4>
            <div className="block--review">
                <div className="block__images row m-0 block__images_total">
                    {
                        images.map((img,id) => (
                            <div className="col-lg-1 col-sm-2 col-3 more-review-images" key={(id)}>
                                <div className="border position-relative rounded">
                                    <img src={`https://gracetoko.com/storage/products/${img.img}`}
                                        onError={(e)=>{e.target.onerror = null; e.target.src=process.env.PUBLIC_URL+"/assets/img/placeholder.png"}}
                                        alt={img.customerNote} 
                                        onClick={() => {openModalAndSetIndex(id)}}
                                        className="img-responsive rounded h-100" 
                                    /> 
                                </div>
                            </div>
                        ))
                    }
                </div>
            </div>
        </div>

        {showModal && (
            <Lightbox
            mainSrc={images[indexOfImages]}
            nextSrc={images[(indexOfImages + 1) % images.length]}
            prevSrc={
                images[
                (indexOfImages + images.length - 1) % images.length
                ]
            }
            imageCaption={captions[indexOfImages]}
            onCloseRequest={() => setShowModal(false)}
            onMovePrevRequest={() =>
                setIndexOfImages(
                (indexOfImages + images.length - 1) % images.length
                )
            }
            onMoveNextRequest={() =>
                setIndexOfImages(
                (indexOfImages + images.length + 1) % images.length
                )
            }
            />
        )}

    </>
  )

//   function ViewImages(dataimages) {
//     let data = dataimages.dataimages;
//     let rvComment = data.comment;
//     let rvUsername = 'N/A'; 
//     let rvStar = data.star;
//     let rvImages = [];
//     if (data.ec_customer != null) {
//         rvUsername = data.ec_customer.name;
//     }
//     if (data.images != null) {
//         rvImages = JSON.parse(data.images);
//     }

//     // console.log(rvImages);

//     counterImages ++;

//     // console.log(counterImages)


//     return (
//         <>
//         {
//             React.Children.toArray(
//                 rvImages.map((img,id) => (
//                     <div className="col-lg-1 col-sm-2 col-3 more-review-images" key={(counterImages+id)}>
//                         <div className="border position-relative rounded">
//                             <img src={`https://gracetoko.com/storage/products/${img}`}
//                                 onError={(e)=>{e.target.onerror = null; e.target.src=process.env.PUBLIC_URL+"/assets/img/placeholder.png"}}
//                                 alt={img} 
//                                 onClick={() => {openModalAndSetIndex(id)}}
//                                 className="img-responsive rounded h-100" 
//                             /> 
//                             <p>{id}</p>
//                         </div>
//                     </div>
//                 ))

//             )

//         }
//         </>
//     )
    
//     }

}
