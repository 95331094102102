import React, { useEffect, useState } from 'react';
import axios from '../../hooks/useAxios';

const VariantDetail = ({ data, onDataChange }) => {

    // dataparam, id, attributeClicked, variantClicked
    const pars = data;

    // console.log("nindex : " + data.nindex);
    // console.log("Variant1 : ")
    // console.log(data.variant1);

    const [dataStock, setDataStock] = useState(null);
    const [recordFound, setRecordFound] = useState(0);
    const [idVariant1d, setIdVariant1d] = useState(data.variant1?.attribute_id || 0);

    function handleClickVariant(dataVar) {
        setIdVariant1d(dataVar.attribute_id)
        let nilai = dataVar.attribute_id;
        // console.log("NILAI ON Variant Detail : " + nilai)
        onDataChange(dataVar)
    }

    const GetDataVariants = async () => {

        try {
            const res = await axios.get(`/productvariantswithattr/${pars.kodebarang}?variant1=${pars.idVariant0}&variant2=${pars.attr_id}`);
            setDataStock(res.data);
            setRecordFound(res.data.length);
        } catch (error) {
            console.log(error)
        }
    }


    useEffect(() => {
        GetDataVariants();
    }, [])

    return (
        <>
            {
                <li
                    id={pars.nindex}
                    onClick={(e) => handleClickVariant(pars.dataparam)}
                    data-slug={pars.dataparam.slug}
                    data-id={pars.dataparam.attribute_id}
                    className={recordFound > 0 ? "attribute-swatch-item" : " attribute-swatch-item pe-none "}
                >
                    <div>
                        <label>
                            {/* {pars.dataparam.attribute_id} */}
                            <input
                                className="product-filter-item "
                                type="radio"
                                name={`attribute_${pars.judul}`}
                                onChange={() => { }}
                                value={pars.dataparam.attribute_id}
                                checked={pars.dataparam.attribute_id === idVariant1d}
                            />
                            <span>{pars.dataparam.title}</span>
                        </label>
                    </div>
                </li >
            }
        </>
    )
}

export default VariantDetail