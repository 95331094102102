const CategoriesSubMenu = ({ categoriesDetail, parent_id_new }) => {
  const filterItems = (value) => {
    const newItem = categoriesDetail.filter((newVal) => {
      return newVal.parent_id === value;
    });
    return newItem;
  };
  const detailMenu = filterItems(parent_id_new);
  return (
    <>
      <span className="sub-toggle"></span>
      <div className="mega-menu">
        {detailMenu.map((cated, index) => (
          <div className="mega-menu__column" key={cated.id}>
            <h4>
              {cated.name}
              <span className="sub-toggle"></span>
            </h4>
            <ul className="mega-menu__list">
              {categoriesDetail
                .filter(
                  (dataCategories) => dataCategories.parent_id === cated.id
                )
                .map((dataCategories) => (
                  <li key={dataCategories.id}>
                    <a href={`product-categories/${dataCategories.slugs.key}`}>{dataCategories.name}</a>
                  </li>
                ))}
            </ul>
          </div>
        ))}
      </div>
    </>
  );
};

export default CategoriesSubMenu;
