import CryptoJS from "crypto-js";
import jwtDecode from "jwt-decode";


function decryptJWT() {
    const token = localStorage.getItem("tjwt")
    const bytes = CryptoJS.AES.decrypt(token, process.env.React_APP_SecretKey);
    return bytes.toString(CryptoJS.enc.Utf8);
}

class TokenService {

    getLocalRefreshToken() {
        const user = JSON.parse(decryptJWT());
        return user?.refreshToken;
    }

    encryptToken(token) {
        const encdata = CryptoJS.AES.encrypt(token, process.env.React_APP_SecretKey);
        return encdata;
    }

    getLocalAccessToken() {
        return (decryptJWT());
        // const user = JSON.parse(localStorage.getItem("tjwt"));
        // return user?.accessToken;
    }

    updateLocalAccessToken(token) {
        // console.log("Update local storage JWT")
        // localStorage.setItem("jwt", JSON.stringify("jwt"));
        const encdata = CryptoJS.AES.encrypt(token, process.env.React_APP_SecretKey)
        localStorage.setItem("tjwt", encdata);
    }

    getUser() {
        return JSON.parse(localStorage.getItem("tjwt"));
    }

    setUser(user) {
        console.log(JSON.stringify(user));
        localStorage.setItem("user", JSON.stringify("tjwt"));
    }

    removeUser() {
        localStorage.removeItem("tjwt");
    }


}

export default new TokenService();