import React, { useEffect, useState } from 'react'
import axios from '../../hooks/useAxios';
import Header from "../../Components/Header/Header";
import HeaderMobile from "../../Components/Header/HeaderMobile";
import Footer from '../../Components/Footer/Footer';
import Sidebar from '../../Components/SideBar/Sidebar';
import Breadcrumb from '../../Components/Common/Breadcrumb';
import MainHeader from '../../Components/Header/MainHeader';
import CardsProducts from '../../Components/CardsProducts';
import ReactPaginate from "react-paginate";

const ShopPage = ({data}) => {

  let store_url = data[0].key;
  let store_id = data[0].id;

  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(12);
  const [pages, setPages] = useState(0);
  const [rows, setRows] = useState(0);
  const [productdata, setProductdata] = useState([]);
  const [sortOrderBy, setSortOrderBy] = useState("");

  const arrCumb = [
    {
      "name" : "Products",
      "link" : "products"
    }    
  ]

  const changePage = ({selected, }) => {
    setPage(selected);
  }


  const getProducts = async() => {
    try {
      const res = await axios.get(`/products?store=${store_url || ''}&sortby=${sortOrderBy || ''}&page=${page}&limit=${limit}`);
      setPage(res.data.current_page);
      setRows(res.data.total_rows);
      setPages(res.data.total_page);
      setProductdata(res.data.data)   
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(()=>{
    getProducts();
  },[page, sortOrderBy])



  return (
    <>
      {/* <Header /> */}
      <MainHeader />
      <HeaderMobile />
      {/* <Breadcrumb /> */}
      <Breadcrumb pagename={arrCumb} />
      <div className="ps-page--shop">
      <div className="ps-container">
        <div className="mt-40 mb-40">
          <div className="ps-page--single ps-page--vendor">
            <section className="ps-store-list">
              <div className="container">
                <aside className="ps-block--store-banner" >
                  <div className="ps-block__user" style={{backgroundColor:"#6e1818"}}>
                    <div className="ps-block__user-avatar">
                      <img src="http://localhost:8000/storage/stores/3-150x150.png" alt={data[0].name} />
                      <div className="rating_wrap">
                        <div className="rating">
                          <div className="product_rate" style={{"width": "65%"}}></div>
                        </div>
                        <span className="rating_num">(24)</span>
                      </div>
                    </div>
                    <div className="ps-block__user-content">
                      <h3 className="text-white">{data[0].name}</h3>
                      <p><i className="icon-map-marker"></i> 7167 Ankunding Mountains Apt. 246, Veronicahaven, Louisiana, </p>
                      <p><i className="icon-telephone"></i> +19066953012</p>
                      <p><i className="icon-envelope"></i> <a href="mailto:gdibbert@example.org">gdibbert@example.org</a></p>
                    </div>
                  </div>
                </aside>
                <div className='ps-section__wrapper'>
                  <div className="ps-shopping ps-tab-root">
                    <div className="ps-shopping__header">
                      <p>
                        <strong> {rows}</strong> Products found
                      </p>
                      <div className="ps-shopping__actions">
                        <select onChange={(e) => {
                          const selectedOrderBy = e.target.value;
                          setSortOrderBy(selectedOrderBy)
                        }} data-placeholder="Sort Items" >
                          <option value="slates">Sort by sslatest</option>
                          <option value='srating'>Sort by average rating</option>
                          <option value='slohi'>Sort by price: low to high</option>
                          <option value='shilo'>Sort by price: high to low</option>
                        </select>
                      </div>
                    </div>
                    <div className="ps-tabs">
                        <div className="ps-shopping-product">
                          <div className="row">
                              {
                                productdata.map((products)=> (
                                    <div key={products.id} className="col-xl-3 col-lg-4 col-md-4 col-sm-6 col-6 ">
                                      <CardsProducts data={products}/>
                                    </div>
                                ))
                              }
                            </div>
                        </div>
                        <div className="ps-pagination" style={{paddingTop:"1px"}}>
                          <ReactPaginate
                            pageCount={pages}
                            onPageChange={changePage} 
                            activeLinkClassName={"active"}
                            containerClassName={"pagination"}
                            pageClassName={'page-item'}
                            breakLabel={'...'}
                            pageLinkClassName={'page-item'}
                            activeClassName={'active hidden'}
                          />
                        </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>    
      </div>
      <Footer/>
      <Sidebar />
    </>
  )
}

export default ShopPage 