import CryptoJS from "crypto-js";
import jwt_decoded from "jwt-decode";
import {
    CART_ITEM_ADD_REQUEST,
    CART_ITEM_ADD_SUCCESS,
    CART_ITEM_ADD_FAIL,
    CART_ITEM_UPDATE_REQUEST,
    CART_ITEM_UPDATE_SUCCESS,
    CART_ITEM_UPDATE_FAIL,
    CART_ITEM_REMOVE_REQUEST,
    CART_ITEM_REMOVE_SUCCESS,
    CART_ITEM_REMOVE_FAIL,
    CART_LIST_REQUEST,
    CART_LIST_SUCCESS,
    CART_LIST_FAIL,
    CART_ITEM_CHECKBOX_REQUEST,
    CART_ITEM_CHECKBOX_SUCCESS,
    CART_ITEM_CHECKBOX_FAIL,
    CART_SHOP_ITEM_CHECKBOX_FAIL,
    CART_SHOP_ITEM_CHECKBOX_SUCCESS,
    CART_SHOP_ITEM_CHECKBOX_REQUEST,
    CART_USER_ITEM_CHECKBOX_FAIL,
    CART_USER_ITEM_CHECKBOX_REQUEST,
    CART_USER_ITEM_CHECKBOX_SUCCESS
} from "./types";
import { db } from "../../Components/Firebase/firebaseConfig";
import {
    ref,
    set,
    get,
    update,
    remove,
    child,
    key,
    push,
    query,
    orderByChild,
    orderByKey,
    equalTo,
    getDatabase
} from "firebase/database";
import { async } from "@firebase/util";

const userObjJWT = localStorage.getItem("tjwt");
let user
let encData

if (userObjJWT != null) {
    const bytes = CryptoJS.AES.decrypt(localStorage.getItem("tjwt"), process.env.React_APP_SecretKey);
    encData = bytes.toString(CryptoJS.enc.Utf8);
    user = jwt_decoded(encData);
}

export const addProductToCart = (new_cart_item, variants) => async (dispatch) => {
    // console.log("Product : ", new_cart_item)
    // console.log("Variant : ", variants);
    let idVariant = 0;
    let linkVariant = "";
    let linkStore = "";
    let nameStore = "";

    if (typeof (variants) != "undefined" && variants != null) {
        idVariant = variants.id;
        linkVariant = variants.slugs_key;
        nameStore = variants.mp_stores.name;
        linkStore = variants.mp_stores.key;
    } else {
        linkVariant = new_cart_item.slugs_key;
        nameStore = new_cart_item.mp_stores.name;
        linkStore = new_cart_item.mp_stores.key;
    }

    const newCartProduct = {};
    try {
        dispatch({
            type: CART_ITEM_ADD_REQUEST,
        })

        let oldQtyInCart = 0;
        let childKey = "";

        const queryCart = await query(ref(db, 'Cart/' + user.name), orderByChild('itemcode'), equalTo(new_cart_item.id));
        await get(queryCart).then((snapshot) => {
            snapshot.forEach((child) => {
                oldQtyInCart = child.val().qtyInCart;
                childKey = child.key;
                // console.log(child.key, child.val().qtyInCart);
            });
        })

        var today = new Date().toLocaleString();


        if (childKey === "") {
            await push(ref(db, "Cart/" + user.name), {
                itemcode: new_cart_item.id,
                url: linkVariant,
                title: new_cart_item.name,
                variants: idVariant,
                price: new_cart_item.price,
                saleprice: new_cart_item.sale_price,
                image: new_cart_item.image,
                qtyInCart: 1,
                weight: new_cart_item.weight,
                storeUrl: linkStore,
                storeName: nameStore,
                checkStatus: 0,
                cartDate: today
            })
            console.log("Tambah data baru");
            dispatch({
                type: CART_ITEM_ADD_SUCCESS,
                payload: newCartProduct
            })

        } else {
            await update(ref(db, "Cart/" + user.name + "/" + childKey), {
                qtyInCart: oldQtyInCart + 1
            })
            console.log("Sudah ada ... update qty only");
            dispatch({
                type: CART_ITEM_ADD_SUCCESS,
                payload: newCartProduct
            })

        }


    } catch (error) {
        console.log(error);
        dispatch({
            type: CART_ITEM_ADD_FAIL,
            payload: error.response && error.response.data.message
                ? error.response.data.message
                : error.message
        })
    }
}

export const listCartitems = () => async (dispatch) => {

    let cartData = [];

    async function getCartItems() {
        const rootReference = ref(db);
        const dbGet = await get(child(rootReference, "Cart/" + user.name));
        const dbValue = dbGet.val();
        const cartList = Object.values(dbValue || {});
        // console.log("cart action reducer : ", cartList)
        return cartList;
    }

    try {
        dispatch({ type: CART_LIST_REQUEST });
        cartData = await getCartItems();
        dispatch({ type: CART_LIST_SUCCESS, payload: cartData });
    } catch (error) {
        // console.log(error.message)
        dispatch({
            type: CART_LIST_FAIL,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message
        })

    }

}

export const updateProductCart = (cart_item_id, qty) => async (dispatch) => {
    try {
        dispatch({
            type: CART_ITEM_UPDATE_REQUEST
        })

        let childKey = "";

        const queryCart = await query(ref(db, 'Cart/' + user.name), orderByChild('itemcode'), equalTo(cart_item_id));
        await get(queryCart).then((snapshot) => {
            snapshot.forEach((child) => {
                // oldQtyInCart = child.val().qtyInCart;
                childKey = child.key;
                // console.log(child.key, child.val().qtyInCart);
            });
        })

        var today = new Date().toLocaleString();

        if (childKey !== '') {
            await update(ref(db, "Cart/" + user.name + "/" + childKey), {
                qtyInCart: qty
            })
            dispatch({
                type: CART_ITEM_UPDATE_SUCCESS,
                // payload: newCartProduct
            })
        } else {
            dispatch({
                type: CART_ITEM_UPDATE_FAIL,
                payload: "DATA NOT FOUND"
            })
        }
    } catch (error) {
        console.log(error);
        dispatch({
            type: CART_ITEM_UPDATE_FAIL,
            payload: error.response && error.response.data.message
                ? error.response.data.message
                : error.message
        })

    }

}

export const deleteProductCart = (cart_item_id) => async (dispatch) => {
    try {

        dispatch({
            type: CART_ITEM_REMOVE_REQUEST
        });

        let childKey = "";


        const queryCart = await query(ref(db, 'Cart/' + user.name), orderByChild('itemcode'), equalTo(cart_item_id));
        await get(queryCart).then((snapshot) => {
            snapshot.forEach((child) => {
                childKey = child.key;
            });
        })

        console.log("Firebase Node : ", childKey);

        if (childKey != "") {
            await remove(ref(db, 'Cart/' + user.name + "/" + childKey))
            dispatch({
                type: CART_ITEM_REMOVE_SUCCESS
            })

        } else {
            dispatch({
                type: CART_ITEM_REMOVE_FAIL,
                payload: "Error Remove Cart Item"
            })
        }
    } catch (error) {
        console.log(error);
        dispatch({
            type: CART_ITEM_REMOVE_FAIL,
            payload: error.response && error.response.data.message
                ? error.response.data.message
                : error.message
        })

    }
}

export const checkStateCartShop = (cart_shop_id, checkstate) => async (dispatch) => {
    try {
        dispatch({
            type: CART_SHOP_ITEM_CHECKBOX_REQUEST
        });

        let childKeys = [];

        const queryCart = await query(ref(db, 'Cart/' + user.name), orderByChild('storeUrl'), equalTo(cart_shop_id));
        await get(queryCart).then((snapshot) => {
            snapshot.forEach((child) => {
                childKeys.push(child.key);
            });
        })
        var today = new Date().toLocaleString();
        if (childKeys.length > 0) {
            childKeys.forEach(async (data) => {
                // console.log(data);
                await update(ref(db, "Cart/" + user.name + "/" + data), {
                    checkStatus: (checkstate === 'Selected' ? 1 : 0)
                })
            })
            dispatch({
                type: CART_SHOP_ITEM_CHECKBOX_SUCCESS,
                // payload: newCartProduct
            })
        } else {
            dispatch({
                type: CART_SHOP_ITEM_CHECKBOX_FAIL,
                payload: "DATA NOT FOUND"
            })
        }
    } catch (error) {
        dispatch({
            type: CART_SHOP_ITEM_CHECKBOX_FAIL,
            payload: error.response && error.response.data.message
                ? error.response.data.message
                : error.message
        })
    }
}

export const checkStateCartitem = (cart_item_id, checkstate) => async (dispatch) => {
    try {
        dispatch({
            type: CART_ITEM_CHECKBOX_REQUEST
        })

        let childKey = "";

        const queryCart = await query(ref(db, 'Cart/' + user.name), orderByChild('itemcode'), equalTo(cart_item_id));
        await get(queryCart).then((snapshot) => {
            snapshot.forEach((child) => {
                // oldQtyInCart = child.val().qtyInCart;
                childKey = child.key;
                // console.log(child.key, child.val().qtyInCart);
            });
        })

        var today = new Date().toLocaleString();

        if (childKey !== '') {
            await update(ref(db, "Cart/" + user.name + "/" + childKey), {
                checkStatus: (checkstate === 'Selected' ? 1 : 0)
            })
            dispatch({
                type: CART_ITEM_CHECKBOX_SUCCESS,
                // payload: newCartProduct
            })
        } else {
            dispatch({
                type: CART_ITEM_CHECKBOX_FAIL,
                payload: "DATA NOT FOUND"
            })
        }
    } catch (error) {
        console.log(error);
        dispatch({
            type: CART_ITEM_CHECKBOX_FAIL,
            payload: error.response && error.response.data.message
                ? error.response.data.message
                : error.message
        })
    }
}

export const checkStateCartUser = (checkstate) => async (dispatch) => {
    console.log("all check user : ", checkstate)
    user = jwt_decoded(encData);
    console.log(user);

    try {
        dispatch({
            type: CART_USER_ITEM_CHECKBOX_REQUEST
        });

        let childKeys = [];


        const queryCart = await query(ref(db, 'Cart/' + user.name));
        await get(queryCart).then((snapshot) => {
            snapshot.forEach((child) => {
                childKeys.push(child.key);
            });
        })

        if (childKeys.length > 0) {
            childKeys.forEach(async (data) => {
                // console.log(data);
                await update(ref(db, "Cart/" + user.name + "/" + data), {
                    checkStatus: (checkstate === 'Selected' ? 1 : 0)
                })
            })
            dispatch({
                type: CART_USER_ITEM_CHECKBOX_SUCCESS,
                // payload: newCartProduct
            })
        } else {
            dispatch({
                type: CART_USER_ITEM_CHECKBOX_FAIL,
                payload: "DATA NOT FOUND"
            })
        }


    } catch (error) {
        dispatch({
            type: CART_USER_ITEM_CHECKBOX_FAIL,
            payload: error.response && error.response.data.message
                ? error.response.data.message
                : error.message
        })
    }


}