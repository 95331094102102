import React, {useState, useEffect} from 'react';
import InfiniteScroll from "react-infinite-scroll-component";
import CardsProducts from '../CardsProducts';
import axios from '../../hooks/useAxios';

export const ProductListDown = () => {

    const [products, setProducts] = useState([]);
    const [lastId, setLastId] = useState(0);
    const [tempId, setTempId] = useState(0);
    const [limit, setLimit] = useState(12);
    const [hasMore, setHasMore] = useState(true);
    const [page, setPage] = useState(0);


    useEffect(() => {
        getProducts();
    },[page]);

    const fetchMore = () => {
        let curPage = page;
        if (curPage <= 2) {
            setPage(curPage+1);
        }
    }

    const getProducts = async() => {
        try {
          const res = await axios.get(`/products?limit=${limit}&page=${page}`);
          const newProducts = res.data.data;
          setProducts([...products, ...newProducts ]);
          setTempId(res.data.last_id);
          setHasMore(res.data.hasMore);
        } catch (error) {
          console.log(error);
        }
      }
    

  return (
    <>
        <InfiniteScroll
            dataLength={products.length}
            next={fetchMore}
            hasMore={hasMore}
            loader={<h4>Loading data</h4>}
        >
            <div className="ps-product-list">
                <div className="ps-container">
                    <div className="ps-section__header">
                        <h3>Our Products</h3> 
                    </div>
                    <div className="ps-section__content">
                        <div className='row'>
                            {
                                products.map((products, index)=> (
                                    <div key={index} className="col-xl-2 col-lg-4 col-md-4 col-sm-6 col-6">
                                    <CardsProducts data={products} />
                                    </div>
                                ))
                            }
                        </div>
                    </div>
                </div>
            </div>
            {/* <div style={{textAlign:"center"}}>
                <a  style={{ display:"inline-block"}} href="/products">More Products</a>
            </div>
 */}
        </InfiniteScroll>
    </>
  )
}
