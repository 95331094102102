import React, {useEffect}  from "react";
import Header from "../Components/Header/Header";
import HeaderMobile from "../Components/Header/HeaderMobile";
import Sidebar from "../Components/SideBar/Sidebar";
import ProductFooter from "../Components/Footer/ProductFooter";
import Loading from "../Components/Loading";
import Footer from "../Components/Footer/Footer";

const Page404 = () => {

  useEffect(() => {
    document.title = '404 - Not Found ';
  })

  return (
    <>
      <Header />
      <HeaderMobile />
      <Sidebar />

      <div className="ps-page--404">
        <div className="container">
            <div className="ps-section__content mt-40 mb-40">
                <img src="http://localhost:8000/themes/martfury/img/404.jpg" alt="404" />
                <h3>Ohh! Page not found</h3>
                <p>It seems we can't find what you're looking for. Perhaps searching can help or go back to
                    <a href="/"> Homepage</a>
                </p>
                {/* <form className="ps-form--widget-search" action="http://localhost:8000/products" method="get">
                    <input className="form-control" type="text" name="q" placeholder="Search...">
                    <button><i className="icon-magnifier"></i></button>
                </form> */}
            </div>
        </div>
    </div>

      <div className="ps-site-overlay"></div>
      <Footer />
    </>
  );
};

export default Page404;
