import React, { useState, useEffect } from 'react';
// import ShowVariants from '../../Components/Common/ShowVariants';
import "./CartPage.css";
import CartPageDetail from './CartPageDetail';
import { formatRupiah, persenDiscount, countCartQty, summaryCartAmount } from '../../Components/Helper';
import Checkbox from '../../Components/Checkbox';
import { useDispatch, useSelector } from 'react-redux';
import { listCartitems, updateProductCart, deleteProductCart, checkStateCartitem, checkStateCartShop, checkStateCartUser } from '../../config/actions/cartActions';
import { async } from '@firebase/util';

import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import { Oval, Audio, BallTriangle } from 'react-loader-spinner'
import ListAddressModal from '../CustomerPage/ListAddressModal';

const style = { position: "fixed", top: "50%", left: "50%", transform: "translate(-50%, -50%)" };
// const style = { position: "relative", left: "50%", top: "50%", transform: "translate(-50%, -50%)" };


const CartPage = () => {

    const dispatch = useDispatch();
    const cartItemsList = useSelector((state) => state.cartItemsList);
    const { loading, error, cartItems } = cartItemsList;
    const [allSelected, setAllSelected] = useState(0);

    const [currentAddress, setCurrentAddress] = useState(localStorage.getItem("currentAddress") || null);


    const [modalListAddress, setModalListAddress] = useState(false);

    const handleOpenListAddressModal = () => {
        setModalListAddress(true)
    }

    const handleCloseListAddressModal = () => {
        setModalListAddress(false)
    }




    // console.log("cartItems: ", cartItems);

    useEffect(() => {
        dispatch(listCartitems())
    }, [dispatch, allSelected])

    useEffect(() => {
        setAllSelected(checkStateCheckedItems())
    }, [cartItems])

    let items = cartItemsList.cartItems;
    let totalCartQty = countCartQty(items);
    let totalCartAmount = summaryCartAmount(items);
    let userCartSelected = 0;
    const [disable, setDisable] = useState(false);
    let userCardRecords = 0;



    const ViewCurrentAddress = () => {
        let currAddress = JSON.parse(currentAddress);
        // console.log(currAddress);
        if (currentAddress !== null) {
            return (
                <div>
                    <span style={{ fontSize: "12px" }}>{currAddress.name}{"("}{currAddress.phone}{")"}{", "}{currAddress.address}{" "}{currAddress.subdistrict_name} {currAddress.city_name} {currAddress.province_name}{" - "}{currAddress.zip_code}</span>
                    {/* <span style={{ bottom: "0", right: "0", position: "absolute" }}>ganti...</span> */}
                </div>
            )
        } else {
            return (
                <div>
                    <span>Alamat kirim belum ada</span>
                </div>
            )
        }


    }

    // console.log(items);


    // items.reduce((accumulator, currentValue) => accumulator + currentValue.available_funds, 0)

    // console.log("Cart Item List :", cartItemsList)

    // const cartSorting = [...items].sort((a, b) =>
    //     a.storeUrl > b.storeUrl ? -1 : 1,
    // );

    const groupBy = (key, arr) => arr
        .reduce(
            (cache, items) => {
                // if (items["checkStatus"] > 0) {
                //     userCartSelected = userCartSelected + items["checkStatus"]
                //     console.log("userCartSelected: ", userCartSelected)
                // }
                const property = items[key];
                if (property in cache) {
                    return { ...cache, [property]: cache[property].concat(items) }
                }
                return { ...cache, [property]: [items] }
            },
            {}
        );

    const cartGroupBy = groupBy('storeUrl', items);

    const RemoveCartItem = async (param) => {
        await dispatch(deleteProductCart(param))
        dispatch(listCartitems())
    }

    const PlusCartItem = async (itemcode, qty) => {
        let qtyItems = parseInt(qty) + 1;
        await dispatch(updateProductCart(itemcode, qtyItems))
        dispatch(listCartitems())
    }

    const MinusCartItem = async (itemcode, qty) => {
        if (parseInt(qty) > 1) {
            let qtyItems = parseInt(qty) - 1;
            await dispatch(updateProductCart(itemcode, qtyItems))
            dispatch(listCartitems())
        }
    }

    const ChangeItemQty = async (itemId, qty, lastQty) => {
        await dispatch(updateProductCart(itemId, parseInt(qty)))
        dispatch(listCartitems())
    }

    const CheckStateItem = async (typeId, itemId, checkState) => {
        if (typeId === 'CartItem') {
            await dispatch(checkStateCartitem(itemId, checkState));
        }
        if (typeId === 'CartStore') {
            await dispatch(checkStateCartShop(itemId, checkState));
        }
        if (typeId === 'CartUser') {
            console.log("CartUser");
            await dispatch(checkStateCartUser(checkState));
        }
        dispatch(listCartitems())
    }

    function checkStateCheckedItems() {
        let data = cartItemsList.cartItems
        let checkedsts = 0;
        let allSelect = 0;
        data.forEach(dt => {
            checkedsts = checkedsts + dt.checkStatus
        })
        if (checkedsts > 0) {
            if (checkedsts === data.length) {
                allSelect = 1
            }
        }
        return allSelect;
    }

    const PrintHeader = ({ data, storeCheckState }) => {
        let cartdata = data; //.data;

        // console.log("Data : ", data);
        // console.log("Check State : ", storeCheckState);

        // console.log(storeCheckState);
        // console.log(data);
        // console.log("STATUS CHECK : ", storeCheckState);
        // console.log("Print Header Data :", cartdata[0].storeUrl)
        return (
            <>
                <div className="cart-group cart-group">
                    <div className="box-group shadow">
                        <div className="box cart-group__header cart-group__header--no-error">
                            <div className="cart-group__header__wrapper">
                                <div className="merchant-section">
                                    <Checkbox
                                        typeCheck={'CartStore'}
                                        valueCheck={cartdata[0].storeUrl}
                                        onChangeCheckState={CheckStateItem}
                                        currentCheckState={parseInt(storeCheckState)}
                                    />
                                    <div className="cart-group__header__merchant-logo cart-group__header__merchant-logo--seller">
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            fill="currentColor"
                                            preserveAspectRatio="xMidYMid meet"
                                            width="24" height="24"
                                            viewBox="0 0 24 24">
                                            <path
                                                transform="translate(-392 -1987) translate(392 1987) translate(3 2)"
                                                d="M14.251 10.487c-.504.358-1.113.56-1.75.56s-1.246-.202-1.75-.56c-.504.358-1.113.56-1.75.56s-1.246-.202-1.75-.56c-.504.358-1.113.56-1.75.56s-1.246-.202-1.75-.56c-.495.352-1.091.55-1.716.557-.051 2.092.032 4.187.293 6.268.154 1.23 1.112 2.205 2.339 2.38 2.875.41 5.794.41 8.67 0 1.227-.175 2.185-1.15 2.339-2.38.26-2.081.344-4.176.293-6.268-.626-.007-1.222-.205-1.718-.557zM9.412 13c.633 0 1.195.459 1.395 1.14.259.883.259 1.838 0 2.72-.2.682-.762 1.14-1.395 1.14h-.821c-.633 0-1.195-.458-1.395-1.14-.26-.882-.26-1.837 0-2.72.2-.681.762-1.14 1.395-1.14h.821zM5.219.39c2.496-.52 5.067-.52 7.563 0 .78.164 1.467.644 1.908 1.335l2.807 4.397c.329.515.504 1.119.504 1.738v.015L18 8c0 1.105-.895 2-2 2-.759 0-1.411-.427-1.75-1.05-.339.622-.991 1.05-1.75 1.05s-1.411-.427-1.75-1.05C10.411 9.572 9.759 10 9 10s-1.411-.427-1.75-1.05C6.911 9.572 6.259 10 5.5 10s-1.411-.427-1.75-1.05C3.411 9.572 2.759 10 2 10c-1.105 0-2-.895-2-2v-.14c0-.62.176-1.223.504-1.738l2.807-4.397C3.753 1.034 4.439.554 5.219.39z"></path>
                                        </svg>
                                    </div>
                                    <div className="cart-group__header__merchant-info">
                                        <span className="ellipsis-oneline" style={{ fontSize: "14px", color: "black" }}>
                                            <a href={`${cartdata[0].storeUrl}`} >
                                                {cartdata[0].storeName}
                                            </a>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {
                            cartdata.map((item, index) => {
                                return (
                                    <CartPageDetail
                                        key={index}
                                        item={item}
                                        storeName={cartdata[0].storeName}
                                        storeUrl={cartdata[0].storeUrl}
                                        onItemDelete={RemoveCartItem}
                                        onMinusQty={MinusCartItem}
                                        onPlusQty={PlusCartItem}
                                        onChangeQty={ChangeItemQty}
                                        onCheckStateItem={CheckStateItem}
                                    />
                                )
                            })
                        }
                    </div>
                </div>
            </>
        )
    }

    return (
        <>
            {
                loading ?
                    <div className='cart'>
                        <div className='cart__filled'>
                            <div className='cart__container' style={{ marginBottom: "150px" }}>
                                <div style={style}  >
                                    <BallTriangle
                                        height={100}
                                        width={100}
                                        color="#00BFFF"
                                        ariaLabel="loading"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    // <p>Loading</p>
                    :
                    items.length === 0 ?
                        <div className='cart_empty'>
                            <img src={process.env.PUBLIC_URL + "/assets/img/empty_cart.png"} />
                            <p>Keranjang kamu masih kosong</p>
                        </div>
                        :
                        <div className='cart'>
                            <div className='cart__filled'>
                                <div className='cart__container'>
                                    {/* <p>{allSelected === false ? "false" : "true"}</p> */}
                                    <div className="cart__container--left blu-column b-8-desktop">
                                        <div className="cart__header box shadow">

                                            {/* <Checkbox typeCheck={'CartUser'} valueCheck={""} /> */}

                                            <Checkbox
                                                typeCheck={'CartUser'}
                                                valueCheck={allSelected}
                                                onChangeCheckState={CheckStateItem}
                                                currentCheckState={allSelected}
                                            />

                                            {" "}

                                            <label style={{ display: "inline-block", verticalAlign: "midlle" }}>
                                                Pilih semua produk
                                            </label>
                                        </div>
                                        <div className='group-cart-data'>
                                            {
                                                Object.values(cartGroupBy).map((cart, index) => {
                                                    let stateCheckStore = 0;
                                                    let checkStoreState = 0;
                                                    let nrecordStore = 0;
                                                    stateCheckStore = 0;
                                                    cart.forEach(cartHeader => {
                                                        nrecordStore = nrecordStore + 1
                                                        checkStoreState = checkStoreState + parseInt(cartHeader.checkStatus)
                                                        // userCartSelected = userCartSelected + parseInt(cartHeader.checkStatus)
                                                    });
                                                    if (checkStoreState > 0) {
                                                        if (checkStoreState === nrecordStore) {
                                                            stateCheckStore = 1;
                                                        }
                                                    }

                                                    return (
                                                        <PrintHeader key={index} data={cart} storeCheckState={stateCheckStore} />
                                                    )
                                                })
                                            }
                                        </div>
                                    </div>
                                    <div className="cart__container--right blu-column b-4-desktop">
                                        <ListAddressModal modalListAddress={modalListAddress} handleCloseListAddressModal={handleCloseListAddressModal} />

                                        <div className='sticky-group'>
                                            <div className="shipping-info cart__shipping shadow qualified">
                                                <img
                                                    src="https://www.static-src.com/frontend/retail/static/img/truck-check.f716107.svg"
                                                    alt=""
                                                    className="shipping-info__icon"
                                                />
                                                <div className="shipping-info__message"
                                                    onClick={() => {
                                                        setModalListAddress(true)
                                                    }}
                                                    style={{ cursor: "pointer" }}
                                                >
                                                    <ViewCurrentAddress />
                                                </div>
                                                <span className="tooltip shipping-info__tooltip tooltip--btm-left tooltip-shipping-rules">
                                                    <span className="tooltip__label">
                                                        <span className="shipping-info__info-icon">
                                                            <svg
                                                                xmlns="http://www.w3.org/2000/svg"
                                                                fill="currentColor"
                                                                preserveAspectRatio="xMidYMid meet"
                                                                width="24"
                                                                height="24"
                                                                viewBox="0 0 24 24"
                                                            >
                                                                <path
                                                                    transform="translate(-632 -665) translate(632 665)"
                                                                    d="M12 2C6.477 2 2 6.477 2 12s4.477 10 10 10 10-4.477 10-10c0-5.522-4.477-10-10-10zm0 3.5c.828 0 1.5.672 1.5 1.5s-.672 1.5-1.5 1.5-1.5-.672-1.5-1.5.672-1.5 1.5-1.5zm1.243 12.03c-.143.57-.655.97-1.243.97-.588 0-1.1-.4-1.243-.97-.497-1.989-.497-4.07 0-6.06.143-.57.655-.97 1.243-.97.588 0 1.1.4 1.243.97.497 1.99.497 4.071 0 6.06z">
                                                                </path>
                                                            </svg>
                                                        </span>
                                                    </span>
                                                </span>
                                            </div>
                                            <div className="cart__heading">
                                                <span className="cart__heading__icon">
                                                    <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        fill="currentColor"
                                                        preserveAspectRatio="xMidYMid meet"
                                                        width="24"
                                                        height="24"
                                                        viewBox="0 0 24 24">
                                                        <path
                                                            transform="translate(-698 -625) translate(698 625)"
                                                            d="M15.617 4.114c-3.87-.221-7.752-.12-11.608.308-1.514.168-2.733 1.322-2.983 2.825-.138.83.306 2.27.64 2.27 1.105 0 2 .897 2 2 0 1.106-.895 2-2 2-.334 0-.778 1.44-.64 2.272.25 1.503 1.469 2.656 2.983 2.825 3.856.428 7.739.529 11.608.308-.437-5.783-.437-9.025 0-14.808zm-5.952 9.403h-3c-.552 0-1-.448-1-1s.448-1 1-1h3c.552 0 1 .448 1 1 0 .553-.447 1-1 1zm6.949-9.321c.903.064 1.807.125 2.708.225 1.514.169 2.733 1.322 2.983 2.825l.014.118c.068.783-.337 2.153-.654 2.153-1.105 0-2 .896-2 2 0 1.105.895 2 2 2 .334 0 .778 1.44.64 2.27-.25 1.503-1.47 2.657-2.983 2.825-.901.1-1.804.16-2.708.225-.432-5.717-.432-8.925 0-14.641zm-4.95 3.32c.553 0 1 .449 1 1 0 .554-.446 1-1 1h-5c-.551 0-1-.447-1-1 0-.551.449-1 1-1h5z">
                                                        </path>
                                                    </svg>
                                                </span>
                                                Voucher &amp; promo
                                            </div>
                                            <div className="cart__promo-box">
                                                <div className="promo-box-container">
                                                    <div className="promo-box promo-box--has-applied">
                                                        <span className="promo-box__icon">
                                                            <svg
                                                                xmlns="http://www.w3.org/2000/svg"
                                                                fill="currentColor"
                                                                preserveAspectRatio="xMidYMid meet"
                                                                width="24"
                                                                height="24"
                                                                viewBox="0 0 24 24">
                                                                <path
                                                                    transform="translate(-698 -625) translate(698 625)"
                                                                    d="M15.617 4.114c-3.87-.221-7.752-.12-11.608.308-1.514.168-2.733 1.322-2.983 2.825-.138.83.306 2.27.64 2.27 1.105 0 2 .897 2 2 0 1.106-.895 2-2 2-.334 0-.778 1.44-.64 2.272.25 1.503 1.469 2.656 2.983 2.825 3.856.428 7.739.529 11.608.308-.437-5.783-.437-9.025 0-14.808zm-5.952 9.403h-3c-.552 0-1-.448-1-1s.448-1 1-1h3c.552 0 1 .448 1 1 0 .553-.447 1-1 1zm6.949-9.321c.903.064 1.807.125 2.708.225 1.514.169 2.733 1.322 2.983 2.825l.014.118c.068.783-.337 2.153-.654 2.153-1.105 0-2 .896-2 2 0 1.105.895 2 2 2 .334 0 .778 1.44.64 2.27-.25 1.503-1.47 2.657-2.983 2.825-.901.1-1.804.16-2.708.225-.432-5.717-.432-8.925 0-14.641zm-4.95 3.32c.553 0 1 .449 1 1 0 .554-.446 1-1 1h-5c-.551 0-1-.447-1-1 0-.551.449-1 1-1h5z">
                                                                </path>
                                                            </svg>
                                                        </span>
                                                        <div className="promo-box__content">
                                                            <div className="promo-box__content__title">
                                                                1 voucher/kode promo terpakai
                                                            </div>
                                                            <div className="promo-box__content__subtitle tx-gray-light"></div>
                                                        </div>
                                                        <span className="promo-box__arrow">
                                                            <svg
                                                                xmlns="http://www.w3.org/2000/svg"
                                                                fill="currentColor"
                                                                preserveAspectRatio="xMidYMid meet"
                                                                width="24"
                                                                height="24"
                                                                viewBox="0 0 24 24">
                                                                <path
                                                                    transform="translate(-618 -785) translate(618 785) translate(9 7)"
                                                                    d="M7 5.154c0-.636-.292-1.219-.8-1.6L1.59.192C1.142-.133.516-.036.191.412c-.325.445-.227 1.071.22 1.396l4.6 3.354L.447 8.166c-.46.305-.587.925-.282 1.386.305.46.926.586 1.386.282l4.6-3.046C6.71 6.372 7 5.79 7 5.154z">
                                                                </path>
                                                            </svg>
                                                        </span>
                                                    </div>
                                                </div>
                                                <div className="applied-section">
                                                    <span className="applied-section__icon">
                                                        <svg
                                                            data-v-d5b9e8de=""
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            fill="currentColor"
                                                            width="24"
                                                            height="24"
                                                            viewBox="0 0 24 24">
                                                            <path
                                                                d="M13.738 14.292a1 1 0 111.414 1.416 1 1 0 01-1.414-1.416zm-3.296-6.293a1 1 0 110 2 1 1 0 010-2zm12.444 4c0-1.067-1.241-1.925-1.554-2.888-.324-.998.165-2.42-.44-3.25-.611-.84-2.118-.811-2.957-1.421-.831-.605-1.266-2.05-2.264-2.374-.963-.313-2.161.59-3.228.59-1.067 0-2.265-.902-3.228-.59-.998.324-1.433 1.77-2.264 2.374-.839.61-2.346.582-2.957 1.42-.605.832-.116 2.253-.44 3.25C3.241 10.075 2 10.933 2 12c0 1.067 1.241 1.925 1.554 2.888.324.998-.165 2.42.44 3.25.611.839 2.118.81 2.957 1.42.831.606 1.266 2.05 2.264 2.375.963.313 2.161-.591 3.228-.591 1.067 0 2.265.903 3.228.59.998-.323 1.433-1.77 2.264-2.373.839-.611 2.346-.582 2.957-1.421.605-.831.116-2.252.44-3.25.313-.962 1.554-1.82 1.554-2.888zm-6.32 5.122a3.003 3.003 0 01-4.243 0 2.994 2.994 0 01-.794-2.792l-1.561 1.56a.997.997 0 01-1.414 0 .998.998 0 010-1.413l2.562-2.562a2.994 2.994 0 01-2.796-.792 3.003 3.003 0 010-4.243 3.003 3.003 0 014.243 0 2.991 2.991 0 01.793 2.796l1.562-1.562a.999.999 0 111.414 1.414l-2.559 2.559a2.993 2.993 0 012.792.794 2.999 2.999 0 01.001 4.24z">
                                                            </path>
                                                        </svg>
                                                    </span>
                                                    <span>Hore, kamu hemat
                                                        <span className="highlight">Rp80.650</span> untuk pesananmu!
                                                    </span>
                                                </div>
                                            </div>
                                            <div className="cart__heading has-icon">
                                                <span className="cart__heading__icon">
                                                    <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        fill="currentColor"
                                                        preserveAspectRatio="xMidYMid meet"
                                                        width="24"
                                                        height="24"
                                                        viewBox="0 0 24 24">
                                                        <path
                                                            transform="translate(-698 -745) translate(698 745)"
                                                            d="M19.594 3.77l-.158-1.36c0-.736-.829-1.104-1.314-.584-.301.323-.788.323-1.089 0l-.385-.413c-.513-.55-1.346-.55-1.859 0-.513.551-1.345.551-1.859 0-.513-.55-1.345-.55-1.859 0-.513.551-1.345.551-1.859 0-.513-.55-1.345-.55-1.859 0l-.385.413c-.301.323-.788.323-1.089 0-.485-.52-1.314-.152-1.314.584l-.158 1.36c-.512 4.395-.541 8.84-.085 13.243.142 1.375 1.145 2.466 2.425 2.637 3.488.468 7.017.468 10.505 0 1.28-.172 2.283-1.262 2.425-2.637.457-4.403.429-8.849-.083-13.244zM12 16H8c-.552 0-1-.448-1-1s.448-1 1-1h4c.552 0 1 .448 1 1s-.447 1-1 1zm4-4H8c-.552 0-1-.448-1-1s.448-1 1-1h8c.552 0 1 .448 1 1s-.447 1-1 1zm4.567 5.687c.82.24 1.433.991 1.433 1.904 0 1.022-.775 1.878-1.792 1.98l-.396.04c-5.195.52-10.429.52-15.624 0l-.396-.04C2.775 21.47 2 20.613 2 19.591c0-.913.613-1.664 1.433-1.904.384 1.563 1.622 2.745 3.181 2.954 1.78.24 3.592.36 5.386.36s3.606-.12 5.386-.36c1.559-.209 2.797-1.39 3.181-2.954zM16 6c.552 0 1 .448 1 1s-.447 1-1 1H8c-.552 0-1-.448-1-1s.448-1 1-1h8z">
                                                        </path>
                                                    </svg>
                                                </span>
                                                Detail pesanan
                                            </div>
                                            <div className="cart__summary box shadow">
                                                <div className="payment-detail cart__summary__detail">
                                                    <div className="payment-detail__total">
                                                        <div className="tx-black">
                                                            Total
                                                        </div>
                                                        <div className="payment-detail__total__value tx-primary">
                                                            {formatRupiah(totalCartAmount)}
                                                        </div>
                                                    </div>
                                                    <div className="payment-detail__total">
                                                        <div>
                                                            Cashback
                                                        </div>
                                                        <div className="tx-lime">
                                                            -Rp80.650
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="cart-footer">
                                                    <div className="cart-footer__summary">
                                                        <button
                                                            type="button"
                                                            disabled={totalCartQty > 0 ? false : true}
                                                            // className="blu-btn cart-footer__summary__button b-full-width b-primary"
                                                            className={`blu-btn b-full-width b-primary ${totalCartQty > 0 ? "" : " b-disabled "} `}
                                                            style={totalCartQty > 0 ? { cursor: "default" } : { cursor: "not-allowed" }}
                                                        >
                                                            <div className="blu-ripple">
                                                                Checkout {"("}{totalCartQty}{")"}
                                                            </div>
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
            }
        </>
    )
}

export default CartPage