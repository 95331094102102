import React, { useEffect, useState } from "react";
import ListAddressModal from "../../pages/CustomerPage/ListAddressModal";

function HeaderCartMobile({ product }) {

    const currentAddress = localStorage.getItem("currentAddress") || {}
    const [modalListAddress, setModalListAddress] = useState(false);

    let alamatKirim = "";

    if (currentAddress.length > 0) {
        let currAddress = JSON.parse(currentAddress);
        // console.log("Alamat : ", currAddress);
        alamatKirim = currAddress.address;

    } else {
        alamatKirim = 'alamat pengiriman belum ada'
    }

    const handleOpenListAddressModal = () => {
        setModalListAddress(true)
    }

    const handleCloseListAddressModal = () => {
        setModalListAddress(false)
    }

    // console.log(currentAddress.address)

    return (
        <>
            <header id="header_mobile" style={{ backgroundColor: "white", height: "80px", boxShadow: "0 0 24px -4px rgb(0 0 0 / 12%)", position: "fixed" }} className="header header--mobile header--sticky shadow" data-sticky="true">
                <div className="header__content" style={{ display: "flex", justifyContent: "space-between", alignItems: "center", padding: "0 16px 8px", marginTop: "10px" }}>
                    <a href="/" className="header__content__title router-link-active">
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="currentColor"
                            preserveAspectRatio="xMidYMid meet"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            className="icon">
                            <path
                                transform="translate(-378 -785) translate(378 785) translate(4 8)"
                                d="M5.913.486c.285.475.131 1.089-.343 1.372L3.681 2.992h10.375c.513 0 .935.386.993.883l.007.117c0 .552-.448 1-1 1H3.652l1.919 1.152c.474.283.627.898.343 1.372-.284.474-.898.627-1.372.343L.971 5.716C.363 5.35 0 4.71 0 4s.363-1.35.972-1.715L4.543.143c.472-.284 1.086-.131 1.37.343z">
                            </path>
                        </svg>
                        {"   "}Keranjang
                    </a>
                </div>
                <div className="header__content" style={{ display: "flex", justifyContent: "space-between", alignItems: "center", padding: "0 16px 8px" }}>
                    <div className="customer-location" style={{ display: "flex", flexDirection: "row", alignItems: "center", whiteSpace: "nowrap", width: "100%" }}>
                        <div className="customer-location__address" style={{
                            cursor: "pointer", flexDirection: "row", justifyContent: "flex-start",
                            alignItems: "center", fontSize: "12px", lineHeight: "1.33",
                            minHeight: "0", color: "rgba(0,0,0,.87)", marginRight: "4px"

                        }}
                            onClick={() => {
                                setModalListAddress(true)
                            }}
                        >
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="currentColor"
                                preserveAspectRatio="xMidYMid meet"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                style={{

                                    width: "20px",
                                    height: "20px",
                                    color: "858585",
                                }}>
                                <path
                                    d="M5 11.1818C5 7.11464 7.45455 3 12.3636 3C17.2727 3 19.7273 7.11545 19.7273 11.1818C19.7273 16.0909 14.8182 21 12.3636 21C9.90909 21 5 16.0909 5 11.1818ZM9.09091 10.3636C9.09091 12.171 10.5563 13.6364 12.3636 13.6364C14.1718 13.6364 15.6364 12.1718 15.6364 10.3636C15.6364 8.55627 14.171 7.09091 12.3636 7.09091C10.5563 7.09091 9.09091 8.55627 9.09091 10.3636Z">
                                </path>
                            </svg>
                            {"  "}Kirim Ke : {" "}

                            <label style={{ fontWeight: "500" }}>
                                {
                                    alamatKirim
                                }
                            </label>

                        </div>
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="currentColor"
                            preserveAspectRatio="xMidYMid meet"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            style={{

                                width: "20px",
                                height: "20px",
                                color: "858585",
                            }}
                            onClick={() => {
                                console.log("Click alamat")
                            }}
                        >

                            >
                            <path
                                transform="translate(-538 -785) translate(538 785) translate(7 9)"
                                d="M5.154 7c-.636 0-1.219-.292-1.6-.8L.192 1.59C-.133 1.142-.036.516.412.191c.445-.325 1.071-.227 1.396.22l3.354 4.6L8.166.447c.305-.46.925-.587 1.386-.282.46.305.586.926.282 1.386l-3.046 4.6C6.372 6.71 5.79 7 5.154 7z">
                            </path>
                        </svg>
                    </div>
                </div>
                {/* <div className="header__location" style={{ "padding": "0 16px 4px" }}>
                </div> */}
            </header>
            <ListAddressModal modalListAddress={modalListAddress} handleCloseListAddressModal={handleCloseListAddressModal} />

        </>
    );
}

export default HeaderCartMobile;
