import React, { useState, useEffect } from 'react'
import axios from "../../hooks/useAxios";
import BlockProducts from "./BlockProducts";
import {Swiper, SwiperSlide} from 'swiper/react';
import {FreeMode, Autoplay, Pagination, Navigation} from 'swiper'
import 'swiper/css';
import 'swiper/css/free-mode'
import "./BlockSwipers.css"

function BlockFeatured() {

    const [dataProduct, setDataProduct] = useState([]); 
    const getProductsFeatured = async() => {
        try {
            const res = await axios.get(`/featuredproducts?limit=12`);
            setDataProduct(res.data);
            // console.log(res.data);
        } catch (error) {

            console.log(error)
            
        }
    } 

    useEffect(()=> {
        getProductsFeatured()
    },[])

    return (
        <div>
            <div className="ps-product-list mt-40 mb-40">
                <div className="ps-container">
                    <div className="ps-section__header">
                        <h3>Featured products</h3> 
                        <ul className="ps-section__links">
                            <li><a href="https://martfury.botble.com/products">View All</a></li>
                        </ul>
                    </div> 
                    <div className="ps-section__content">



                        {
                            <Swiper
                                freeMode={true}
                                grabCursor={true}
                                navigation={true}
                                modules={[Autoplay, Pagination, Navigation]}
                                slidesPerView={7}
                                breakpoints={{
                                    0: {
                                        slidesPerView: 2,
                                        spaceBetween: 10
                                    },
                                    480: {
                                        slidesPerView: 2,
                                        spaceBetween: 10
                                    },
                                    768: {
                                        slidesPerView: 3,
                                        spaceBetween: 15
                                    },
                                    1280: {
                                        slidesPerView: 7,
                                        spaceBetween: 15
                    
                                    }
                                }}
                    
                            >

                            {
                                dataProduct.map((qqq) => {
                                    return (
                                        <SwiperSlide key={qqq.id} style={{width: "205.714px", marginRight: "30px"}}>
                                            <BlockProducts dataproduct={qqq} />
                                        </SwiperSlide>
                                    )
                                })
                            }

                            </Swiper>     
                        }
                        {/* <div className="swiper-button-prev"></div>
                        <div className="swiper-button-next"></div>                    */}


                    </div>
                </div>
            </div>
        </div>

    )



}

export default BlockFeatured