import React from "react";
import PropTypes from "prop-types";
import CartMini from "../Common/CartMini";
import CartMiniMobile from "../Common/CartMiniMobile";
import CategoriesMenuMobile from "../../pages/CategoriesMenuMobile";
import Account from "../../pages/CustomerPage/Account";
import SearchForm from "../SearchForm"

function Sidebar(props) {
  return (
    <>
      <div className="ps-site-overlay"></div>
      <div className="ps-panel--sidebar" id="cart-mobile">
        <div className="ps-panel__header">
          <h3>Shopping Cart</h3>
        </div>
        <div className="navigation__content">
          <CartMiniMobile />
        </div>
      </div>
      <div className="ps-panel--sidebar" id="navigation-mobile">
        <div className="ps-panel__header">
          <h3>Categoriesss</h3>
        </div>
        <CategoriesMenuMobile />
      </div>
      <div className="navigation--list">
        <div className="navigation__content">
          <a
            className="navigation__item"
            href="/"
          >
            <i className="icon-home"></i>
            <span> Beranda</span>
          </a>
          <a
            className="navigation__item ps-toggle--sidebar"
            href="#navigation-mobile"
          >
            <i className="icon-menu"></i>
            <span> Kategori</span>
          </a>
          <a
            className="navigation__item ps-toggle--sidebar"
            href="#search-sidebar"
          >
            <i className="icon-magnifier"></i>
            <span> Pencarian</span>
          </a>
          <a
            className="navigation__item ps-toggle--sidebar"
            href="#menus-account"
          >
            <i className="icon-user"></i>
            <span> Akun</span>
          </a>

          {/* <a
            className="navigation__item ps-toggle--sidebar"
            href="#cart-mobile"
          >
            <i className="icon-user"></i>
            <span> Akun</span>
          </a> */}
        </div>
      </div>
      <div className="ps-panel--sidebar" id='menus-account'>
        <div className="ps-panel__header">
          <h3>Akun Saya</h3>
        </div>
        <div className="navigation__content">
          <Account />
        </div>
      </div>
      <div className="ps-panel--sidebar" id="search-sidebar">
        <div className="ps-panel__header">
          <SearchForm />
        </div>
        <div className="navigation__content"></div>
      </div>
      <div className="ps-panel--sidebar" id="menu-mobile">
        <div className="ps-panel__header">
          <h3>Menu</h3>
        </div>
        <div className="ps-panel__content">
          <ul className="menu--mobile">
            <li className="menu-item-has-children">
              <a href="index">Home</a>
              <span className="sub-toggle"></span>
              <ul className="sub-menu">
                <li>
                  <a href="index.html">Marketplace Full Width</a>
                </li>
                <li>
                  <a href="home-autopart.html">Home Auto Parts</a>
                </li>
                <li>
                  <a href="home-technology.html">Home Technology</a>
                </li>
                <li>
                  <a href="home-organic.html">Home Organic</a>
                </li>
                <li>
                  <a href="home-marketplace.html">Home Marketplace V1</a>
                </li>
                <li>
                  <a href="home-marketplace-2.html">Home Marketplace V2</a>
                </li>
                <li>
                  <a href="home-marketplace-3.html">Home Marketplace V3</a>
                </li>
                <li>
                  <a href="home-marketplace-4.html">Home Marketplace V4</a>
                </li>
                <li>
                  <a href="home-electronic.html">Home Electronic</a>
                </li>
                <li>
                  <a href="home-furniture.html">Home Furniture</a>
                </li>
                <li>
                  <a href="home-kid.html">Home Kids</a>
                </li>
                <li>
                  <a href="homepage-photo-and-video.html">
                    Home photo and picture
                  </a>
                </li>
                <li>
                  <a href="home-medical.html">Home Medical</a>
                </li>
              </ul>
            </li>
            <li className="menu-item-has-children has-mega-menu">
              <a href="shop-default">Shop</a>
              <span className="sub-toggle"></span>
              <div className="mega-menu">
                <div className="mega-menu__column">
                  <h4>
                    Catalog Pages<span className="sub-toggle"></span>
                  </h4>
                  <ul className="mega-menu__list">
                    <li>
                      <a href="shop-default.html">Shop Default</a>
                    </li>
                    <li>
                      <a href="shop-default.html">Shop Fullwidth</a>
                    </li>
                    <li>
                      <a href="shop-categories.html">Shop Categories</a>
                    </li>
                    <li>
                      <a href="shop-sidebar.html">Shop Sidebar</a>
                    </li>
                    <li>
                      <a href="shop-sidebar-without-banner.html">
                        Shop Without Banner
                      </a>
                    </li>
                    <li>
                      <a href="shop-carousel.html">Shop Carousel</a>
                    </li>
                  </ul>
                </div>
                <div className="mega-menu__column">
                  <h4>
                    Product Layout<span className="sub-toggle"></span>
                  </h4>
                  <ul className="mega-menu__list">
                    <li>
                      <a href="product-default.html">Default</a>
                    </li>
                    <li>
                      <a href="product-extend.html">Extended</a>
                    </li>
                    <li>
                      <a href="product-full-content.html">Full Content</a>
                    </li>
                    <li>
                      <a href="product-box.html">Boxed</a>
                    </li>
                    <li>
                      <a href="product-sidebar.html">Sidebar</a>
                    </li>
                    <li>
                      <a href="product-default.html">Fullwidth</a>
                    </li>
                  </ul>
                </div>
                <div className="mega-menu__column">
                  <h4>
                    Product Types<span className="sub-toggle"></span>
                  </h4>
                  <ul className="mega-menu__list">
                    <li>
                      <a href="product-default.html">Simple</a>
                    </li>
                    <li>
                      <a href="product-default.html">Color Swatches</a>
                    </li>
                    <li>
                      <a href="product-image-swatches.html">Images Swatches</a>
                    </li>
                    <li>
                      <a href="product-countdown.html">Countdown</a>
                    </li>
                    <li>
                      <a href="product-multi-vendor.html">Multi-Vendor</a>
                    </li>
                    <li>
                      <a href="product-instagram.html">Instagram</a>
                    </li>
                    <li>
                      <a href="product-affiliate.html">Affiliate</a>
                    </li>
                    <li>
                      <a href="product-on-sale.html">On sale</a>
                    </li>
                    <li>
                      <a href="product-video.html">Video Featured</a>
                    </li>
                    <li>
                      <a href="product-groupped.html">Grouped</a>
                    </li>
                    <li>
                      <a href="product-out-stock.html">Out Of Stock</a>
                    </li>
                  </ul>
                </div>
                <div className="mega-menu__column">
                  <h4>
                    Woo Pages<span className="sub-toggle"></span>
                  </h4>
                  <ul className="mega-menu__list">
                    <li>
                      <a href="shopping-cart.html">Shopping Cart</a>
                    </li>
                    <li>
                      <a href="checkout.html">Checkout</a>
                    </li>
                    <li>
                      <a href="whishlist.html">Whishlist</a>
                    </li>
                    <li>
                      <a href="compare.html">Compare</a>
                    </li>
                    <li>
                      <a href="order-tracking.html">Order Tracking</a>
                    </li>
                    <li>
                      <a href="my-account.html">My Account</a>
                    </li>
                  </ul>
                </div>
              </div>
            </li>
            <li className="menu-item-has-children has-mega-menu">
              <a href="">Pages</a>
              <span className="sub-toggle"></span>
              <div className="mega-menu">
                <div className="mega-menu__column">
                  <h4>
                    Basic Page<span className="sub-toggle"></span>
                  </h4>
                  <ul className="mega-menu__list">
                    <li>
                      <a href="about-us.html">About Us</a>
                    </li>
                    <li>
                      <a href="contact-us.html">Contact</a>
                    </li>
                    <li>
                      <a href="faqs.html">Faqs</a>
                    </li>
                    <li>
                      <a href="comming-soon.html">Comming Soon</a>
                    </li>
                    <li>
                      <a href="404-page.html">404 Page</a>
                    </li>
                  </ul>
                </div>
                <div className="mega-menu__column">
                  <h4>
                    Vendor Pages<span className="sub-toggle"></span>
                  </h4>
                  <ul className="mega-menu__list">
                    <li>
                      <a href="become-a-vendor.html">Become a Vendor</a>
                    </li>
                    <li>
                      <a href="vendor-store.html">Vendor Store</a>
                    </li>
                    <li>
                      <a href="vendor-dashboard-free.html">
                        Vendor Dashboard Free
                      </a>
                    </li>
                    <li>
                      <a href="vendor-dashboard-pro.html">
                        Vendor Dashboard Pro
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </li>
            <li className="menu-item-has-children has-mega-menu">
              <a href="">Blogs</a>
              <span className="sub-toggle"></span>
              <div className="mega-menu">
                <div className="mega-menu__column">
                  <h4>
                    Blog Layout<span className="sub-toggle"></span>
                  </h4>
                  <ul className="mega-menu__list">
                    <li>
                      <a href="blog-grid.html">Grid</a>
                    </li>
                    <li>
                      <a href="blog-list.html">Listing</a>
                    </li>
                    <li>
                      <a href="blog-small-thumb.html">Small Thumb</a>
                    </li>
                    <li>
                      <a href="blog-left-sidebar.html">Left Sidebar</a>
                    </li>
                    <li>
                      <a href="blog-right-sidebar.html">Right Sidebar</a>
                    </li>
                  </ul>
                </div>
                <div className="mega-menu__column">
                  <h4>
                    Single Blog<span className="sub-toggle"></span>
                  </h4>
                  <ul className="mega-menu__list">
                    <li>
                      <a href="blog-detail.html">Single 1</a>
                    </li>
                    <li>
                      <a href="blog-detail-2.html">Single 2</a>
                    </li>
                    <li>
                      <a href="blog-detail-3.html">Single 3</a>
                    </li>
                    <li>
                      <a href="blog-detail-4.html">Single 4</a>
                    </li>
                  </ul>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </>
  );
}

Sidebar.propTypes = {};

export default Sidebar;
