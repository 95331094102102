import React from "react";
import "./address.css";

const CardAddress = (props) => {
    let alamat = props.data;
    let gmap;

    if (alamat.gmap != null) {
        gmap = JSON.parse(alamat.gmap);
    }

    const onUpdateData = () => {
        props.onChangeState("UPDATE", alamat);
    }

    const onDeleteData = () => {
        props.onChangeState("DELETE", alamat);
    }

    const onDefaultData = () => {
        props.onChangeState("DEFAULT", alamat);
    }

    const DeleteLink = ({ is_default }) => {
        return (
            <span onClick={onDeleteData} className={`footer__action ${is_default > 1 ? "disable" : ""}`}>{" "}Hapus{" "}</span>
        )
    }



    return (
        <div className="content__item">
            <div>
                <div className="blu-card">
                    <div className="blu-card__content address-card">
                        <div className="address-item">
                            <div className="address-item__header">
                                <div className="header__left">
                                    <span className="title">{alamat.label_address}</span>
                                    {
                                        alamat.is_default > 0 ? <div className="label header__primary b-secondary">{" "}Alamat Utama{" "}</div> : null
                                    }
                                </div>
                                <div className="header__right">
                                    <svg
                                        data-v-9f8e8692=""
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="#858585"
                                        preserveAspectRatio="xMidYMid meet"
                                        width="24"
                                        height="24"
                                        viewBox="0 0 24 24">
                                        <path
                                            fillRule="evenodd"
                                            clipRule={"evenodd"}
                                            d="M17.3179 15C15.9621 15 14.7895 15.704 14.1495 16.741C11.9737 15.893 9.90526 14.836 7.95579 13.58C8.21158 13.103 8.36948 12.572 8.36948 12C8.36948 11.47 8.23578 10.972 8.01368 10.522C9.94841 9.24503 12.0053 8.16803 14.1705 7.29803C14.8158 8.31403 15.9768 9 17.3158 9C19.3505 9 21 7.433 21 5.5C21 3.567 19.3505 2 17.3158 2C15.3421 2 13.7442 3.47903 13.6495 5.33203C11.1579 6.30703 8.80209 7.54303 6.59683 9.02203C6.03788 8.69703 5.38737 8.5 4.68421 8.5C2.64947 8.5 1 10.067 1 12C1 13.933 2.64947 15.5 4.68421 15.5C5.33789 15.5 5.94315 15.324 6.47578 15.041C8.72315 16.521 11.12 17.755 13.6547 18.717C13.7747 20.547 15.3589 22.001 17.3158 22.001C19.3505 22.001 21 20.434 21 18.501C21.0021 16.567 19.3526 15 17.3179 15Z">
                                        </path>
                                    </svg>
                                </div>
                            </div>
                            <div className="address-item__separator"></div>
                            <div className="address-item__content">
                                <div className="content__top-info">
                                    <span className="top-info__name">{alamat.name}</span>
                                    <span className="top-info__phone">{alamat.phone}</span>
                                </div>
                                <div className="content__complete-address">
                                    {alamat.address}
                                </div>
                                <div className="content__district">
                                    {alamat.village_name} {" - "}
                                    {alamat.subdistrict_name} {" - "}
                                    {alamat.city_name} {" - "}
                                    {alamat.province_name} {" - "}
                                    {alamat.zip_code}
                                </div>

                                <article className="blu-ticker content__geoLocationInfo">
                                    <span className="blu-ticker__bg"></span>
                                    <i className="blu-ticker__icon"></i>
                                    <div className="blu-ticker__msg">
                                        <div className="ticker__content">
                                            <div>
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    fill="#858585"
                                                    preserveAspectRatio="xMidYMid meet"
                                                    width="24"
                                                    height="24"
                                                    viewBox="0 0 24 24">
                                                    <path
                                                        d="M5 11.1818C5 7.11464 7.45455 3 12.3636 3C17.2727 3 19.7273 7.11545 19.7273 11.1818C19.7273 16.0909 14.8182 21 12.3636 21C9.90909 21 5 16.0909 5 11.1818ZM9.09091 10.3636C9.09091 12.171 10.5563 13.6364 12.3636 13.6364C14.1718 13.6364 15.6364 12.1718 15.6364 10.3636C15.6364 8.55627 14.171 7.09091 12.3636 7.09091C10.5563 7.09091 9.09091 8.55627 9.09091 10.3636Z">
                                                    </path>
                                                </svg>
                                            </div>
                                            {
                                                gmap !== null ? gmap.formated_address : "Atur titik lokasi biar lebih akurat dan bisa pakai metode pengiriman cepat."

                                            }
                                        </div>
                                    </div>
                                </article>

                            </div>
                            <div className="address-item__separator"></div>
                            <div className="address-item__footer">
                                <span className="footer__action" onClick={onUpdateData}>{" "}Ubah{" "}</span>
                                <DeleteLink is_default={alamat.is_default} />
                                {/* <span className="footer__action disabled">{" "}Hapus{" "}</span> */}
                                {
                                    alamat.is_default > 0 ? null :
                                        <span onClick={onDefaultData} className="footer__action">{" "}Jadikan Alamat Utama{" "}</span>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CardAddress;