import React from "react";

function WidgetPromo() {


    return (

        <div>
            <div className="ps-home-ads mt-40 mb-40">
                <div className="ps-container">
                    <div className="row">
                        <div className="col-lg-4">
                            <div className="ps-collection">
                                <div>
                                    <a href="https://martfury.botble.com/ads-click/IZ6WU8KUALYD" target="_blank">
                                        <img src="https://martfury.botble.com/storage/promotion/3.jpg" alt="Homepage middle 1" style={{ 'maxWidth': "100%"}} />
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div className="ps-collection">
                                <div>
                                    <a href="https://martfury.botble.com/ads-click/ILSFJVYFGCPZ" target="_blank">
                                        <img src="https://martfury.botble.com/storage/promotion/4.jpg" alt="Homepage middle 2" style={{ 'maxWidth': "100%"}} />
                                    </a>
                                </div>
                            </div>
                        </div> 
                        <div className="col-lg-4">
                            <div className="ps-collection">
                                <div>
                                    <a href="https://martfury.botble.com/ads-click/ZDOZUZZIU7FT" target="_blank">
                                        <img src="https://martfury.botble.com/storage/promotion/5.jpg" alt="Homepage middle 3" style={{ 'maxWidth': "100%"}} />
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>        
        
    )
}

export default WidgetPromo;