import { Fragment, useState } from "react";
import {
    Row,
    Col,
    Card,
    Badge,
    Alert,
    Modal,
    Label,
    Button,
    CardBody,
    Progress,
    CardTitle,
    ModalBody,
    CardHeader,
    ModalHeader,
} from "reactstrap";

import Swal from "sweetalert2";
import Select from "react-select";
import withReactContent from "sweetalert2-react-content"
import "./_modal.scss"

// import "@styles/react/libs/react-select/_react-select.scss"
// import "@styles/base/plugins/extensions/ext-component-sweet-alerts.scss"

const planOptions = [
    { value: "standard", label: "Standard - $99/month" },
    { value: "exclusive", label: "Exclusive - $249/month" },
    { value: "enterprise", label: "Enterprise - $499/month" },
    { value: "enterprise123", label: "Enterprise - $899/month" },
]

const MySwal = withReactContent(Swal)

const ModalOrders = () => {
    const [show, setShow] = useState(false)

    const handleConfirmCancel = () => {
        return MySwal.fire({
            title: "",
            text: "Are you sure you would like to cancel your subscription?",
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: "Yes",
            customClass: {
                confirmButton: "btn btn-primary",
                cancelButton: "btn btn-outline-danger ms-1",
            },
            buttonsStyling: false,
        }).then(function (result) {
            if (result.value) {
                MySwal.fire({
                    icon: "success",
                    title: "Unsubscribed!",
                    text: "Your subscription cancelled successfully.",
                    customClass: {
                        confirmButton: "btn btn-success",
                    },
                })
            } else if (result.dismiss === MySwal.DismissReason.cancel) {
                MySwal.fire({
                    title: "Cancelled",
                    text: "Unsubscription Cancelled!!",
                    icon: "error",
                    customClass: {
                        confirmButton: "btn btn-success",
                    },
                })
            }
        })
    }

    return (
        <Fragment>
            <Card>
                <CardHeader>
                    <CardTitle tag="h4">Current plan123</CardTitle>
                </CardHeader>
                <CardBody>
                    <Row>
                        <Col md="6">
                            <div className="mb-2 pb-50">
                                <h5>
                                    Your Current Plan is <strong>Basic</strong>
                                </h5>
                                <span>A simple start for everyone</span>
                            </div>
                            <div className="mb-2 pb-50">
                                <h5>Active until Dec 09, 2021</h5>
                                <span>
                                    We will send you a notification upon Subscription expiration
                                </span>
                            </div>
                            <div className="mb-2 mb-md-1">
                                <h5>
                                    $199 Per Month{" "}
                                    <Badge color="light-primary" className="ms-50">
                                        Popular
                                    </Badge>
                                </h5>
                                <span>Standard plan for small to medium businesses</span>
                            </div>
                        </Col>
                        <Col md="6">
                            <Alert color="warning" className="mb-2">
                                <h4 className="alert-heading">We need your attention!</h4>
                                <div className="alert-body">your plan requires update</div>
                            </Alert>
                            <div className="plan-statistics pt-1">
                                <div className="d-flex justify-content-between">
                                    <h5 className="fw-bolder">Days</h5>
                                    <h5 className="fw-bolder">4 of 30 Days</h5>
                                </div>
                                <Progress className="mb-50" value={85} />
                                <p className="mt-50">
                                    4 days remaining until your plan requires update
                                </p>
                            </div>
                        </Col>
                        <Col xs={12}>
                            <Button
                                color="primary"
                                className="me-1 mt-1"
                                onClick={() => setShow(true)}
                            >
                                Upgrade Plan
                            </Button>
                            <Button
                                outline
                                color="danger"
                                className="mt-1"
                                onClick={handleConfirmCancel}
                            >
                                Cancel Subscription
                            </Button>
                        </Col>
                    </Row>
                </CardBody>
            </Card>

            <Modal
                isOpen={show}
                toggle={() => setShow(!show)}
                className="modal-dialog-centered"
            >
                <ModalHeader
                    className="bg-transparent"
                    style={{ borderBottom: "0px" }}
                    toggle={() => setShow(!show)}
                ></ModalHeader>
                <ModalBody className="px-5 pb-2">
                    <div className="text-center mb-2">
                        <h1 className="mb-1">Upgrade Plan</h1>
                        <p>Choose the best plan for user1.</p>
                    </div>
                    <Row className="pt-50">
                        <Col sm={8}>
                            <Label className="form-label">Choose Plan</Label>
                            <Select
                                isClearable={false}
                                className="react-select"
                                classNamePrefix="select"
                                options={planOptions}
                                // theme={selectThemeColors}
                                defaultValue={planOptions[0]}
                            />
                        </Col>
                        <Col sm={4} className="text-sm-end mt-2">
                            <Button color="primary">Upgrade</Button>
                        </Col>
                    </Row>
                </ModalBody>
                <hr />
                <ModalBody className="px-5 pb-3">
                    <h6>User current plan is standard plan</h6>
                    <div className="d-flex justify-content-between align-items-center flex-wrap">
                        <div className="d-flex justify-content-center me-1 mb-1">
                            <sup className="h5 pricing-currency pt-1 text-primary">$</sup>
                            <h1 className="fw-bolder display-4 mb-0 text-primary me-25">
                                99
                            </h1>
                            <sub className="pricing-duration font-small-4 mt-auto mb-2">
                                /month
                            </sub>
                        </div>
                        <Button
                            outline
                            color="danger"
                            className="mb-1"
                            onClick={handleConfirmCancel}
                        >
                            Cancel Subscription
                        </Button>
                    </div>
                </ModalBody>
            </Modal>
        </Fragment>
    )

}

export default ModalOrders;
