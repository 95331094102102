import React from 'react';
import ShowVariants from './ShowVariants';
import { useDispatch, useSelector } from 'react-redux';
import { deleteProductCart, listCartitems } from '../../config/actions/cartActions';
import { formatRupiah } from '../Helper';

const CartDetail = (item) => {

    let dtCart = item.item;

    const dispatch = useDispatch();

    const removeCartItem = async (param) => {
        // console.log(param);
        await dispatch(deleteProductCart(param))
        dispatch(listCartitems())

    }

    const ShowPrice = ({ qty, saleprice, price }) => {
        if (saleprice > 0) {
            return (
                <small>{qty} x {formatRupiah(saleprice)} {" "}<del>{price}</del></small>
            )
        } else {
            return (
                <small>{qty} x {formatRupiah(price)}</small>
            )
        }
    }

    return (
        <>
            <div className="ps-product--cart-mobile" key={dtCart.dtCartcode}>
                <div className="ps-product__thumbnail">
                    <a href={`/${dtCart.storeUrl}/${dtCart.url}`}>
                        <img
                            src={`https://gracetoko.com/adminsite/uploads/products/${dtCart.image}`}
                            onError={(e) => { e.target.onerror = null; e.target.src = process.env.PUBLIC_URL + "/assets/img/placeholder.png" }}
                            alt={dtCart.title}
                        />
                    </a>
                </div>
                <div className="ps-product__content" >
                    <a className="ps-product__remove" href="#" onClick={
                        (e) => {
                            e.preventDefault();
                            removeCartItem(dtCart.itemcode)
                        }
                    }>
                        <i className="icon-cross"></i>
                    </a>
                    <a href={`/${dtCart.storeUrl}/${dtCart.url}`}>
                        {dtCart.title}
                    </a>
                    {
                        parseInt(dtCart.variants) > 0
                            ? <p style={{ fontSize: "12px", marginBottom: "1px" }}>{"( "}<ShowVariants id={dtCart.itemcode} />{")"}</p>
                            : ""
                    }
                    <p>
                        <strong>Sold by:</strong> {" "}
                        <a href={`/${dtCart.storeUrl}`}>
                            {dtCart.storeName}
                        </a>
                    </p>
                    <ShowPrice qty={dtCart.qtyInCart} price={dtCart.price} saleprice={dtCart.saleprice} />
                </div>
            </div>

        </>
    )
}

export default CartDetail