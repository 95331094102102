import React, {useEffect, useState} from 'react'
import axios from '../../hooks/useAxios'

export const SliderDealOfDay = ({recorddata}) => {

    const [dataProduct, setDataProduct] = useState([]);

    const getProduct = async() => {
        try {
            const res = await axios.get(`/dataproduct/${recorddata}`);
            setDataProduct(res.data);
        } catch (error) {
            console.log(error)        
        }
    } 
    
    useEffect(()=>{
        getProduct();
    },[])

    const BadgeView = ({sale_price, price}) => {


        let discount = 0;

        if (sale_price != null || sale_price > 0) {
            discount = ((sale_price / price) * 100) - 100; 
        }

        if (sale_price != null || sale_price > 0) {
            return (
                <div className="ps-product__badge">{discount}%</div>
            )
        } 
    }


    const PriceView = ({sale_price, price}) => {

        if (sale_price != null || sale_price > 0) {
            return (
                <p className="ps-product__price  sale ">Rp. {sale_price}  <del>{price} </del> </p>
            )
        } else {
            return (
                <p className="ps-product__price  sale ">Rp. {price} </p>
            )
        }
    }

    const RatingsView = ({ratings}) => {
        let persenStars = 0;
        let stars = ratings.stars;
        let records = ratings.records
        if (stars !== null) {
            persenStars = ((parseInt(stars) / parseInt(records)) * 100 / 5)
        }
        return (
            <div className="rating_wrap">
                <div className="rating">
                    <div className="product_rate" style={{ "width": `${persenStars}%` }}></div>
                </div>
                <span className="rating_num">({records})</span>
            </div>
        )
    } 

  return (

    dataProduct.length > 0 &&
    <>
        <div className="ps-product ps-product--inner">
            <div className="ps-product__thumbnail">
                <a href={`/${dataProduct[0].mp_stores.key}/${dataProduct[0].slugs_key}`}>
                    <img src={`https://gracetoko.com/storage/products/${dataProduct[0].images[0]}`}
                        onError={(e)=>{e.target.onerror = null; e.target.src=process.env.PUBLIC_URL+"/assets/img/placeholder.png"}}
                        alt={dataProduct[0].name}
                    />
                </a>
                <BadgeView sale_price={dataProduct[0].sale_price} price={dataProduct[0].price} />
                <ul className="ps-product__actions">
                    {/* <li><a className="add-to-cart-button" data-id="1" href="#" data-url="https://martfury.botble.com/cart/add-to-cart" title="Add To Cart"><i className="icon-bag2"></i></a></li> */}
                    <li><a href="#" data-url="https://martfury.botble.com/ajax/quick-view/1" title="Quick View" className="js-quick-view-button"><i className="icon-eye"></i></a></li>
                    <li><a className="js-add-to-wishlist-button" href="#" data-url="https://martfury.botble.com/wishlist/1" title="Add to Wishlist"><i className="icon-heart"></i></a></li>
                    {/* <li><a className="js-add-to-compare-button" href="#" data-url="https://martfury.botble.com/compare/1" title="Compare"><i className="icon-chart-bars"></i></a></li> */}
                </ul>
            </div>
            <div className="ps-product__container">
                    <PriceView sale_price={dataProduct[0].sale_price} price={dataProduct[0].price} />
                    <div className="ps-product__content">
                        <p className="ps-product__vendor">
                            <span>Sold by : </span>
                            <a href={`/${dataProduct[0].mp_stores.key}`} className="text-uppercase">{dataProduct[0].mp_stores.name}</a>
                        </p>
                        <a className="ps-product__title" href={`/${dataProduct[0].mp_stores.key}/${dataProduct[0].slugs_key}`}>{dataProduct[0].name}</a>
                        <RatingsView ratings={dataProduct[0].reviews} />
                        <div className="ps-product__progress-bar ps-progress" data-value="25">
                        {/* <div className="ps-progress__value"><span style={{width: "25%"}}></span></div>
                        <p>Sold: 2</p> */}
                    </div>
                </div>
            </div>
        </div>
    </>

    
  )
}
