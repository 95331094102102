// import React, { useState } from 'react'
// import Checkbox from '../Checkbox'
import { formatRupiah, countCartQty, summaryCartAmount } from '../Helper';
import { useSelector, useDispatch } from 'react-redux';
// import { listCartitems, checkStateCartUser } from '../../config/actions/cartActions';

export const CartSidebar = () => {

    const dispatch = useDispatch();
    const cartItemsList = useSelector((state) => state.cartItemsList);
    // const [isSelectedAll, setIsSelectedAll] = useState(false);
    const { loading, error, cartItems } = cartItemsList;

    // useEffect(() => {
    //     setIsSelectedAll(checkStateCheckedItems)
    //     console.log(isSelectedAll)
    // }, [cartItemsList])

    // function checkStateCheckedItems() {
    //     let data = cartItemsList.cartItems
    //     let checkedsts = 0;
    //     let allSelect = 0;
    //     data.forEach(dt => {
    //         checkedsts = checkedsts + dt.checkStatus
    //     })
    //     if (checkedsts > 0) {
    //         if (checkedsts === data.length) {
    //             allSelect = 1
    //         }
    //     }
    //     return allSelect === 1 ? true : false;
    // }


    let items = cartItemsList.cartItems;
    let totalItems = countCartQty(items);
    let totalAmount = summaryCartAmount(items);


    // const CheckStateItem = async (checkState) => {
    //     await dispatch(checkStateCartUser(checkState))
    //     dispatch(listCartitems())
    // }

    // const [disable, setDisable] = useState(false);

    return (
        <>
            {/* <div className="ps-site-overlay"></div> */}
            <div className="navigation--list" style={{
                borderRadius: "12px 12px 0 0",
                backgroundColor: "#fff",
                boxShadow: "0 0 24px -4px rgb(0 0 0 / 12%)",
                padding: "10px 15px"
            }}
            >
                <div className="navigation__content" style={{ height: "50px" }} >
                    {/* <div className='navigation__item ' style={{ width: "18%", textAlign: "left", color: "#858585", paddingRight: "8px", marginRight: "8px", borderRight: "0.1px solid #ececec" }}>
                        <Checkbox
                            typeCheck={'CartUser'}
                            valueCheck={isSelectedAll}
                            onChangeCheckState={CheckStateItem}
                            currentCheckState={isSelectedAll}
                        />
                        <span style={{ fontSize: "16px", fontWeight: "500" }}>
                            Semua
                        </span>
                    </div> */}
                    <div className='navigation__item ' style={{ width: "40%", textAlign: "left" }}>
                        <span style={{ fontSize: "16px", color: "black", lineHeight: "24px", fontWeight: "500" }}>
                            Total :
                        </span>
                        <label style={{ fontSize: "18px", lineHeight: "24px", color: "#f37021", fontWeight: "500" }}>
                            {formatRupiah(totalAmount)}
                        </label>
                    </div>
                    <div className='navigation__item ' style={{ width: "40%" }}>
                        <button
                            type="button"
                            // className="blu-btn cart-footer__summary__button b-full-width b-primary"
                            // disabled={disable}
                            // className={`blu-btn cart-footer__summary__button b-full-width b-primary" ${disable ? " disabled " : ""}`}
                            disabled={totalItems > 0 ? false : true}
                            // className="blu-btn cart-footer__summary__button b-full-width b-primary"
                            className={`blu-btn b-full-width b-primary ${totalItems > 0 ? "" : " b-disabled "} `}
                            style={totalItems > 0 ? { cursor: "default" } : { cursor: "not-allowed" }}
                        >
                            <div className="blu-ripple" >
                                Checkout {"("}{totalItems}{")"}
                            </div>
                        </button>
                    </div>
                </div>
            </div>
        </>
    )
}
