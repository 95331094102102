import axios from "./useAxios";

const useRefreshToken = () => {
    const refresh = async () => {
        const response = await axios.get("/token", {
            withCredentials: true
        });
        return response.data.accessToken;
    }
    return refresh;
}

export default useRefreshToken;