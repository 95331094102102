import React, { useEffect, useState } from 'react'
import axios from '../../hooks/useAxios'


const ShowVariants = (id) => {

    let idproduct = id;

    const [dataVariants, setDataVariants] = useState();

    const getDataVariants = async () => {
        try {
            const res = await axios.get(`/variantsproduct/${idproduct.id}`);
            setDataVariants(res.data);
            // console.log(res.data)
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        getDataVariants();
    }, [])

    return (
        <>
            {
                dataVariants &&
                dataVariants.map((data, index) => (
                    <small key={index} style={{ fontSize: "12px" }}>{data.v_ec_product_variation_items.title_attribute}{": "}{data.v_ec_product_variation_items.title}{" "}</small>
                ))


            }
        </>
    )
}

export default ShowVariants