import React, { useState } from "react";
import axios from "axios";
import { useNavigate, Redirect } from "react-router-dom";
import CustomerSideBar from "./SideBar";
import Header from "../../Components/Header/Header";
import HeaderMobile from "../../Components/Header/HeaderMobile";
import Breadcrumb from "../../Components/Common/Breadcrumb";
import Sidebar from "../../Components/SideBar/Sidebar";
import ProductFooter from "../../Components/Footer/ProductFooter";

import ModalOrders from "./ModalOrders";


const Orders = () => {

  const arrCumb = [
    {
      "name": "Orders",
      "link": "customer/orders"
    }
  ]


  return (
    <>
      <Header />
      <HeaderMobile />
      <Breadcrumb pagename={arrCumb} />
      <Sidebar />
      <section className="ps-section--account crop-avatar customer-page">
        <div className="container">
          <div className="row">
            <CustomerSideBar pageid="orders" />
            <div className="col-lg-8">
              <div className="ps-section__right">
                <div className="ps-section__header">
                  <h3>Orders</h3>
                </div>
                <div className="ps-section__content">
                  <div className="table-responsive">
                    <table className="table ps-table--wishlist">
                      <thead>
                        <tr>
                          <th>ID number</th>
                          <th>Date</th>
                          <th>Total</th>
                          <th>Status</th>
                          <th>Actions</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td colSpan="5" className="text-center">No orders!</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>

                  <div className="ps-pagination">

                  </div>
                  <ModalOrders />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

    </>
  )
}

export default Orders