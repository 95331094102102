import React, { useEffect, useState, useCallback } from 'react'
import axios from '../hooks/useAxios'

export const Brands = ({selectedBrands, onClickBrands}) => {
    const [brands, setBrands] = useState([]);
    const [checked, setChecked] = useState([]);
    const checkList = [];

    const getBrands = async () => {
      try {
        const response = await axios.get("/brands");
        setBrands(response.data)
      } catch (error) {
        console.log(error.response);
      }
    };

    const handleCheck = (event) => {
      var updatedList = [...checked];
      if (event.target.checked) {
        updatedList = [...checked, event.target.value];
      } else {
        updatedList.splice(checked.indexOf(event.target.value), 1);
      }
      onClickBrands(updatedList)
      setChecked(updatedList);
    };

    useEffect(()=> {
        getBrands();
    },[])
    
  return (
    <>
    {
        brands.map((br, id) => (
            <div className="ps-checkbox" key={br.id}>
                <input
                    type="checkbox"
                    name="brands[]"
                    id={br.id}
                    value={br.id}
                    className="form-control product-filter-item"
                    onChange={handleCheck}
                />
                <label htmlFor={br.id}>
                    <span>
                    {br.name} <span className="d-inline-block">(3)</span>
                    </span>
                </label>
            </div>
        ))
    }
    </>
  )  
}  
