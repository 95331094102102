import CartMini from "../Common/CartMini";
import React, { useEffect, useState } from "react";

function HeaderMobileCart({product}) {

  const [sticky, setSticky] = useState("header header--mobile header--mobile-product");
  const [productname, setProductName] = useState("");
  // let stickyClass = "header header--mobile header--mobile-product";

  useEffect(() => {
    window.addEventListener("scroll", isSticky);
    return () => {
      window.removeEventListener("scroll", isSticky);
    };
  }, []);


  const isSticky = () => {
    const scrollTop = window.scrollY;
    // console.log(scrollTop);
    const stickyClass = scrollTop >= 20 ? "header header--mobile header--mobile-product header--sticky" : "header header--mobile header--mobile-product";
    setSticky(stickyClass);
    if (scrollTop > 465) {
      setProductName(product[0]?.name)
    } else {
      setProductName("")
    }
  };

  return (
    <header id="header_mobile" className={sticky} data-sticky="true">
        <div className="navigation--mobile">
          <div className="navigation__left">
              {/* <a className="header__back" href="#"><i className="icon-chevron-left" style={{marginLeft:"-20px"}}></i><div style={{fontSize:"1.4rem",  marginLeft:"10px"}}>AAA</div></a> */}
              <a className="header__back" href="#"><i className="icon-chevron-left" style={{marginLeft:"-15px"}} ></i></a><div style={{fontSize:"1.4rem", marginLeft:"-10px", textAlign:"left", lineHeight:"15px", color:"wheat"}}>{productname}</div>
          </div>
          <div className="navigation__right">
            <div className="header__actions">
              <CartMini />

              {/* <div className="ps-cart--mini" >
                <a className="header__extra btn-shopping-cart" href="#">
                  <i style={{color:"white"}} className="icon-bag2"></i><span><i>0</i></span>
                </a>
                <div className="ps-cart--mobile">
                  <div className="ps-cart__content">
                    <div className="ps-cart__items ps-cart_no_items">
                      <span className="cart-empty-message">No products in the cart.</span>
                    </div>
                  </div>
                </div>
              </div> */}
              <div className="ps-block--user-header">
                <div className="ps-block__left"><a href="#"><i style={{color:"white"}} className="icon-user"></i></a></div>
              </div>
            </div>
          </div>
        </div>
    </header>


  );
}

export default HeaderMobileCart;
