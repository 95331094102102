import React, { useState, useEffect, useCallback } from 'react'
import axios from '../../hooks/useAxios';
import VariantDetail from './VariantDetail';

export const VariantsProduct = ({ errorMessage, parent_product, onDataChange }) => {

  const data_product = parent_product;
  let counterx = 0;

  // console.log("Error Message:" + errorMessage);

  // console.log(data_product);

  // const planOptions = [
  //   { value: "standard", label: "Standard - $99/month" },
  //   { value: "exclusive", label: "Exclusive - $249/month" },
  //   { value: "enterprise", label: "Enterprise - $499/month" },
  //   { value: "enterprise123", label: "Enterprise - $899/month" },
  // ];

  // const [dataVariants, setDataVariants] = useState(null);
  const [attributeSet, setAttributeSet] = useState(null);
  const [variant0, setVariant0] = useState(null);
  const [variant1, setVariant1] = useState(null);
  const [idVariant0, setIdVariant0] = useState(0);
  const [idVariant1, setIdVariant1] = useState(0);
  const [errorMessage0, setErrorMessage0] = useState('');
  const [errorMessage1, setErrorMessage1] = useState('');
  const [textVariant0, setTextVariant0] = useState(null);
  const [textVariant1, setTextVariant1] = useState(null);
  const [variantClicked, setVariantClicked] = useState(0);
  const [attributeClicked, setAttributeClicked] = useState("");

  const getAttributeSet = async () => {
    try {
      const res = await axios.get(`/productgroupvariants/${parent_product[0].id}`);
      setAttributeSet(res.data);
      // console.log(res.data)
    } catch (error) {
      console.log(error)
    }
  }

  // const iconClick = () => {
  //   console.log(labelOption);
  //   if (labelOption != null) {
  //     setLabelOption(null);
  //   }
  // }

  // const handleClickVariant = useCallback(data => {
  //   setIsActive(false)
  //   onDataChange(data);
  //   setLabelOption(data);
  // }, [onDataChange])

  useEffect(() => {
    // console.log("useEffect VariantsProduct");
    // console.log("Variant 0: ");
    // console.log(variant0);
    // console.log("Variant 1: ");
    // console.log(variant1);
    // console.log(idVariant1);
    onDataChange({ "varian1": variant0?.attribute_id || 0, "varian2": variant1?.attribute_id || 0 })
    getAttributeSet();
  }, [variant0, variant1])

  //   }, [variant0, variant1, variantClicked])

  function handleClickVariant(index, dataVar) {
    if (index === 0) {
      console.log(dataVar);
      setVariantClicked(dataVar.attribute_id);
      setAttributeClicked(dataVar.slug_attribute)
      setErrorMessage0("")
      setVariant0(dataVar);
      setIdVariant0(dataVar.attribute_id)
      setTextVariant0(dataVar.title)
      setIdVariant1(0)
      setVariant1(null)
      setTextVariant1(null)
      // let nilai = dataVar.attribute_id;
      // onDataChange({ "varian1": nilai, "varian2": 0 })
    } else {
      if (variant0 != null) {
        // setErrorMessage1("")
        // setVariant1(dataVar);
        // setIdVariant1(dataVar.attribute_id)
        // setTextVariant1(dataVar.title)
        // let nilai = dataVar.attribute_id;
        // onDataChange({ "varian1": idVariant0, "varian2": nilai })
      } else {
        setErrorMessage0("Silahkan pilih varian")
      }
    }
  }

  const groupBy = (array, key) => {
    return array.reduce((result, currentValue) => {
      (result[currentValue[key]] = result[currentValue[key]] || []).push(
        currentValue
      );
      return result;
    }, {});
  };

  const ShowDetailVariants = ({ id, nindex, dataparam, judul, classNameStock }) => {
    return (

      <li
        key={id}
        id={nindex}
        data-slug={dataparam.slug}
        data-id={dataparam.attribute_id}
        onClick={(e) => handleClickVariant(nindex, dataparam)}
        className={classNameStock}
      >
        <div>
          <label>
            {/* {dataparam.attribute_id} */}

            <input
              className="product-filter-item "
              type="radio"
              name={`attribute_${judul}`}
              value={dataparam.attribute_id}
              onChange={() => { }}
              checked={dataparam.attribute_id === (nindex == 0 ? idVariant0 : idVariant1)}
            />
            <span>{dataparam.title}</span>
          </label>
        </div>
      </li >


    )
  }

  const ViewDetailVariants = ({ id, dataparam, nindex, judul }) => {
    let classNameStock = " attribute-swatch-item ";
    if (variantClicked > 0) {
      if (attributeClicked != dataparam.slug_attribute) {
        let kodebarang = data_product[0].id;
        let attr_id = dataparam.attribute_id;
        // console.log("STEP# : 1 --- CLICKED")
        if (nindex > 0) {
          // console.log("NINDEX :" + nindex)
          return (
            <>
              <VariantDetail data={{ dataparam, id, attributeClicked, variantClicked, kodebarang, idVariant0, attr_id, idVariant1, nindex, variant1 }} onDataChange={setVariant1} />
            </>
          )
        } else {
          // console.log("JUDUL : " + judul)
          return (

            <ShowDetailVariants id={id} nindex={nindex} dataparam={dataparam} judul={judul} classNameStock={classNameStock} />
          )

        }
      } else {
        // console.log("STEP# : 2")
        if (nindex === 0) {

          return (
            <>
              <ShowDetailVariants id={id} nindex={nindex} dataparam={dataparam} judul={judul} classNameStock={classNameStock} />
            </>
          )
        }
      }
    } else {
      // console.log("STEP# : 3")

      return (
        <>
          <ShowDetailVariants id={id} nindex={nindex} dataparam={dataparam} judul={judul} classNameStock={classNameStock} />
        </>
      )
    }

  }

  const ViewAttributeVariants = ({ data }) => {

    const attVariant = groupBy(data, "title_attribute")
    var arr = [];
    Object.keys(attVariant).forEach(function (key) {
      arr.push(attVariant[key]);
    });
    // console.log(arr);
    return (
      <>
        {arr.map((datax, index) => (
          <div key={index}>
            <label className="attribute-name">{datax[0].title_attribute} : {" "}
              {
                index === 0 ?
                  textVariant0 != null ?
                    textVariant0 : datax.length + " " + "varian"
                  :
                  textVariant1 != null ?
                    textVariant1 : datax.length + " " + "varian"
              }
            </label>
            {
              index === 0 ?
                errorMessage0 && (
                  <p style={{ color: "red", position: "relative", marginTop: "-5px" }}>{errorMessage0}</p>
                ) :
                errorMessage1 && (
                  <p style={{ color: "red", position: "relative", marginTop: "-5px" }}>zz{errorMessage1}</p>
                  // <p className='error'>{errorMessage1}</p>
                )
            }

            {
              errorMessage && (
                <p style={{ color: "red", position: "relative", marginTop: "-5px" }}>{errorMessage}</p>
              )
            }

            <div id={index} className="text-swatches-wrapper attribute-swatches-wrapper attribute-swatches-wrapper form-group product__attribute product__color" data-type="text">
              <div className="attribute-values">
                <ul className="text-swatch attribute-swatch color-swatch">
                  {
                    datax.map((datay, i) => (
                      <ViewDetailVariants key={i} id={i} dataparam={datay} nindex={index} judul={datax[0].title_attribute} />
                    ))
                  }
                </ul>
              </div>
            </div>
          </div>
        ))
        }
      </>
    )

  }

  return (

    <>
      {
        attributeSet &&
        (
          <div className='pr_switch_wrap'>
            <ViewAttributeVariants data={attributeSet} />
          </div>
        )
      }
    </>



    //     {/* <Row className="pt-50">
    //       <Col sm={8}>
    //         <Label className="form-label">Choose Plan</Label>
    //         <Select
    //           isClearable={false}
    //           className="react-select"
    //           classNamePrefix="select"
    //           options={planOptions}
    //           placeholder={"Pilih Variant ..."}
    //           isClearable={true}
    //         // theme={selectThemeColors}
    //         // defaultValue={planOptions[0]}
    //         />
    //       </Col>
    //     </Row> */}
    // {
    //   // dataVariants &&
    //   // <div className='dropdown-variants'>
    //   //   <div className='dropdown-variants-btn' onClick={(e) => setIsActive(!isActive)}>
    //   //     {
    //   //       labelOption != null ? labelOption : "Pilih Varian"
    //   //     }
    //   //     <span>
    //   //       <i onClick={iconClick} className={labelOption != null ? "icon-cross" : "icon-chevron-down"} style={{ width: "35px", objectFit: "cover", height: "35px", color: "black" }}></i>
    //   //       {/* <i onClick={iconClick} className="icon-chevron-down" style={{width: "35px", objectFit:"cover", height: "35px", color: "black"}}></i> */}
    //   //     </span>
    //   //   </div>
    //   //   {
    //   //     isActive && (
    //   //       <div className='dropdown-variants-content'>

    //   //         {
    //   //           dataVariants.map((variant, index) => {
    //   //             return (
    //   //               <div onClick={(e) => handleClickVariant(variant.id)}
    //   //                 className='dropdown-variants-item' key={index} value={variant.id}
    //   //               >
    //   //                 {variant.id}
    //   //               </div>
    //   //             )
    //   //           })
    //   //         }
    //   //       </div>
    //   //     )
    //   //   }
    //   // </div>
    // }
  )
}
