export const REGISTER_SUCCESS = 'REGISTER_SUCCESS';
export const REGISTER_FAIL = 'REGISTER_FAIL';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILED = 'LOGIN_FAILED';
export const LOGOUT = 'LOGOUT';
export const REFRESH_TOKEN = "REFRESH_TOKEN";
export const SET_MESSAGE = 'SET_MESSAGE';
export const CLEAR_MESSAGE = 'CLEAR_MESSAGE';
export const CART_LIST_REQUEST = 'CART_LIST_REQUEST';
export const CART_LIST_SUCCESS = 'CART_LIST_SUCCESS';
export const CART_LIST_FAIL = 'CART_LIST_FAIL';
export const CART_ITEM_ADD_REQUEST = 'CART_ITEM_ADD_REQUEST';
export const CART_ITEM_ADD_SUCCESS = 'CART_ITEM_ADD_SUCCESS';
export const CART_ITEM_ADD_FAIL = 'CART_ITEM_ADD_FAIL';
export const CART_ITEM_REMOVE_REQUEST = 'CART_ITEM_REMOVE_REQUEST';
export const CART_ITEM_REMOVE_SUCCESS = 'CART_ITEM_REMOVE_SUCCESS';
export const CART_ITEM_REMOVE_FAIL = 'CART_ITEM_REMOVE_FAIL';
export const CART_ITEM_UPDATE_REQUEST = 'CART_ITEM_UPDATE_REQUEST';
export const CART_ITEM_UPDATE_SUCCESS = 'CART_ITEM_UPDATE_SUCCESS';
export const CART_ITEM_UPDATE_FAIL = 'CART_ITEM_UPDATE_FAIL';
export const CART_ITEM_CHECKBOX_REQUEST = 'CART_ITEM_CHECKBOX_REQUEST';
export const CART_ITEM_CHECKBOX_SUCCESS = 'CART_ITEM_CHECKBOX_SUCCESS';
export const CART_ITEM_CHECKBOX_FAIL = 'CART_ITEM_CHECKBOX_FAIL';
export const CART_SHOP_ITEM_CHECKBOX_REQUEST = 'CART_SHOP_ITEM_CHECKBOX_REQUEST';
export const CART_SHOP_ITEM_CHECKBOX_SUCCESS = 'CART_SHOP_ITEM_CHECKBOX_SUCCESS';
export const CART_SHOP_ITEM_CHECKBOX_FAIL = 'CART_SHOP_ITEM_CHECKBOX_FAIL';
export const CART_USER_ITEM_CHECKBOX_REQUEST = 'CART_USER_ITEM_CHECKBOX_REQUEST';
export const CART_USER_ITEM_CHECKBOX_SUCCESS = 'CART_USER_ITEM_CHECKBOX_SUCCESS';
export const CART_USER_ITEM_CHECKBOX_FAIL = 'CART_USER_ITEM_CHECKBOX_FAIL';

