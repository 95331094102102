import { useState, useMemo, useCallback, useRef, useEffect, Component } from "react";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import { GoogleMap, useLoadScript, Marker, StandaloneSearchBox } from "@react-google-maps/api";
import Geocode from "react-geocode";
import usePlacesAutocomplete, {
  getGeocode,
  getLatLng,
} from "use-places-autocomplete";
import {
  Combobox,
  ComboboxInput,
  ComboboxPopover,
  ComboboxList,
  ComboboxOption,
} from "@reach/combobox";
import "@reach/combobox/styles.css";
import "./Maps.css";
import PlaceComponent from "./PlaceComponent";



export default function Maps(props) {


  const { mapModal, onClose, formMap, setFormatedAlamat } = props;

  console.log(formMap.pathname);
  Geocode.setApiKey(process.env.React_App_GOOGLE_MAPS_API_KEY);
  // const datapos = props.pos;
  const [libraries] = useState(['places']);

  var ls = (formMap.pathname === '/customer/address' ? "CustomerAddressInput" : "currentlocation");

  const [lsMap, setLsMap] = useState(ls);

  const map_ls = JSON.parse(localStorage.getItem(ls));


  if (Object.keys(map_ls).length === 0) {
    ls = "currentlocation";
  }


  const currLoc = JSON.parse(localStorage.getItem(ls)) ?? {
    lat: parseFloat(process.env.React_App_LatCurrent),
    lng: parseFloat(process.env.React_App_LngCurrent),
  };
  // console.log(currLoc);
  const currPos = [{ lat: currLoc.lat, lng: currLoc.lng }];
  const [markers, setMarkers] = useState(null);
  const [selectLocation, setSelectLocation] = useState("Pilih Lokasi Map")
  const inputRef = useRef();

  const center = useMemo(
    () => ({ lat: currPos[0].lat, lng: currPos[0].lng }),
    []
  );
  const [selected, setSelected] = useState(center);
  const [namaalamat, setNamaalamat] = useState("");
  const [alamatdipilih, setAlamatdipilih] = useState("");
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: process.env.React_App_GOOGLE_MAPS_API_KEY,
    // libraries: ["places"],
    libraries,
    region: "ID",
  });

  const onMarkerDragEnd = async (event) => {
    let newLat = event.latLng.lat();
    let newLng = event.latLng.lng();
    await Geocode.fromLatLng(newLat, newLng).then(
      response => {
        // console.log(response.results[0].formatted_address);
        setNamaalamat(response.results[0].formatted_address)
        localStorage.setItem("datamap", JSON.stringify(response.results[0]));
      }
    )
  };

  const handleModal = () => {
    // const hasilpilih = JSON.parse(localStorage.getItem("datamap")) ?? "";
    const hasilpilih = JSON.parse(localStorage.getItem(lsMap)) ?? "";

    if (hasilpilih !== "") {
      setAlamatdipilih(hasilpilih.formatted_address);
      let kecamatan, kota, provinsi, kodepos;
      for (const component of hasilpilih.address_components) {
        const componentType = component.types[0];
        switch (componentType) {
          case "postal_code": {
            kodepos = component.short_name;
            break;
          }
          case "administrative_area_level_3": {
            kecamatan = component.long_name;
            break;
          }
          case "administrative_area_level_2": {
            kota = component.long_name;
            break;
          }
          case "administrative_area_level_1": {
            provinsi = component.long_name;
            break;
          }
        }
      }
      setSelectLocation(kecamatan + "/" + kota + "/" + provinsi + " " + kodepos);
      setFormatedAlamat(hasilpilih.formatted_address)
      onClose(true)
      // setModal(false);
    } else {
      console.log("Pilih dulu");
    }
  };

  const ResultMap = async (data, alamat, setNamaalamat) => {
    if (data !== undefined && data !== null) {
      const { lat, lng } = await getLatLng(data[0]);
      setNamaalamat(alamat);
      // localStorage.setItem("datamap", JSON.stringify(data[0]));
      localStorage.setItem(lsMap, JSON.stringify(data[0]));
    }
  };

  const onMarkerDrag = (even) => {
    console.log(even);
  };

  const PlacesAutocomplete = ({ setSelected, setNamaalamat }) => {
    const {
      ready,
      value,
      setValue,
      suggestions: { status, data },
      clearSuggestions,
    } = usePlacesAutocomplete();

    const handleSelect = async (address) => {
      setValue(address, true);
      clearSuggestions();
      const results = await getGeocode({ address });
      const { lat, lng } = await getLatLng(results[0]);
      setSelected({ lat, lng });
      ResultMap(results, address, setNamaalamat);
      setValue("");
    };

    // console.log("maps : ", data)

    return (
      <Combobox onSelect={handleSelect}>
        <ComboboxInput
          value={value}
          onChange={(e) => setValue(e.target.value)}
          disabled={!ready}
          className="combobox-input"
          placeholder="Cari alamat"
        />
        <ComboboxPopover style={{ zIndex: "1051" }}>
          <ComboboxList>
            {status === "OK" &&
              data.map(({ place_id, description }) => (
                <ComboboxOption key={place_id} value={description} />
              ))}
          </ComboboxList>
        </ComboboxPopover>
      </Combobox>
    );
  };

  useEffect(() => {
    Geocode.fromLatLng(currPos[0].lat, currPos[0].lng).then(
      response => {
        setNamaalamat(response.results[0].formatted_address)
      }
    )
  }, [])

  if (!isLoaded) return <div>Loading...</div>;

  return (
    <div>
      {/* <button
        className="ps-btn ps-btn--fullwidth"
        style={{ backgroundColor: "transparent", border: "1px solid #dddddd", fontSize: "14px", fontWeight: "400px", color: "black", height: "50px" }}
      // onClick={() => setModal(true)}
      >
        <i className="fa fa-home"></i> {selectLocation}

      </button> */}
      <p>{alamatdipilih}</p>
      <Modal
        style={
          {
            top: "120px",
            zIndex: "1050",
          }
        }

        size="lg"
        isOpen={props.mapModal}
        // toggle={() => setModal(!modal)}
        backdrop="static"
      // style={{ zIndex: -1000 }}
      >
        <ModalHeader style={{ borderBottom: "none" }} toggle={() => onClose(true)}>Pilih Map</ModalHeader>
        <ModalBody>
          <div>
            <div className="container-fluid w-auto p-3">
              <div className="row">
                <div className="col col-md-5" style={{ padding: "1px", marginLeft: "5px" }}>
                  <div>
                    <PlacesAutocomplete
                      setSelected={setSelected}
                      setNamaalamat={setNamaalamat}
                    />
                  </div>
                  <div className="row" style={{ marginTop: "20px" }}>
                    <div style={{ padding: "1px", marginLeft: "15px" }}>
                      <svg
                        data-v-f527b052=""
                        xmlns="http://www.w3.org/2000/svg"
                        fill="#858585"
                        preserveAspectRatio="xMidYMid meet"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                      >
                        <path d="M5 11.1818C5 7.11464 7.45455 3 12.3636 3C17.2727 3 19.7273 7.11545 19.7273 11.1818C19.7273 16.0909 14.8182 21 12.3636 21C9.90909 21 5 16.0909 5 11.1818ZM9.09091 10.3636C9.09091 12.171 10.5563 13.6364 12.3636 13.6364C14.1718 13.6364 15.6364 12.1718 15.6364 10.3636C15.6364 8.55627 14.171 7.09091 12.3636 7.09091C10.5563 7.09091 9.09091 8.55627 9.09091 10.3636Z"></path>
                      </svg>
                      <span>Lokasi terpilih</span>
                    </div>
                    <p style={{ fontSize: "14px", padding: "5px", marginLeft: "20px" }}>
                      {namaalamat}
                    </p>
                  </div>

                  <div
                    style={{
                      bottom: 0,
                      left: "50%",
                      position: "absolute",
                      padding: "10px",
                      transform: "translateX(-50%)",
                      paddingBottom: "5px",
                    }}
                  >
                    <button
                      className="btn btn-primary btn-block " style={{ fontSize: "15px", width: "300px" }}
                      onClick={() => {
                        handleModal();
                      }}
                    >
                      &nbsp; &nbsp; &nbsp; &nbsp; Pilih Lokasi ini &nbsp; &nbsp;
                      &nbsp; &nbsp;&nbsp;
                    </button>
                  </div>
                </div>
                <div className="col">
                  <GoogleMap
                    // onLoad={(map) => onMapLoad(map, setSelected)}
                    zoom={15}
                    center={selected}
                    className="map-container"
                    onDragEnd={(e) => {
                      console.log(e);
                    }}
                    mapContainerStyle={{ height: "54vh" }}
                  >
                    {/* <Marker position={selected} /> */}
                    {
                      <Marker
                        position={selected}
                        draggable={true}
                        onDragEnd={onMarkerDragEnd}
                      />
                    }
                  </GoogleMap>
                </div>
              </div>
            </div>
          </div>
        </ModalBody>
      </Modal>
    </div>
  );
}



