import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { listCartitems } from "../../config/actions/cartActions";
import ShowVariants from "./ShowVariants";
import CartDetail from "./CartDetail";

const CartMiniMobile = () => {
  const dispatch = useDispatch();
  const cartItemsList = useSelector(state => state.cartItemsList);
  const { loading, error, cartItems } = cartItemsList;

  const [totalQty, setTotalQty] = useState(0);
  const [totalCart, setTotalCart] = useState(0);


  useEffect(() => {
    TotalCartData();
  }, [cartItems])

  function TotalCartData() {
    if (cartItems.length > 0) {
      let totalQty = 0;
      let totalCart = 0;
      cartItems.map((item) => {
        totalQty = totalQty + parseInt(item.qtyInCart);
        if (parseInt(item.saleprice) > 0) {
          totalCart = totalCart + parseInt(item.qtyInCart) * parseInt(item.saleprice);
        } else {
          totalCart = totalCart + parseInt(item.qtyInCart) * parseInt(item.price);
        }
      })
      setTotalQty(totalQty);
      setTotalCart(totalCart);
    }
  }

  const formatRupiah = (money) => {
    return new Intl.NumberFormat('id-ID',
      { style: 'currency', currency: 'IDR', minimumFractionDigits: 0 }
    ).format(money);
  }



  return (
    <>
      {
        loading ? (
          <div> loading...</div >
        ) : error ? (
          <div>{error}</div>
        ) : (
          <div className="ps-cart--mobile">
            <div className="ps-cart__content" style={{ height: "100px", overflow: "scroll" }} >
              {
                cartItems.length > 0 ?
                  cartItems.map((item, index) => (
                    <CartDetail key={index} item={item} />
                  )) : null
              }
            </div>
            <div className="ps-cart__footer">
              <h3>
                Sub Total:<strong>{formatRupiah(totalCart)}</strong>
              </h3>
              <figure>
                <a className="ps-btn" href="/cart">
                  View Cart
                </a>
                <a className="ps-btn" href="#">
                  Checkout
                </a>
              </figure>
            </div>
          </div>

        )
      }
    </>
  );
};

export default CartMiniMobile;
