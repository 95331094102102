import axios from "axios";
import React, { Component } from "react";
import axos from "../hooks/useAxios";

export type LabelProps = { label?: string };

class SearchForm extends React.Component<LabelProps> {


    constructor(props) {

        super(props);

        this.state = {
            query: '',
            results: {},
            loading: false,
            message: ""
        };

        this.cancel = '';
    }

    handleSubmit = (e) => e.preventDefault();

    handleOnInputChange = (event) => {
        const query = event.target.value;
        console.log(query);
        if (!query) {
            this.setState({ query, results: {}, message: '' })
        } else {
            this.setState({ query: query, loading: true, message: '' }, () => {
                this.fetchSearchResults(query);
            })
            this.fetchSearchResults();
        }

    }

    fetchSearchResults = (query) => {
        if (this.cancel) {
            this.cancel.cancel();
        }
        this.cancel = axios.CancelToken.source();
        const searchUrl = process.env.React_App_Gracetoko_Api + `/products?namestr=${query}&limit=4`;
        axios.get(searchUrl, {
            cancelToken: this.cancel.token
        })
            .then(res => {
                // console.log(res.data.data);
                const resultNotFoundMsg = !res.data.data.length
                    ? 'There are no more data results'
                    : '';
                this.setState({
                    results: res.data.data,
                    message: resultNotFoundMsg,
                    loading: false
                })
            })
            .catch(error => {
                if (axios.isCancel(error) || error) {
                    this.setState({
                        loading: false,
                        message: 'Failed to load data please check connection'
                    })
                }
            });
    }

    ratingReviews = (records, stars) => {
        let persenStars = 0;
        persenStars = ((parseInt(stars) / parseInt(records)) * 100 / 5);
        return (
            <>
                <div className="rating_wrap">
                    <div className="rating">
                        <div className="product_rate" style={{ "width": `${persenStars}%` }} ></div>
                    </div>
                    <span className="rating_num">({records})</span>
                </div>
            </>
        )
    }

    priceViews = (sale_price, price) => {
        return (
            sale_price > 0 ?
                <p className="ps-product__price sale ">Rp. {sale_price.toLocaleString()} <del>{price}</del> </p> :
                <p className="ps-product__price ">Rp. {price.toLocaleString()} </p>
        )
    }

    renderSearchResults = () => {
        const { results } = this.state;

        // console.log(results.length);

        if (Object.keys(results).length && results.length) {

            if (results.length > 0) {
                return (
                    <>
                        <div className="ps-panel--search-result active" style={{ width: "98%" }}>
                            <div className="ps-panel__content">
                                {
                                    results.map((data, index) => {
                                        return (
                                            <>
                                                <div className="ps-product ps-product--wide ps-product--search-result" key={index}>
                                                    <div className="ps-product__thumbnail" >

                                                        <a href={`/${data.mp_stores.key}/${data.slugs_key}`}>
                                                            <img src={`https://gracetoko.com/storage/products/${data.images[0]}`}
                                                                onError={(e) => { e.target.onerror = null; e.target.src = `https://gracetoko.com/storage/products/8-300x300.jpg` }}
                                                                alt={data.name}
                                                            />
                                                        </a>

                                                    </div>
                                                    <div className="ps-product__content" >
                                                        <a className="ps-product__title" href={`/${data.mp_stores.key}/${data.slugs_key}`} >
                                                            {data.name}
                                                        </a>

                                                        {this.ratingReviews(data.reviews.records, data.reviews.stars)}

                                                        {this.priceViews(data.sale_price, data.price)}


                                                        {/* <p className="ps-product__price ">{data.price} </p> */}
                                                    </div>
                                                </div>
                                            </>
                                        )
                                    })
                                }
                            </div>
                            <div className="ps-panel__footer text-center">
                                <a href={`/products?q=${this.state.query}`}>See all results</a>
                            </div>
                        </div>
                    </>
                )
            } else {
                return (
                    <div className="ps-panel--search-result active" style={{ width: "98%" }}>
                        <div className="ps-panel__content">
                            <div className="ps-product ps-product--wide ps-product--search-result" >
                                <p>barang tidak ditemukan</p>
                            </div>
                        </div>
                        <div className="ps-panel__footer text-center">
                            <a href="http://localhost:8000/products?q=sh">See all results</a>
                        </div>
                    </div>

                )
            }

        } else {
            if (results.length === 0) {

                return (
                    <div className="ps-panel--search-result active" style={{ width: "98%" }}>
                        <div className="ps-panel__footer text-center">
                            <a href="http://localhost:8000/products?q=sh">Maaf, barang tidak ditemukan..</a>
                        </div>
                    </div>

                )
            }

        }
    }

    render() {
        const { query } = this.state;
        const { label = 'typeBrowser' } = this.props;
        if (this.props.typeBrowser === 'web') {
            return (
                <>
                    <form className="ps-form--quick-search" onSubmit={this.handleSubmit}>
                        <input className="form-control" style={{
                            width: "100%",
                            padding: "10px 35px 10px 15px",
                            border: "none",
                            borderRadius: "5px",
                            outline: "none"
                        }} onChange={this.handleOnInputChange} name="query" value={query} type="text" placeholder="Cari sesuatu (web version)..." autoComplete='off' />
                        <div className="spinner-icon">
                            <i className="fa fa-spin fa-spinner"></i>
                        </div>
                        <button type="submit" style={{
                            background: "transparent",
                            border: "none",
                            outline: "none",
                            marginLeft: "-50px"
                        }}>
                            <i style={{
                                width: "35px",
                                objectFit: "cover",
                                height: "35px",
                                color: "black"

                            }} className="icon-magnifier"></i>
                        </button>
                        {this.renderSearchResults()}
                    </form>
                </>
            )

        } else {

            return (

                <form
                    className="ps-form--search-mobile" onSubmit={this.handleSubmit}
                >
                    <div className="form-group--nest">
                        <input
                            onChange={this.handleOnInputChange}
                            name="query"
                            value={query}
                            className="form-control"
                            type="text"
                            placeholder="Cari sesuatu (mobile version)"
                            style={{
                                width: "100%",
                                padding: "10px 35px 10px 15px",
                                border: "none",
                                borderRadius: "5px",
                                outline: "none"
                            }}
                        />
                        <button style={{
                            background: "transparent",
                            border: "none",
                            outline: "none",
                            marginLeft: "-50px"
                        }}>
                            <i style={{
                                width: "20px",
                                objectFit: "cover",
                                height: "20px",
                                color: "black"

                            }} className="icon-magnifier"></i>
                        </button>
                    </div>
                    {this.renderSearchResults()}

                </form>



            )

        }

    }



}

export default SearchForm