import React from "react";

function PopupProduct() {
  return (
    <div
      className="ps-popup"
      id="subscribe"
      data-time="10000"
      style={{ display: "none" }}
    >
      <div
        className="ps-popup__content bg--cover"
        data-background="http://gracetoko.com/storage/general/newsletter.jpg"
        style={{ backgroundSize: "cover!important" }}
      >
        <a className="ps-popup__close" href="#">
          <i className="icon-cross"></i>
        </a>
        <form
          method="post"
          action="http://gracetoko.com/newsletter/subscribe"
          className="ps-form--subscribe-popup newsletter-form"
        >
          <input
            type="hidden"
            name="_token"
            value="p7mk0zaIfBF0EVima8gKki0s2clCxFcsOuXOWopE"
          />
          <div className="ps-form__content">
            <h4>Get 25% Discount</h4>
            <p>
              Subscribe to the mailing list to receive updates on new arrivals,
              special offers and our promotions.
            </p>
            <div className="form-group">
              <input
                className="form-control"
                name="email"
                type="email"
                placeholder="Email Address"
                required
              />
            </div>

            <div className="form-group">
              <button className="ps-btn" type="submit">
                Subscribe
              </button>
            </div>
            <div className="ps-checkbox">
              <input
                className="form-control"
                type="checkbox"
                id="dont_show_again"
                name="dont_show_again"
              />
              <label htmlFor="dont_show_again">
                Don&#039;t show this popup again
              </label>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}

export default PopupProduct;
