import React from "react";

function ProductFooter() {
  return (
    <footer className="ps-footer">
      <div className="ps-container">
        <div className="ps-footer__widgets">
          <aside className="widget widget_footer widget_contact-us">
            <h4 className="widget-title">Contact us</h4>
            <div className="widget_content">
              <p>Call us 24/7</p>
              <h3>1800 97 97 69</h3>
              <p>
                502 New Street, Brighton VIC, Australia <br />
                <a href="mailto:contact@martfury.co">contact@martfury.co</a>
              </p>
              <ul className="ps-list--social">
                <li>
                  <a
                    href="https://www.facebook.com/"
                    title="Facebook"
                    style={{ color: "#3b5999" }}
                  >
                    <i className="fa fa-facebook"></i>
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.instagram.com/"
                    title="Instagram"
                    style={{ color: "#E1306C" }}
                  >
                    <i className="fa fa-instagram"></i>
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.youtube.com/"
                    title="Youtube"
                    style={{ color: "#FF0000" }}
                  >
                    <i className="fa fa-youtube"></i>
                  </a>
                </li>
              </ul>
            </div>
          </aside>
          <aside className="widget widget_footer">
            <h4 className="widget-title">Quick links</h4>
            <ul className="ps-list--link">
              <li>
                <a href="http://gracetoko.com/terms-of-use">
                  <span>Terms Of Use</span>
                </a>
              </li>
              <li>
                <a href="http://gracetoko.com/terms-conditions">
                  <span>Terms &amp; Conditions</span>
                </a>
              </li>
              <li>
                <a href="http://gracetoko.com/refund-policy">
                  <span>Refund Policy</span>
                </a>
              </li>
              <li>
                <a href="http://gracetoko.com/faqs">
                  <span>FAQs</span>
                </a>
              </li>
              <li>
                <a href="http://gracetoko.com/nothing">
                  <span>404 Page</span>
                </a>
              </li>
            </ul>
          </aside>
          <aside className="widget widget_footer">
            <h4 className="widget-title">Company</h4>
            <ul className="ps-list--link">
              <li>
                <a href="http://gracetoko.com/about-us">
                  <span>About us</span>
                </a>
              </li>
              <li>
                <a href="http://gracetoko.com/affiliate">
                  <span>Affiliate</span>
                </a>
              </li>
              <li>
                <a href="http://gracetoko.com/career">
                  <span>Career</span>
                </a>
              </li>
              <li>
                <a href="http://gracetoko.com/contact">
                  <span>Contact us</span>
                </a>
              </li>
            </ul>
          </aside>
          <aside className="widget widget_footer">
            <h4 className="widget-title">Business</h4>
            <ul className="ps-list--link">
              <li>
                <a href="http://gracetoko.com/blog">
                  <span>Our blog</span>
                </a>
              </li>
              <li>
                <a href="http://gracetoko.com/cart">
                  <span>Cart</span>
                </a>
              </li>
              <li>
                <a href="http://gracetoko.com/customer/overview">
                  <span>My account</span>
                </a>
              </li>
              <li>
                <a href="http://gracetoko.com/products">
                  <span>Shop</span>
                </a>
              </li>
            </ul>
          </aside>
        </div>

        <div className="ps-footer__links">
          <p>
            <strong>Consumer Electric:</strong>
            <a href="#">Air Conditioners</a>
            <a href="#">Audios &amp; Theaters</a>
            <a href="#">Car Electronics</a>
            <a href="#">Office Electronics</a>
            <a href="#">TV Televisions</a>
            <a href="#">Washing Machines</a>
          </p>
          <p>
            <strong>Clothing &amp; Apparel:</strong>
            <a href="#">Printers</a>
            <a href="#">Projectors</a>
            <a href="#">Scanners</a>
            <a href="#">Store &amp; Business</a>
            <a href="#">4K Ultra HD TVs</a>
            <a href="#">LED TVs</a>
            <a href="#">OLED TVs</a>
          </p>
          <p>
            <strong>Home, Garden &amp; Kitchen:</strong>
            <a href="#">Cookware</a>
            <a href="#">Decoration</a>
            <a href="#">Furniture</a>
            <a href="#">Garden Tools</a>
            <a href="#">Garden Equipments</a>
            <a href="#">Powers And Hand Tools</a>
            <a href="#">Utensil &amp; Gadget</a>
          </p>
          <p>
            <strong>Health &amp; Beauty:</strong>
            <a href="#">Hair Care</a>
            <a href="#">Decoration</a>
            <a href="#">Hair Care</a>
            <a href="#">Makeup</a>
            <a href="#">Body Shower</a>
            <a href="#">Skin Care</a>
            <a href="#">Cologine</a>
            <a href="#">Perfume</a>
          </p>
          <p>
            <strong>Jewelry &amp; Watches:</strong>
            <a href="#">Necklace</a>
            <a href="#">Pendant</a>
            <a href="#">Diamond Ring</a>
            <a href="#">Sliver Earing</a>
            <a href="#">Leather Watcher</a>
            <a href="#">Gucci</a>
          </p>
          <p>
            <strong>Computer &amp; Technologies:</strong>
            <a href="#">Desktop PC</a>
            <a href="#">Laptop</a>
            <a href="#">Smartphones</a>
            <a href="#">Tablet</a>
            <a href="#">Game Controller</a>
            <a href="#">Audio &amp; Video</a>
            <a href="#">Wireless Speaker</a>
            <a href="#">Done</a>
          </p>
        </div>

        {/* <div className="ps-footer__links" id="footer-links">
          <footer-product-categories-component
            name="Consumer Electric"
            url="http://gracetoko.com/ajax/get-product-categories?categories%5B0%5D=18&amp;categories%5B1%5D=2&amp;categories%5B2%5D=3&amp;categories%5B3%5D=4&amp;categories%5B4%5D=5&amp;categories%5B5%5D=6&amp;categories%5B6%5D=7"
          ></footer-product-categories-component>

          <footer-product-categories-component
            name="Clothing &amp; Apparel"
            url="http://gracetoko.com/ajax/get-product-categories?categories%5B0%5D=8&amp;categories%5B1%5D=9&amp;categories%5B2%5D=10&amp;categories%5B3%5D=11&amp;categories%5B4%5D=12"
          ></footer-product-categories-component>

          <footer-product-categories-component
            name="Home, Garden &amp; Kitchen"
            url="http://gracetoko.com/ajax/get-product-categories?categories%5B0%5D=13&amp;categories%5B1%5D=14&amp;categories%5B2%5D=15&amp;categories%5B3%5D=16&amp;categories%5B4%5D=17"
          ></footer-product-categories-component>

          <footer-product-categories-component
            name="Health &amp; Beauty"
            url="http://gracetoko.com/ajax/get-product-categories?categories%5B0%5D=20&amp;categories%5B1%5D=21&amp;categories%5B2%5D=22&amp;categories%5B3%5D=23&amp;categories%5B4%5D=24"
          ></footer-product-categories-component>

          <footer-product-categories-component
            name="Computer &amp; Technologies"
            url="http://gracetoko.com/ajax/get-product-categories?categories%5B0%5D=25&amp;categories%5B1%5D=26&amp;categories%5B2%5D=27&amp;categories%5B3%5D=28&amp;categories%5B4%5D=29&amp;categories%5B5%5D=19"
          ></footer-product-categories-component>
        </div> */}
        <div className="ps-footer__copyright">
          <p>© 2022 Martfury. All Rights Reserved.</p>
          <div className="footer-payments">
            <span className="payment-method-title">
              We Using Safe Payment For:
            </span>
            <p className="d-sm-inline-block d-block">
              <span>
                <img
                  src="/storage/general/payment-method-1.jpg"
                  alt="payment method"
                />
              </span>
              <span>
                <img
                  src="storage/general/payment-method-2.jpg"
                  alt="payment method"
                />
              </span>
              <span>
                <img
                  src="storage/general/payment-method-3.jpg"
                  alt="payment method"
                />
              </span>
              <span>
                <img
                  src="storage/general/payment-method-4.jpg"
                  alt="payment method"
                />
              </span>
              <span>
                <img
                  src="storage/general/payment-method-5.jpg"
                  alt="payment method"
                />
              </span>
            </p>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default ProductFooter;
