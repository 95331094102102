import React from "react";

function HeaderPanelSidebar() {
  return (
    //gak kepake
    <>
      <div
        className="ps-panel--sidebar"
        id="cart-mobile"
        style={{ display: "none" }}
      >
        <div className="ps-panel__header">
          <h3>Shopping Cart</h3>
        </div>
        <div className="navigation__content">
          <div className="ps-cart--mobile">
            <div className="ps-cart__content">
              <div className="ps-cart__items ps-cart_no_items">
                <span className="cart-empty-message">
                  No products in the cart.
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        className="ps-panel--sidebar"
        id="navigation-mobile"
        style={{ display: "none" }}
      >
        <div className="ps-panel__header">
          <h3>Catssegories</h3>
        </div>
        <div className="ps-panel__content">
          <ul className="menu--mobile">
            <li className="menu-item-has-children has-mega-menu">
              <a href="http://gracetoko.com/product-categories/hot-promotions">
                <i className="icon-star"></i> HOT PROMOTIONSSs2
              </a>
              <span className="sub-toggle"></span>
              <div className="mega-menu">
                <div className="mega-menu__column">
                  <a href="http://gracetoko.com/product-categories/tv-videos">
                    TV &amp; VIDEOS
                  </a>
                </div>
              </div>
            </li>
            <li className="menu-item-has-children has-mega-menu">
              <a href="http://gracetoko.com/product-categories/computers">
                <i className="icon-desktop"></i> Computers
              </a>
              <span className="sub-toggle"></span>
              <div className="mega-menu">
                <div className="mega-menu__column">
                  <a href="http://gracetoko.com/product-categories/computer-technologies">
                    <h4>
                      Computer &amp; Technologies
                      <span className="sub-toggle"></span>
                    </h4>
                  </a>
                  <ul className="mega-menu__list">
                    <li>
                      <a href="http://gracetoko.com/product-categories/computer-tablets">
                        Computer &amp; Tablets
                      </a>
                    </li>
                    <li>
                      <a href="http://gracetoko.com/product-categories/laptop">
                        Laptop
                      </a>
                    </li>
                    <li>
                      <a href="http://gracetoko.com/product-categories/monitors">
                        Monitors
                      </a>
                    </li>
                    <li>
                      <a href="http://gracetoko.com/product-categories/computer-components">
                        Computer Components
                      </a>
                    </li>
                  </ul>
                </div>
                <div className="mega-menu__column">
                  <a href="http://gracetoko.com/product-categories/networking">
                    <h4>
                      Networking<span className="sub-toggle"></span>
                    </h4>
                  </a>
                  <ul className="mega-menu__list">
                    <li>
                      <a href="http://gracetoko.com/product-categories/drive-storages">
                        Drive &amp; Storages
                      </a>
                    </li>
                    <li>
                      <a href="http://gracetoko.com/product-categories/gaming-laptop">
                        Gaming Laptop
                      </a>
                    </li>
                    <li>
                      <a href="http://gracetoko.com/product-categories/security-protection">
                        Security &amp; Protection
                      </a>
                    </li>
                    <li>
                      <a href="http://gracetoko.com/product-categories/accessories">
                        Accessories
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </li>
            <li>
              <a href="http://gracetoko.com/product-categories/home-improvements">
                <i className="icon-wrench"></i> Home Improvements
              </a>
            </li>
            <li className="menu-item-has-children has-mega-menu">
              <a href="http://gracetoko.com/product-categories/makanan-dan-minuman">
                makanan dan minuman
              </a>
              <span className="sub-toggle"></span>
              <div className="mega-menu">
                <div className="mega-menu__column">
                  <a href="http://gracetoko.com/product-categories/makanan">
                    <h4>
                      minuman<span className="sub-toggle"></span>
                    </h4>
                  </a>
                  <ul className="mega-menu__list">
                    <li>
                      <a href="http://gracetoko.com/product-categories/minuman">
                        madu
                      </a>
                    </li>
                    <li>
                      <a href="http://gracetoko.com">makanan kesehatan</a>
                    </li>
                    <li>
                      <a href="http://gracetoko.com">makanan kesehatan</a>
                    </li>
                  </ul>
                </div>
                <div className="mega-menu__column">
                  <a href="http://gracetoko.com/product-categories/makanan-1">
                    <h4>
                      makanan<span className="sub-toggle"></span>
                    </h4>
                  </a>
                  <ul className="mega-menu__list">
                    <li>
                      <a href="http://gracetoko.com/product-categories/snack">
                        snack
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>

      <div
        className="ps-panel--sidebar"
        id="search-sidebar"
        style={{ display: "none" }}
      >
        <div className="ps-panel__header">
          <form
            className="ps-form--search-mobile"
            action="http://gracetoko.com/products"
            data-ajax-url="http://gracetoko.com/ajax/search-products"
            method="get"
          >
            <div className="form-group--nest position-relative">
              <input
                className="form-control input-search-product"
                name="q"
                type="text"
                placeholder="Search something...ss"
              />
              <div className="spinner-icon">
                <i className="fa fa-spin fa-spinner"></i>
              </div>
              <button type="submit">
                <i className="icon-magnifier"></i>
              </button>
              <div className="ps-panel--search-result"></div>
            </div>
          </form>
        </div>
        <div className="navigation__content"></div>
      </div>

      <div
        className="ps-panel--sidebar"
        id="menu-mobile"
        style={{ display: "none" }}
      >
        <div className="ps-panel__header">
          <h3>Menu</h3>
        </div>
        <div className="ps-panel__content">
          <ul className="menu--mobile">
            <li className="  ">
              <a href="http://gracetoko.com/">Home</a>
            </li>
            <li className=" menu-item-has-children   ">
              <a href="http://gracetoko.com">Pages</a>
              <span className="sub-toggle"></span>
              <ul className="sub-menu">
                <li className="  ">
                  <a href="http://gracetoko.com/about-us">About us</a>
                </li>
                <li className="  ">
                  <a href="http://gracetoko.com/terms-of-use">Terms Of Use</a>
                </li>
                <li className="  ">
                  <a href="http://gracetoko.com/terms-conditions">
                    Terms &amp; Conditions
                  </a>
                </li>
                <li className="  ">
                  <a href="http://gracetoko.com/refund-policy">Refund Policy</a>
                </li>
                <li className="  ">
                  <a href="http://gracetoko.com/coming-soon">Coming soon</a>
                </li>
              </ul>
            </li>
            <li className=" menu-item-has-children   ">
              <a href="http://gracetoko.com/products">Shop</a>
              <span className="sub-toggle"></span>
              <ul className="sub-menu">
                <li className="  ">
                  <a href="http://gracetoko.com/products">All products</a>
                </li>
                <li className="  ">
                  <a href="http://gracetoko.com/product-categories/batteries">
                    Products Of Category
                  </a>
                </li>
                <li className="  ">
                  <a href="http://gracetoko.com/products/beat-headphone">
                    Product Single
                  </a>
                </li>
              </ul>
            </li>
            <li className="  ">
              <a href="http://gracetoko.com/stores">Stores</a>
            </li>
            <li className="  ">
              <a href="http://gracetoko.com/blog">Blog</a>
            </li>
            <li className="  ">
              <a href="http://gracetoko.com/faqs">FAQs</a>
            </li>
            <li className="  ">
              <a href="http://gracetoko.com/contact">Contact</a>
            </li>
          </ul>

          <ul className="menu--mobile menu--mobile-extra">
            <li>
              <a href="http://gracetoko.com/orders/tracking">
                <i className="icon-check-square"></i> Track your order
              </a>
            </li>
            <li>
              <a href="http://gracetoko.com/compare">
                <i className="icon-chart-bars"></i> <span>Compare</span>
              </a>
            </li>
            <li>
              <a href="http://gracetoko.com/wishlist">
                <i className="icon-heart"></i> <span>Wishlist</span>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </>
  );
}

export default HeaderPanelSidebar;
