import Breadcrumb from "../../Components/Common/Breadcrumb";
import RelatedProduct from "../../Components/Common/RelatedProduct";
import ProductFooter from "../../Components/Footer/ProductFooter";
import Sidebar from "../../Components/SideBar/Sidebar";
import Anchor from "../../Components/Common/Anchor";
import Header from "../../Components/Header/Header";
import CartPage from "./CartPage";
import { CartSidebar } from "../../Components/SideBar/CartSidebar";
import HeaderCartMobile from "../../Components/Header/HeaderCartMobile";
// import useCheckMobileScreen from "../../Components/CheckMobile";
// const cartItems = [
//   {
//     itemcode: "1",
//     url: "https://gracetoko.com/products/audio-equipment",
//     title: "Audio Equipment",
//     variants: "Size: S, Color: Blue",
//     price: 0,
//     saleprice: 0,
//     image: "https://gracetoko.com/storage/products/7-150x150.jpg",
//     qtyInCart: 0,
//     storeUrl: "https://gracetoko.com/stores/global-office",
//     storeName: "Global Office"
//   },
//   {
//     itemcode: "2",
//     url: "https://gracetoko.com/products/audio-equipment",
//     title: "Audio Equipment",
//     variants: "Size: S, Color: Blue",
//     price: 12030,
//     saleprice: 0,
//     image: "https://gracetoko.com/storage/products/7-150x150.jpg",
//     qtyInCart: 0,
//     storeUrl: "https://gracetoko.com/stores/global-office",
//     storeName: "Global Office"
//   },

// ]

const Cart = () => {

  return (
    <div id="cart">
      <div id="alert-container"></div>
      <Header />
      <CartSidebar />
      <HeaderCartMobile />
      <div className="ps-container">
        <div className="mt-40 mb-40" >
          <div className="ps-section--shopping ps-shopping-cart pt-40" style={{ paddingTop: "5px", marginTop: "2px" }}>
            <div className="container">
              <div className="row">
                <CartPage />
              </div>
              {/* <div className="ps-section__content">
              </div> */}
              <div className="ps-section__footer">
                <div className="row">
                </div>
              </div>
              {/* <RelatedProduct /> */}
            </div>
          </div>
        </div>
      </div>
      <ProductFooter />

      <div id="back2top">
        <i className="icon icon-arrow-up"></i>
      </div>

      {/* <div className="ps-site-overlay"></div> */}

      <div className="ps-search" id="site-search">
        <a className="ps-btn--close" href="#"></a>
        <div className="ps-search__content">
          <form
            className="ps-form--primary-search"
            action="http://gracetoko.com/products"
            data-ajax-url="http://gracetoko.com/ajax/search-products"
            method="get"
          >
            <input
              className="form-control input-search-product"
              name="q"
              type="text"
              autoComplete="off"
              placeholder="Search for...bwaggg"
            />
            <div className="spinner-icon">
              <i className="fa fa-spin fa-spinner"></i>
            </div>
            <button>
              <i className="aroma-magnifying-glass"></i>
            </button>
            <div className="ps-panel--search-result"></div>
          </form>
        </div>
      </div>
    </div >
  );
}

export default Cart;
