import {Swiper, SwiperSlide} from 'swiper/react';
import React, { useState, useEffect } from 'react'
import {FreeMode, Autoplay, Pagination, Navigation} from 'swiper'
import 'swiper/css';
import 'swiper/css/free-mode'
import 'bootstrap/dist/css/bootstrap.min.css';
import CardsProducts from './CardsProducts';
import axios from '../hooks/useAxios';
import styled from "styled-components"

const SliderProducts = ({selectedCategories}) => {


    const mySwiper = styled.div `
        margin-left: auto;
        margin-right: auto;
        position: relative;
        overflow: hidden;
        list-style: none;
        padding: 0;
        z-index: 1;
        padding-left: 30px;
        padding-right: 30px;

    ` 

    // filter jika ada categories
    // console.log(selectedCategories);

    const [dataproducts, setDataproducts] = useState([]);
    const getProducts = async() => {
        try {
            const response = await axios.get("/featuredproducts?limit=10");
            setDataproducts(response.data)
        } catch (error) {
            console.log(error);
        }
    }

    useEffect(() => {
        getProducts();
    },[])


  return (
    dataproducts && 
    <div className='container py-4 px-4 justify-content-center'>
        <Swiper
            freeMode={true}
            grabCursor={true}
            navigation={true}
            modules={[Autoplay, Pagination, Navigation]}
            className="mySwiper"
            slidesPerView={6}
            // style={{paddingLeft:"30px", paddingRight:"30px", padding:"0px"}}
            breakpoints={{
                0: {
                    slidesPerView: 1,
                    spaceBetween: 10
                },
                480: {
                    slidesPerView: 2,
                    spaceBetween: 10
                },
                768: {
                    slidesPerView: 3,
                    spaceBetween: 15
                },
                1280: {
                    slidesPerView: 6,
                    spaceBetween: 20

                }
            }}
            // autoplay={{delay:5000}}
        >

            {
                dataproducts.map((products) => (
                    <SwiperSlide key={products.id}>
                        <CardsProducts data={products}/>
                    </SwiperSlide>
                ))
            }


        </Swiper>
        
    </div>

  )
}

export default SliderProducts