import { combineReducers } from "redux";
import userReducer from "./userReducer";
import {
    listCartItemsReducer,
    addItemToCartReducer
} from "./cartReducer";
import messageReducer from "./messageReducer";

const reducer = combineReducers({
    userReducer,
    cartItemsList: listCartItemsReducer,
    messageReducer
});

export default reducer;
