import React from "react";

function RelatedProduct() {
  return (
    <>
      <div className="ps-section--default ps-customer-bought mt-60">
        <div className="ps-section__header text-left pb-0 mb-4">
          <h3 className="mb-2">Customers who bought this item also bought</h3>
        </div>
        <div className="ps-section__content">
          <div className="row">
            <div className="col-xl-3 col-lg-3 col-md-4 col-sm-6">
              <div className="ps-product">
                <div className="ps-product__thumbnail">
                  <a href="https://gracetoko.com/products/smart-watches">
                    <img
                      src="https://gracetoko.com/storage/products/2-300x300.jpg"
                      alt="Smart Watches"
                    />
                  </a>
                  <ul className="ps-product__actions">
                    <li>
                      <a
                        className="add-to-cart-button"
                        data-id="2"
                        href="#"
                        data-url="https://gracetoko.com/cart/add-to-cart"
                        title="Add To Cart"
                      >
                        <i className="icon-bag2"></i>
                      </a>
                    </li>
                    <li>
                      <a
                        className="js-quick-view-button"
                        href="#"
                        data-url="https://gracetoko.com/ajax/quick-view/2"
                        title="Quick View"
                      >
                        <i className="icon-eye"></i>
                      </a>
                    </li>
                    <li>
                      <a
                        className="js-add-to-wishlist-button"
                        href="#"
                        data-url="https://gracetoko.com/wishlist/2"
                        title="Add to Wishlist"
                      >
                        <i className="icon-heart"></i>
                      </a>
                    </li>
                    <li>
                      <a
                        className="js-add-to-compare-button"
                        href="#"
                        data-url="https://gracetoko.com/compare/2"
                        title="Compare"
                      >
                        <i className="icon-chart-bars"></i>
                      </a>
                    </li>
                  </ul>
                </div>
                <div className="ps-product__container">
                  <a
                    className="ps-product__vendor"
                    href="https://gracetoko.com/stores/global-office"
                  >
                    Global Office
                  </a>
                  <div className="ps-product__content">
                    <a
                      className="ps-product__title"
                      href="https://gracetoko.com/products/smart-watches"
                    >
                      Smart Watches
                    </a>
                    <div className="rating_wrap">
                      <div className="rating">
                        <div
                          className="product_rate"
                          style={{ width: "53.333333333333%" }}
                        ></div>
                      </div>
                      <span className="rating_num">(3)</span>
                    </div>

                    <p className="ps-product__price ">$40.50 </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-lg-3 col-md-4 col-sm-6">
              <div className="ps-product">
                <div className="ps-product__thumbnail">
                  <a href="https://gracetoko.com/products/nyx-beauty-couton-pallete-makeup-12">
                    <img
                      src="https://gracetoko.com/storage/products/20-300x300.jpg"
                      alt="NYX Beauty Couton Pallete Makeup 12"
                    />
                  </a>
                  <div className="ps-product__badge">-28%</div>
                  <ul className="ps-product__actions">
                    <li>
                      <a
                        className="add-to-cart-button"
                        data-id="20"
                        href="#"
                        data-url="https://gracetoko.com/cart/add-to-cart"
                        title="Add To Cart"
                      >
                        <i className="icon-bag2"></i>
                      </a>
                    </li>
                    <li>
                      <a
                        className="js-quick-view-button"
                        href="#"
                        data-url="https://gracetoko.com/ajax/quick-view/20"
                        title="Quick View"
                      >
                        <i className="icon-eye"></i>
                      </a>
                    </li>
                    <li>
                      <a
                        className="js-add-to-wishlist-button"
                        href="#"
                        data-url="https://gracetoko.com/wishlist/20"
                        title="Add to Wishlist"
                      >
                        <i className="icon-heart"></i>
                      </a>
                    </li>
                    <li>
                      <a
                        className="js-add-to-compare-button"
                        href="#"
                        data-url="https://gracetoko.com/compare/20"
                        title="Compare"
                      >
                        <i className="icon-chart-bars"></i>
                      </a>
                    </li>
                  </ul>
                </div>
                <div className="ps-product__container">
                  <a
                    className="ps-product__vendor"
                    href="https://gracetoko.com/stores/stouffer"
                  >
                    Stouffer
                  </a>
                  <div className="ps-product__content">
                    <a
                      className="ps-product__title"
                      href="https://gracetoko.com/products/nyx-beauty-couton-pallete-makeup-12"
                    >
                      NYX Beauty Couton Pallete Makeup 12
                    </a>
                    <div className="rating_wrap">
                      <div className="rating">
                        <div
                          className="product_rate"
                          style={{ width: "60%" }}
                        ></div>
                      </div>
                      <span className="rating_num">(4)</span>
                    </div>

                    <p className="ps-product__price  sale ">
                      $566.58 <del>$798.00 </del>{" "}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-lg-3 col-md-4 col-sm-6">
              <div className="ps-product">
                <div className="ps-product__thumbnail">
                  <a href="https://gracetoko.com/products/aveeno-moisturizing-body-shower-450ml">
                    <img
                      src="https://gracetoko.com/storage/products/18-300x300.jpg"
                      alt="Aveeno Moisturizing Body Shower 450ml"
                    />
                  </a>
                  <span
                    className="ps-product__badge"
                    style={{ backgroundColor: "#ec2434" }}
                  >
                    Hot
                  </span>
                  <ul className="ps-product__actions">
                    <li>
                      <a
                        className="add-to-cart-button"
                        data-id="18"
                        href="#"
                        data-url="https://gracetoko.com/cart/add-to-cart"
                        title="Add To Cart"
                      >
                        <i className="icon-bag2"></i>
                      </a>
                    </li>
                    <li>
                      <a
                        className="js-quick-view-button"
                        href="#"
                        data-url="https://gracetoko.com/ajax/quick-view/18"
                        title="Quick View"
                      >
                        <i className="icon-eye"></i>
                      </a>
                    </li>
                    <li>
                      <a
                        className="js-add-to-wishlist-button"
                        href="#"
                        data-url="https://gracetoko.com/wishlist/18"
                        title="Add to Wishlist"
                      >
                        <i className="icon-heart"></i>
                      </a>
                    </li>
                    <li>
                      <a
                        className="js-add-to-compare-button"
                        href="#"
                        data-url="https://gracetoko.com/compare/18"
                        title="Compare"
                      >
                        <i className="icon-chart-bars"></i>
                      </a>
                    </li>
                  </ul>
                </div>
                <div className="ps-product__container">
                  <a
                    className="ps-product__vendor"
                    href="https://gracetoko.com/stores/roberts-store"
                  >
                    Robert’s Store
                  </a>
                  <div className="ps-product__content">
                    <a
                      className="ps-product__title"
                      href="https://gracetoko.com/products/aveeno-moisturizing-body-shower-450ml"
                    >
                      Aveeno Moisturizing Body Shower 450ml
                    </a>
                    <div className="rating_wrap">
                      <div className="rating">
                        <div
                          className="product_rate"
                          style={{ width: "25%" }}
                        ></div>
                      </div>
                      <span className="rating_num">(4)</span>
                    </div>

                    <p className="ps-product__price ">$1,296.00 </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-lg-3 col-md-4 col-sm-6">
              <div className="ps-product">
                <div className="ps-product__thumbnail">
                  <a href="https://gracetoko.com/products/samsung-gear-vr-virtual-reality-headset">
                    <img
                      src="https://gracetoko.com/storage/products/17-300x300.jpg"
                      alt="Samsung Gear VR Virtual Reality Headset"
                    />
                  </a>
                  <ul className="ps-product__actions">
                    <li>
                      <a
                        className="add-to-cart-button"
                        data-id="17"
                        href="#"
                        data-url="https://gracetoko.com/cart/add-to-cart"
                        title="Add To Cart"
                      >
                        <i className="icon-bag2"></i>
                      </a>
                    </li>
                    <li>
                      <a
                        className="js-quick-view-button"
                        href="#"
                        data-url="https://gracetoko.com/ajax/quick-view/17"
                        title="Quick View"
                      >
                        <i className="icon-eye"></i>
                      </a>
                    </li>
                    <li>
                      <a
                        className="js-add-to-wishlist-button"
                        href="#"
                        data-url="https://gracetoko.com/wishlist/17"
                        title="Add to Wishlist"
                      >
                        <i className="icon-heart"></i>
                      </a>
                    </li>
                    <li>
                      <a
                        className="js-add-to-compare-button"
                        href="#"
                        data-url="https://gracetoko.com/compare/17"
                        title="Compare"
                      >
                        <i className="icon-chart-bars"></i>
                      </a>
                    </li>
                  </ul>
                </div>
                <div className="ps-product__container">
                  <a
                    className="ps-product__vendor"
                    href="https://gracetoko.com/stores/global-office"
                  >
                    Global Office
                  </a>
                  <div className="ps-product__content">
                    <a
                      className="ps-product__title"
                      href="https://gracetoko.com/products/samsung-gear-vr-virtual-reality-headset"
                    >
                      Samsung Gear VR Virtual Reality Headset
                    </a>
                    <div className="rating_wrap">
                      <div className="rating">
                        <div
                          className="product_rate"
                          style={{ width: "60%" }}
                        ></div>
                      </div>
                      <span className="rating_num">(4)</span>
                    </div>

                    <p className="ps-product__price ">$564.00 </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="ps-section--default" id="products">
        <div className="ps-section__header">
          <h3>Related products</h3>
        </div>
        <div className="ps-section__content">
          <div
            data-owl-auto="true"
            data-owl-loop="false"
            data-owl-speed="10000"
            data-owl-gap="30"
            data-owl-nav="true"
            data-owl-dots="true"
            data-owl-item="6"
            data-owl-item-xs="2"
            data-owl-item-sm="2"
            data-owl-item-md="3"
            data-owl-item-lg="4"
            data-owl-item-xl="5"
            data-owl-duration="1000"
            data-owl-mousedrag="on"
            className="ps-carousel--nav owl-slider owl-carousel owl-loaded owl-drag"
          >
            <div className="owl-stage-outer">
              <div
                className="owl-stage"
                style={{
                  transform: `translate3d(-275px, 0px, 0px)`,
                  transitionDuration: "1s",
                  width: "1654px",
                }}
              >
                <div className="owl-item" style={{ width: "245.6px" }}>
                  <div className="ps-product">
                    <div className="ps-product__thumbnail">
                      <a href="https://gracetoko.com/products/xbox-one-wireless-controller-black-color">
                        <img
                          src="https://gracetoko.com/storage/products/11-300x300.jpg"
                          alt="Xbox One Wireless Controller Black Color"
                        />
                      </a>
                      <ul className="ps-product__actions">
                        <li>
                          <a
                            className="add-to-cart-button"
                            data-id="11"
                            href="#"
                            data-url="https://gracetoko.com/cart/add-to-cart"
                            title="Add To Cart"
                          >
                            <i className="icon-bag2"></i>
                          </a>
                        </li>
                        <li>
                          <a
                            className="js-quick-view-button"
                            href="#"
                            data-url="https://gracetoko.com/ajax/quick-view/11"
                            title="Quick View"
                          >
                            <i className="icon-eye"></i>
                          </a>
                        </li>
                        <li>
                          <a
                            className="js-add-to-wishlist-button"
                            href="#"
                            data-url="https://gracetoko.com/wishlist/11"
                            title="Add to Wishlist"
                          >
                            <i className="icon-heart"></i>
                          </a>
                        </li>
                        <li>
                          <a
                            className="js-add-to-compare-button"
                            href="#"
                            data-url="https://gracetoko.com/compare/11"
                            title="Compare"
                          >
                            <i className="icon-chart-bars"></i>
                          </a>
                        </li>
                      </ul>
                    </div>
                    <div className="ps-product__container">
                      <a
                        className="ps-product__vendor"
                        href="https://gracetoko.com/stores/gopro"
                      >
                        GoPro
                      </a>
                      <div className="ps-product__content">
                        <a
                          className="ps-product__title"
                          href="https://gracetoko.com/products/xbox-one-wireless-controller-black-color"
                        >
                          Xbox One Wireless Controller Black Color
                        </a>
                        <div className="rating_wrap">
                          <div className="rating">
                            <div
                              className="product_rate"
                              style={{ width: "56%" }}
                            ></div>
                          </div>
                          <span className="rating_num">(5)</span>
                        </div>
                        <p className="ps-product__price ">$1,222.00 </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="owl-item active" style={{ width: "245.6px" }}>
                  <div className="ps-product">
                    <div className="ps-product__thumbnail">
                      <a href="https://gracetoko.com/products/smart-watches">
                        <img
                          src="https://gracetoko.com/storage/products/2-300x300.jpg"
                          alt="Smart Watches"
                        />
                      </a>
                      <ul className="ps-product__actions">
                        <li>
                          <a
                            className="add-to-cart-button"
                            data-id="2"
                            href="#"
                            data-url="https://gracetoko.com/cart/add-to-cart"
                            title="Add To Cart"
                          >
                            <i className="icon-bag2"></i>
                          </a>
                        </li>
                        <li>
                          <a
                            className="js-quick-view-button"
                            href="#"
                            data-url="https://gracetoko.com/ajax/quick-view/2"
                            title="Quick View"
                          >
                            <i className="icon-eye"></i>
                          </a>
                        </li>
                        <li>
                          <a
                            className="js-add-to-wishlist-button"
                            href="#"
                            data-url="https://gracetoko.com/wishlist/2"
                            title="Add to Wishlist"
                          >
                            <i className="icon-heart"></i>
                          </a>
                        </li>
                        <li>
                          <a
                            className="js-add-to-compare-button"
                            href="#"
                            data-url="https://gracetoko.com/compare/2"
                            title="Compare"
                          >
                            <i className="icon-chart-bars"></i>
                          </a>
                        </li>
                      </ul>
                    </div>
                    <div className="ps-product__container">
                      <a
                        className="ps-product__vendor"
                        href="https://gracetoko.com/stores/global-office"
                      >
                        Global Office
                      </a>
                      <div className="ps-product__content">
                        <a
                          className="ps-product__title"
                          href="https://gracetoko.com/products/smart-watches"
                        >
                          Smart Watches
                        </a>
                        <div className="rating_wrap">
                          <div className="rating">
                            <div
                              className="product_rate"
                              style={{ width: "53.333333333333%" }}
                            ></div>
                          </div>
                          <span className="rating_num">(3)</span>
                        </div>
                        <p className="ps-product__price ">$40.50 </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="owl-nav">
              <button type="button" role="presentation" className="owl-prev">
                <i className="icon-chevron-left"></i>
              </button>
              <button
                type="button"
                role="presentation"
                className="owl-next disabled"
              >
                <i className="icon-chevron-right"></i>
              </button>
            </div>
            <div className="owl-dots">
              <button role="button" className="owl-dot">
                <span></span>
              </button>
              <button role="button" className="owl-dot active">
                <span></span>
              </button>
            </div>
          </div>
        </div>
      </div>

      {/* <div className="ps-section--default ps-customer-bought mt-60">
        <div className="ps-section__header text-left pb-0 mb-4">
          <h3 className="mb-2">Customers who bought this item also bought</h3>
        </div>
        <div className="ps-section__content">
          <div className="row">
            <div className="col-xl-3 col-lg-3 col-md-4 col-sm-6">
              <div className="ps-product">
                <div className="ps-product__thumbnail">
                  <a href="http://gracetoko.com/products/xbox-one-wireless-controller-black-color">
                    <img
                      src="http://gracetoko.com/storage/products/11-300x300.jpg"
                      alt="Xbox One Wireless Controller Black Color"
                    />
                  </a>
                  <ul className="ps-product__actions">
                    <li>
                      <a
                        className="add-to-cart-button"
                        data-id="11"
                        href="#"
                        data-url="http://gracetoko.com/cart/add-to-cart"
                        title="Add To Cart"
                      >
                        <i className="icon-bag2"></i>
                      </a>
                    </li>
                    <li>
                      <a
                        className="js-quick-view-button"
                        href="#"
                        data-url="http://gracetoko.com/ajax/quick-view/11"
                        title="Quick View"
                      >
                        <i className="icon-eye"></i>
                      </a>
                    </li>
                    <li>
                      <a
                        className="js-add-to-wishlist-button"
                        href="#"
                        data-url="http://gracetoko.com/wishlist/11"
                        title="Add to Wishlist"
                      >
                        <i className="icon-heart"></i>
                      </a>
                    </li>
                    <li>
                      <a
                        className="js-add-to-compare-button"
                        href="#"
                        data-url="http://gracetoko.com/compare/11"
                        title="Compare"
                      >
                        <i className="icon-chart-bars"></i>
                      </a>
                    </li>
                  </ul>
                </div>
                <div className="ps-product__container">
                  <a
                    className="ps-product__vendor"
                    href="http://gracetoko.com/stores/gopro"
                  >
                    GoPro
                  </a>
                  <div className="ps-product__content">
                    <a
                      className="ps-product__title"
                      href="http://gracetoko.com/products/xbox-one-wireless-controller-black-color"
                    >
                      Xbox One Wireless Controller Black Color
                    </a>
                    <div className="rating_wrap">
                      <div className="rating">
                        <div
                          className="product_rate"
                          style={{ width: "56%" }}
                        ></div>
                      </div>
                      <span className="rating_num">(5)</span>
                    </div>

                    <p className="ps-product__price ">$1,222.00 </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-lg-3 col-md-4 col-sm-6">
              <div className="ps-product">
                <div className="ps-product__thumbnail">
                  <a href="http://gracetoko.com/products/smart-watch-external">
                    <img
                      src="http://gracetoko.com/storage/products/5-300x300.jpg"
                      alt="Smart Watch External"
                    />
                  </a>
                  <ul className="ps-product__actions">
                    <li>
                      <a
                        className="add-to-cart-button"
                        data-id="5"
                        href="#"
                        data-url="http://gracetoko.com/cart/add-to-cart"
                        title="Add To Cart"
                      >
                        <i className="icon-bag2"></i>
                      </a>
                    </li>
                    <li>
                      <a
                        className="js-quick-view-button"
                        href="#"
                        data-url="http://gracetoko.com/ajax/quick-view/5"
                        title="Quick View"
                      >
                        <i className="icon-eye"></i>
                      </a>
                    </li>
                    <li>
                      <a
                        className="js-add-to-wishlist-button"
                        href="#"
                        data-url="http://gracetoko.com/wishlist/5"
                        title="Add to Wishlist"
                      >
                        <i className="icon-heart"></i>
                      </a>
                    </li>
                    <li>
                      <a
                        className="js-add-to-compare-button"
                        href="#"
                        data-url="http://gracetoko.com/compare/5"
                        title="Compare"
                      >
                        <i className="icon-chart-bars"></i>
                      </a>
                    </li>
                  </ul>
                </div>
                <div className="ps-product__container">
                  <a
                    className="ps-product__vendor"
                    href="http://gracetoko.com/stores/stouffer"
                  >
                    Stouffer
                  </a>
                  <div className="ps-product__content">
                    <a
                      className="ps-product__title"
                      href="http://gracetoko.com/products/smart-watch-external"
                    >
                      Smart Watch External
                    </a>
                    <div className="rating_wrap">
                      <div className="rating">
                        <div
                          className="product_rate"
                          style={{ width: "53.333333333333%" }}
                        ></div>
                      </div>
                      <span className="rating_num">(3)</span>
                    </div>

                    <p className="ps-product__price ">$787.00 </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-lg-3 col-md-4 col-sm-6">
              <div className="ps-product">
                <div className="ps-product__thumbnail">
                  <a href="http://gracetoko.com/products/samsung-smart-phone">
                    <img
                      src="http://gracetoko.com/storage/products/9-300x300.jpg"
                      alt="Samsung Smart Phone"
                    />
                  </a>
                  <span
                    className="ps-product__badge"
                    style={{ backgroundColor: "#ec2434" }}
                  >
                    Hot
                  </span>
                  <ul className="ps-product__actions">
                    <li>
                      <a
                        className="add-to-cart-button"
                        data-id="9"
                        href="#"
                        data-url="http://gracetoko.com/cart/add-to-cart"
                        title="Add To Cart"
                      >
                        <i className="icon-bag2"></i>
                      </a>
                    </li>
                    <li>
                      <a
                        className="js-quick-view-button"
                        href="#"
                        data-url="http://gracetoko.com/ajax/quick-view/9"
                        title="Quick View"
                      >
                        <i className="icon-eye"></i>
                      </a>
                    </li>
                    <li>
                      <a
                        className="js-add-to-wishlist-button"
                        href="#"
                        data-url="http://gracetoko.com/wishlist/9"
                        title="Add to Wishlist"
                      >
                        <i className="icon-heart"></i>
                      </a>
                    </li>
                    <li>
                      <a
                        className="js-add-to-compare-button"
                        href="#"
                        data-url="http://gracetoko.com/compare/9"
                        title="Compare"
                      >
                        <i className="icon-chart-bars"></i>
                      </a>
                    </li>
                  </ul>
                </div>
                <div className="ps-product__container">
                  <a
                    className="ps-product__vendor"
                    href="http://gracetoko.com/stores/global-store"
                  >
                    Global Store
                  </a>
                  <div className="ps-product__content">
                    <a
                      className="ps-product__title"
                      href="http://gracetoko.com/products/samsung-smart-phone"
                    >
                      Samsung Smart Phone
                    </a>
                    <div className="rating_wrap">
                      <div className="rating">
                        <div
                          className="product_rate"
                          style={{ width: "30%" }}
                        ></div>
                      </div>
                      <span className="rating_num">(2)</span>
                    </div>

                    <p className="ps-product__price ">$575.00 </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-lg-3 col-md-4 col-sm-6">
              <div className="ps-product">
                <div className="ps-product__thumbnail">
                  <a href="http://gracetoko.com/products/dual-camera-20mp">
                    <img
                      src="http://gracetoko.com/storage/products/1-300x300.jpg"
                      alt="Dual Camera 20MP"
                    />
                  </a>
                  <ul className="ps-product__actions">
                    <li>
                      <a
                        className="add-to-cart-button"
                        data-id="1"
                        href="#"
                        data-url="http://gracetoko.com/cart/add-to-cart"
                        title="Add To Cart"
                      >
                        <i className="icon-bag2"></i>
                      </a>
                    </li>
                    <li>
                      <a
                        className="js-quick-view-button"
                        href="#"
                        data-url="http://gracetoko.com/ajax/quick-view/1"
                        title="Quick View"
                      >
                        <i className="icon-eye"></i>
                      </a>
                    </li>
                    <li>
                      <a
                        className="js-add-to-wishlist-button"
                        href="#"
                        data-url="http://gracetoko.com/wishlist/1"
                        title="Add to Wishlist"
                      >
                        <i className="icon-heart"></i>
                      </a>
                    </li>
                    <li>
                      <a
                        className="js-add-to-compare-button"
                        href="#"
                        data-url="http://gracetoko.com/compare/1"
                        title="Compare"
                      >
                        <i className="icon-chart-bars"></i>
                      </a>
                    </li>
                  </ul>
                </div>
                <div className="ps-product__container">
                  <a
                    className="ps-product__vendor"
                    href="http://gracetoko.com/stores/global-office"
                  >
                    Global Office
                  </a>
                  <div className="ps-product__content">
                    <a
                      className="ps-product__title"
                      href="http://gracetoko.com/products/dual-camera-20mp"
                    >
                      Dual Camera 20MP
                    </a>
                    <div className="rating_wrap">
                      <div className="rating">
                        <div
                          className="product_rate"
                          style={{ width: "37.142857142857%" }}
                        ></div>
                      </div>
                      <span className="rating_num">(7)</span>
                    </div>

                    <p className="ps-product__price ">$80.25 </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-lg-3 col-md-4 col-sm-6">
              <div className="ps-product">
                <div className="ps-product__thumbnail">
                  <a href="http://gracetoko.com/products/sound-intone-i65-earphone-white-version">
                    <img
                      src="http://gracetoko.com/storage/products/13-300x300.jpg"
                      alt="Sound Intone I65 Earphone White Version"
                    />
                  </a>
                  <ul className="ps-product__actions">
                    <li>
                      <a
                        className="add-to-cart-button"
                        data-id="13"
                        href="#"
                        data-url="http://gracetoko.com/cart/add-to-cart"
                        title="Add To Cart"
                      >
                        <i className="icon-bag2"></i>
                      </a>
                    </li>
                    <li>
                      <a
                        className="js-quick-view-button"
                        href="#"
                        data-url="http://gracetoko.com/ajax/quick-view/13"
                        title="Quick View"
                      >
                        <i className="icon-eye"></i>
                      </a>
                    </li>
                    <li>
                      <a
                        className="js-add-to-wishlist-button"
                        href="#"
                        data-url="http://gracetoko.com/wishlist/13"
                        title="Add to Wishlist"
                      >
                        <i className="icon-heart"></i>
                      </a>
                    </li>
                    <li>
                      <a
                        className="js-add-to-compare-button"
                        href="#"
                        data-url="http://gracetoko.com/compare/13"
                        title="Compare"
                      >
                        <i className="icon-chart-bars"></i>
                      </a>
                    </li>
                  </ul>
                </div>
                <div className="ps-product__container">
                  <a
                    className="ps-product__vendor"
                    href="http://gracetoko.com/stores/global-store"
                  >
                    Global Store
                  </a>
                  <div className="ps-product__content">
                    <a
                      className="ps-product__title"
                      href="http://gracetoko.com/products/sound-intone-i65-earphone-white-version"
                    >
                      Sound Intone I65 Earphone White Version
                    </a>
                    <div className="rating_wrap">
                      <div className="rating">
                        <div
                          className="product_rate"
                          style={{ width: "45%" }}
                        ></div>
                      </div>
                      <span className="rating_num">(4)</span>
                    </div>

                    <p className="ps-product__price ">$513.00 </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-lg-3 col-md-4 col-sm-6">
              <div className="ps-product">
                <div className="ps-product__thumbnail">
                  <a href="http://gracetoko.com/products/nyx-beauty-couton-pallete-makeup-12">
                    <img
                      src="http://gracetoko.com/storage/products/19-300x300.jpg"
                      alt="NYX Beauty Couton Pallete Makeup 12"
                    />
                  </a>
                  <ul className="ps-product__actions">
                    <li>
                      <a
                        className="add-to-cart-button"
                        data-id="19"
                        href="#"
                        data-url="http://gracetoko.com/cart/add-to-cart"
                        title="Add To Cart"
                      >
                        <i className="icon-bag2"></i>
                      </a>
                    </li>
                    <li>
                      <a
                        className="js-quick-view-button"
                        href="#"
                        data-url="http://gracetoko.com/ajax/quick-view/19"
                        title="Quick View"
                      >
                        <i className="icon-eye"></i>
                      </a>
                    </li>
                    <li>
                      <a
                        className="js-add-to-wishlist-button"
                        href="#"
                        data-url="http://gracetoko.com/wishlist/19"
                        title="Add to Wishlist"
                      >
                        <i className="icon-heart"></i>
                      </a>
                    </li>
                    <li>
                      <a
                        className="js-add-to-compare-button"
                        href="#"
                        data-url="http://gracetoko.com/compare/19"
                        title="Compare"
                      >
                        <i className="icon-chart-bars"></i>
                      </a>
                    </li>
                  </ul>
                </div>
                <div className="ps-product__container">
                  <a
                    className="ps-product__vendor"
                    href="http://gracetoko.com/stores/gopro"
                  >
                    GoPro
                  </a>
                  <div className="ps-product__content">
                    <a
                      className="ps-product__title"
                      href="http://gracetoko.com/products/nyx-beauty-couton-pallete-makeup-12"
                    >
                      NYX Beauty Couton Pallete Makeup 12
                    </a>
                    <div className="rating_wrap">
                      <div className="rating">
                        <div
                          className="product_rate"
                          style={{ width: "66.666666666667%" }}
                        ></div>
                      </div>
                      <span className="rating_num">(9)</span>
                    </div>

                    <p className="ps-product__price ">$1,094.00 </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-lg-3 col-md-4 col-sm-6">
              <div className="ps-product">
                <div className="ps-product__thumbnail">
                  <a href="http://gracetoko.com/products/samsung-gear-vr-virtual-reality-headset">
                    <img
                      src="http://gracetoko.com/storage/products/17-300x300.jpg"
                      alt="Samsung Gear VR Virtual Reality Headset"
                    />
                  </a>
                  <ul className="ps-product__actions">
                    <li>
                      <a
                        className="add-to-cart-button"
                        data-id="17"
                        href="#"
                        data-url="http://gracetoko.com/cart/add-to-cart"
                        title="Add To Cart"
                      >
                        <i className="icon-bag2"></i>
                      </a>
                    </li>
                    <li>
                      <a
                        className="js-quick-view-button"
                        href="#"
                        data-url="http://gracetoko.com/ajax/quick-view/17"
                        title="Quick View"
                      >
                        <i className="icon-eye"></i>
                      </a>
                    </li>
                    <li>
                      <a
                        className="js-add-to-wishlist-button"
                        href="#"
                        data-url="http://gracetoko.com/wishlist/17"
                        title="Add to Wishlist"
                      >
                        <i className="icon-heart"></i>
                      </a>
                    </li>
                    <li>
                      <a
                        className="js-add-to-compare-button"
                        href="#"
                        data-url="http://gracetoko.com/compare/17"
                        title="Compare"
                      >
                        <i className="icon-chart-bars"></i>
                      </a>
                    </li>
                  </ul>
                </div>
                <div className="ps-product__container">
                  <a
                    className="ps-product__vendor"
                    href="http://gracetoko.com/stores/global-office"
                  >
                    Global Office
                  </a>
                  <div className="ps-product__content">
                    <a
                      className="ps-product__title"
                      href="http://gracetoko.com/products/samsung-gear-vr-virtual-reality-headset"
                    >
                      Samsung Gear VR Virtual Reality Headset
                    </a>
                    <div className="rating_wrap">
                      <div className="rating">
                        <div
                          className="product_rate"
                          style={{ width: "60%" }}
                        ></div>
                      </div>
                      <span className="rating_num">(4)</span>
                    </div>

                    <p className="ps-product__price ">$564.00 </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}
    </>
  );
}

export default RelatedProduct;
