import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import axios from "axios";
import "./Components/sass/style.scss";
axios.defaults.withCredentials = true;

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(<App />)


// root.render(
//     <AuthProvider>
//         <App />
//     </AuthProvider>
//     // <BrowserRouter>
//     //     <Routes>
//     //         <Route path="/*" element={<App />} />
//     //     </Routes>
//     //     {/* <App /> */}
//     // </BrowserRouter>
// );


//root.render( < App / > );