import { useEffect, useState } from "react";
import CustomerSideBar from "./SideBar";
import Header from "../../Components/Header/Header";
import HeaderMobile from "../../Components/Header/HeaderMobile";
import Breadcrumb from "../../Components/Common/Breadcrumb";
import Sidebar from "../../Components/SideBar/Sidebar";
import ReactPaginate from "react-paginate";
import { useSelector } from "react-redux";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import CardAddress from "../../Components/CardAddress";
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import AddressModal from "./AddressModal";

const Address = () => {

    const MySwal = withReactContent(Swal);
    const axiosPrivate = useAxiosPrivate();
    const { userLogin } = useSelector(state => state.userReducer);
    const [listAddress, setListAddress] = useState([]);
    const [modalParrent, setModalParrent] = useState(false);
    const [page, setPage] = useState(0);
    const [limit, setLimit] = useState(5);
    const [pages, setPages] = useState(0);
    const [rows, setRows] = useState(0);
    const [counter, setCounter] = useState(0);


    const handleOpenModal = () => {
        setModalParrent(true)
    }

    const handleCloseModal = () => {
        setModalParrent(false)
    }

    const [formData, setFormData] = useState({})


    const changePage = ({ selected }) => {
        setPage(selected);
    }

    const handleRefresh = () => {
        setCounter(counter + 1);
    }
    const [cardCallback, setCardCallback] = useState(null);

    const getAddresses = async () => {
        try {
            // const response = await axiosPrivate.get("/getcustomeraddress?user=" + userLogin.userId);
            const response = await axiosPrivate.get(`/getcustomeraddress?user=${userLogin.userId}&page=${page}&limit=${limit}`);
            const result = response.data;
            setListAddress(result.data);
            setPage(result.page);
            setRows(result.totalRows);
            setPages(result.totalPages);
            // console.log(result.data);
        } catch (error) {
            console.log(error);
        }
    };

    const getAddressById = async (data) => {
        try {
            const response = await axiosPrivate.get(`/addressbyid/${data.id}`);
            var address = response.data.data;
            if (address !== null) {

                setFormData(address);
                handleOpenModal();


            } else {
                console.log("not found!!!")
            }
        } catch (error) {
            console.log(error)
        }
    }


    const deleteAddress = async (data) => {
        let alamat = data;
        if (alamat.is_default > 0) {

            console.log("alamat utama kaga bisa dihapus")

        } else {

            Swal.fire({
                title: "Hapus Alamat",
                text: `${alamat.label_address} -  ${alamat.address}`,
                icon: "warning",
                showCancelButton: true,
                confirmButtonText: "Hapus Alamat",
                customClass: {
                    confirmButton: 'btn btn-primary',
                    cancelButton: 'btn btn-outline-danger ms-1'
                },
                buttonsStyling: false
            }).then(async function (result) {
                if (result.value) {
                    const data = {
                        "id_address": alamat.id
                    }
                    try {
                        const res = await axiosPrivate.post("/customerremoveadd", data)
                        MySwal.fire({
                            icon: 'success',
                            title: 'Hapus Alamat!',
                            text: `Alamat ${alamat.address} berhasil dihapus.`,
                            customClass: {
                                confirmButton: 'btn btn-success'
                            }
                        })
                        setCounter(counter + 1);
                    } catch (error) {
                        console.log("error post : ", error);
                    }

                }
            })
        }
    }

    const setDefaultAddress = async (data) => {
        let alamat = data;
        Swal.fire({
            title: "Alamat utama",
            text: `${alamat.label_address} -  ${alamat.address}`,
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: "Alamat Utama",
            customClass: {
                confirmButton: 'btn btn-primary',
                cancelButton: 'btn btn-outline-danger ms-1'
            },
            buttonsStyling: false
        }).then(async function (result) {
            if (result.value) {
                const data = {
                    "id_customer": alamat.customer_id,
                    "id_address": alamat.id
                }
                try {
                    const res = await axiosPrivate.post("/customerdefaultadd", data)
                    MySwal.fire({
                        icon: 'success',
                        title: 'Alamat utama!',
                        text: 'Alamat utama berhasil di rubah.',
                        customClass: {
                            confirmButton: 'btn btn-success'
                        }
                    })
                    setCounter(counter + 1);
                } catch (error) {
                    console.log("error post : ", error);
                }

            }
        })
    }

    const onChangeState = (cardCallback, alamat) => {
        if (cardCallback === "DEFAULT") {
            setDefaultAddress(alamat)
        }
        if (cardCallback === "DELETE") {
            deleteAddress(alamat);
        }
        if (cardCallback === "UPDATE") {

            getAddressById(alamat);

        }
    }

    const arrCumb = [
        {
            "name": "Address book",
            "link": "customer/address"
        }
    ]

    useEffect(() => {
        getAddresses()
    }, [page, counter])

    return (
        <>
            <Header />
            <HeaderMobile />
            <Breadcrumb pagename={arrCumb} />
            <Sidebar />
            <section className="ps-section--account crop-avatar customer-page">
                <div className="container">
                    <div className="row">
                        <CustomerSideBar pageid="address" />
                        <div className="col-lg-8">
                            <div className="ps-section__right">
                                <div className="ps-section__header">
                                    <h3></h3>
                                    <div className="float-left" >
                                        <h4 style={{ position: "absolute", marginTop: "15px", marginLeft: "10px" }}>Daftar Alamat</h4>
                                    </div>
                                    <div className="float-right">
                                        <button
                                            // className="ps-btn"
                                            style={{ backgroundColor: "transparent", borderRadius: "10px", border: "1px solid #98C379", fontSize: "14px", fontWeight: "400px", color: "black", textAlign: "center", height: "40px", verticalAlign: "middle" }}
                                            onClick={() => {
                                                setFormData({})
                                                setModalParrent(true)
                                            }}
                                        >&nbsp;&nbsp;Tambah Alamat&nbsp;&nbsp;</button>

                                        {/* <a className="add-address ps-btn ps-btn--sm ps-btn--small" href="customer/address/create">
                                            <span>Add a new address</span>
                                        </a> */}
                                    </div>
                                </div>
                                <div className="ps-section__content">
                                    <div className="address__content" style={{ marginTop: "30px" }}>
                                        {
                                            listAddress.map((dt) => (
                                                <CardAddress data={dt} key={dt.id} onChangeState={onChangeState} />
                                            ))
                                        }
                                    </div>
                                </div>

                                <div className="ps-pagination" style={{ paddingTop: "15px" }}>
                                    <ReactPaginate
                                        pageCount={pages}
                                        onPageChange={changePage}
                                        activeLinkClassName={"active"}
                                        containerClassName={"pagination"}
                                        pageClassName={'page-item'}
                                        breakLabel={'...'}
                                        pageLinkClassName={'page-item'}
                                        activeClassName={'active hidden'}
                                    />
                                </div>

                                <AddressModal modalParrent={modalParrent} handleCloseModal={handleCloseModal} data={formData} defaultUser={userLogin.userId} handleRefresh={handleRefresh} />

                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </>
    )
}

export default Address