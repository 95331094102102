import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "../hooks/useAxios";
import Product from "./Product/index";
import ShopPage from "./Shop";
import Header from "../Components/Header/Header";
import Page404 from "./Page404";
// import Loading from "../Components/Loading";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import { Oval } from 'react-loader-spinner'


const PageProducts = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const shoplink = location.pathname.split("/")[1] || "";
  const productlink = location.pathname.split("/")[2] || "";

  const [isLoading, setIsLoading] = useState(true);
  const [pagetype, setPagetype] = useState("");

  const [product, setProduct] = useState([])
  const [foundData, setFoundData] = useState(0);
  const style = { position: "fixed", top: "50%", left: "50%", transform: "translate(-50%, -50%)" };


  const handlePage = async (shoplink, productlink) => {

    if (productlink != '') {
      const res = await axios.get(`/product/${shoplink}/${productlink}`);
      setIsLoading(false);
      setPagetype("product");
      setProduct(res.data);
    } else {
      const res = await axios.get(`/vendorstore/${shoplink}`);
      setIsLoading(false);
      setPagetype("vendor");
      setProduct(res.data);
    }
  }

  useEffect(() => {
    handlePage(shoplink, productlink)
  }, [])


  return (
    <>
      {
        isLoading == false ? (
          product.length > 0 ? pagetype === "product" ? <Product data={product} /> : <ShopPage data={product} /> : <Page404 />
        ) :
          <div style={style}>
            <Oval
              height={100}
              width={100}
              color="#00BFFF"
              ariaLLabel="Please wait..."
            />
          </div>

      }
    </>
  )
}

export default PageProducts