// import firebase from "firebase";
// const firebaseConfig = {
//   apiKey: process.env.React_App_Firebase_API_Key,
//   authDomain: "gracetokocart.firebaseapp.com",
//   databaseURL: "https://gracetokocart-default-rtdb.firebaseio.com",
//   projectId: process.env.React_App_Firebase_Project_ID,
//   storageBucket: "gracetokocart.appspot.com",
//   messagingSenderId: "444333271205",
//   appId: "1:444333271205:web:72d962da7da514afc5c54c",
//   measurementId: "G-84738LMHNX",
// };

// firebase.initializeApp(firebaseConfig);

// export default firebase;

import { initializeApp } from "firebase/app";
import { getDatabase } from "firebase/database";

const firebaseConfig = {
  apiKey: process.env.React_App_Firebase_API_Key,
  authDomain: "gracetokocart.firebaseapp.com",
  databaseURL: "https://gracetokocart-default-rtdb.firebaseio.com",
  projectId: process.env.React_App_Firebase_Project_ID,
  storageBucket: "gracetokocart.appspot.com",
  messagingSenderId: "444333271205",
  appId: "1:444333271205:web:72d962da7da514afc5c54c",
  measurementId: "G-84738LMHNX",
};

const app = initializeApp(firebaseConfig);
export const db = getDatabase(app);

