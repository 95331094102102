import React from "react";
import { Provider } from "react-redux";
import "./App.css";
import { Routes, store } from "./config";
import { ReactNotifications } from "react-notifications-component";
import "react-notifications-component/dist/theme.css";
// import "animate.css";

function App() {
  return (
    <Provider store={store}>
      <ReactNotifications />
      <Routes />
    </Provider>
  );
}

export default App;
