import React from 'react'
import { Store } from "react-notifications-component";
import "react-notifications-component/dist/theme.css";

export function successPopup(pesan, timer) {
    Store.addNotification({
        title: "Success",
        message: pesan,
        type: "success",
        insert: "top",
        container: "bottom-right",
        animationIn: ["animated", "fadeIn"],
        animationOut: ["animated", "fadeOut"],
        dismiss: { duration: timer || 3, showIcon: true },
        dismissable: { click: true }
    })
}

export function errorPopup(pesan, timer) {
    Store.addNotification({
        title: "Error",
        message: pesan,
        type: "danger",
        insert: "top",
        container: "center",
        animationIn: ["animated", "fadeIn"],
        animationOut: ["animated", "fadeOut"],
        dismiss: { duration: timer || 3, showIcon: true },
        dismissable: { click: true }
    })
}