import React, { useEffect, useState } from "react";
import axios from "../../hooks/useAxios";
import { SliderDealOfDay } from "./SliderDealOfDay";
import {Swiper, SwiperSlide} from 'swiper/react';
import {FreeMode, Autoplay, Pagination, Navigation} from 'swiper'
import 'swiper/css';
import 'swiper/css/free-mode'

function DealOfDay() {

  const [flashSales, setFlashSales] = useState([]);

  const getFlashSales = async() => {
    try {
        const res = await axios.get(`/flashsales?limit=12`);
        setFlashSales(res.data);
        // console.log(res.data);
    } catch (error) {
        console.log(error)        
    }
  } 

  useState(()=> {

    getFlashSales();

  },[])





  return (

    flashSales.length > 0 && 
    <div className="ps-deal-of-day">
      <div className="ps-container">
        <div className="ps-section__header">
          <div className="ps-block--countdown-deal">
            <div className="ps-block__left">
              <h3>Deal of the day</h3>
            </div>
            <div className="ps-block__right">
              <figure>
                <figcaption>End in:</figcaption>
                <ul
                  className="ps-countdown"
                  data-time="December 30, 2021 15:37:25"
                >
                  <li>
                    <span className="days"></span>
                  </li>
                  <li>
                    <span className="hours"></span>
                  </li>
                  <li>
                    <span className="minutes"></span>
                  </li>
                  <li>
                    <span className="seconds"></span>
                  </li>
                </ul>
              </figure>
            </div>
          </div>
          {/* <a href="shop-default.html">View all</a> */}
        </div>
        <div className="ps-section__content">

          {
            <Swiper
                freeMode={true}
                grabCursor={true}
                navigation={true}
                modules={[Autoplay, Pagination, Navigation]}
                slidesPerView={7}
                breakpoints={{
                    0: {
                        slidesPerView: 2,
                        spaceBetween: 10
                    },
                    480: {
                        slidesPerView: 2,
                        spaceBetween: 10
                    },
                    768: {
                        slidesPerView: 3,
                        spaceBetween: 15
                    },
                    1280: {
                        slidesPerView: 7,
                        spaceBetween: 15
    
                    }
                }}
    
            >

            {
                flashSales.map((qqq) => {
                    return (
                        <SwiperSlide key={qqq.product_id} style={{width: "205.714px", marginRight: "30px"}}>
                            <SliderDealOfDay recorddata={qqq.product_id} />
                        </SwiperSlide>
                    )
                })
            }

            </Swiper>     
          }

        </div>
      </div>
    </div>
  );
}

export default DealOfDay;
