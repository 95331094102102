import CryptoJS from "crypto-js";
import jwt_decoded from "jwt-decode";
import {
    LOGIN_SUCCESS,
    LOGIN_FAILED,
    REGISTER_SUCCESS,
    REGISTER_FAIL,
    LOGOUT,
    REFRESH_TOKEN
} from "../../actions/types";


const userObjJWT = localStorage.getItem("tjwt");

let user
let encData

if (userObjJWT != null) {
    const bytes = CryptoJS.AES.decrypt(localStorage.getItem("tjwt"), process.env.React_APP_SecretKey);
    encData = bytes.toString(CryptoJS.enc.Utf8);
    user = jwt_decoded(encData);
}


const initialStateUserLogin = userObjJWT
    ? { isLoggedIn: true, userLogin: user, accessToken: encData }
    : { isLoggedIn: false, userLogin: null }



const userReducer = (state = initialStateUserLogin, action) => {
    const { type, payload } = action

    switch (type) {
        case LOGIN_SUCCESS:
            return {
                ...state,
                isLoggedIn: true,
                userLogin: payload.user,
                accessToken: encData
            };
        case LOGOUT:
            return {
                ...state,
                isLoggedIn: false,
                userLogin: null,
            };
        case LOGIN_FAILED:
            return {
                ...state,
                isLoggedIn: false,
                user: null,
            };
        case REFRESH_TOKEN:
            // console.log("ONOOOOOO--------------------");
            return {
                ...state,
                // isLoggedIn: true,
                // userLogin: payload.user,
                accessToken: encData
            }
        default:
            return state
    }
}

export default userReducer;