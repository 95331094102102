import {
    REGISTER_SUCCESS,
    REGISTER_FAIL,
    LOGIN_SUCCESS,
    LOGIN_FAILED,
    LOGOUT,
    REFRESH_TOKEN,
    SET_MESSAGE,
    CLEAR_MESSAGE
} from "./types";

import AuthService from "./auth.service";

export const login = (email, password) => (dispatch) => {
    return AuthService.login(email, password).then(
        (data) => {
            dispatch({
                type: LOGIN_SUCCESS,
                payload: { user: data },
            });
            dispatch({
                type: SET_MESSAGE,
                payload: "LOGIN BERHASIL",
            });

            return Promise.resolve();
        },
        (error) => {
            console.log(error.response.data)
            const message = error.response.data.msg;
            dispatch({
                type: LOGIN_FAILED,
            });
            dispatch({
                type: SET_MESSAGE,
                payload: message,
            });
            return Promise.reject();
        }
    )
}

export const logout = () => (dispatch) => {
    AuthService.logout();

    dispatch({
        type: LOGOUT,
    });
};

export const refreshToken = (accessToken) => (dispatch) => {
    // console.log("INI")
    dispatch({
        type: REFRESH_TOKEN,
        payload: accessToken,
    })
}