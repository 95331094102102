import React, { useState, useEffect, Component, useContext } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import Header from "../Components/Header/Header";
import HeaderMobile from "../Components/Header/HeaderMobile";
import Breadcrumb from "../Components/Common/Breadcrumb";
import Sidebar from "../Components/SideBar/Sidebar";
import ProductFooter from "../Components/Footer/ProductFooter";
import { v4 } from "uuid";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useFormik } from "formik";
import * as Yup from "yup";
// import { CartContext } from "../global/CartContext";

const Resgiter = () => {


  // const dataCart = useContext(CartContext)
  // console.log(dataCart);


  const arrCumb = [
    {
      "name": "Register",
      "link": "register"
    }
  ]

  const [isLoading, setLoading] = useState(false);
  const [count, setCount] = useState(0);
  const formik = useFormik({
    initialValues: {
      name: "",
      email: "",
      password: "",
      repassword: "",
      upline: "",
      shopName: "",
      shopUrl: "",
      shopPhone: "",
      isVendor: "0",
      isChecked: false,
    },
    validationSchema: Yup.object({
      name: Yup.string()
        .max(20, "Must be 20 Characters or less")
        .required("Required"),
      email: Yup.string().email("Invalid email address").required("Required"),
      password: Yup.string()
        .max(20, "Must be 20 Character or less")
        .required("Required"),
      repassword: Yup.string()
        .when("password", {
          is: (val) => (val && val.length > 0 ? true : false),
          then: Yup.string().oneOf(
            [Yup.ref("password")],
            "Confirm Password invalid"
          ),
        })
        .required("Required"),
      shopName: Yup.string().when(["isVendor"], {
        is: (e) => e === "1",
        then: Yup.string().required("Required"),
      }),
      shopUrl: Yup.string().when(["isVendor"], {
        is: (e) => e === "1",
        then: Yup.string().required("Required"),
      }),
      shopPhone: Yup.string().when(["isVendor"], {
        is: (e) => e === "1",
        then: Yup.string().required("Required"),
      }),
      upline: Yup.string().max(10, "Must be 10 Character or less"),
      isChecked: Yup.boolean()
        .required("The terms and conditions must be accepted.")
        .oneOf([true], "The terms and conditions must be accepted."),
    }),
    onSubmit: async (values, { setSubmitting }) => {
      // console.log(values);

      setLoading(true);

      try {
        const apiurl = process.env.React_App_Gracetoko_Api;
        const res = await axios.post(apiurl + "/customers", {
          name: values.name,
          email: values.email,
          password: values.password,
          confirmPassword: values.repassword,
          shopname: values.shopName,
          shopurl: values.shopUrl,
          shopphone: values.shopPhone,
          userrole: values.isVendor,
          affcode_upline: values.upline === "" ? "GRACE" : values.upline,
          affcode: values.isVendor === "1" ? "MLMV" : "MLMC",
        });
        setLoading(false);
        notify("success", res.data.msg);
        window.location.href = '/';
      } catch (error) {
        setLoading(false);
        if (error.response) {
          notify("error", error.response.data.msg);
          // console.log(error.response.data.msg);
        }
      }

      setSubmitting(false);
    },
  });

  const notify = (status, message) => {
    if (status === "error") {
      toast.error(message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    } else {
      toast.success(message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }
  };

  const history = useNavigate();

  return (
    <>
      <Header />
      <HeaderMobile />
      <Breadcrumb pagename={arrCumb} />
      <Sidebar />

      <div className="ps-container">
        <div className="mt-40 mb-40">
          <div className="ps-my-account">
            <div className="container">
              <form
                onSubmit={formik.handleSubmit}
                className="ps-form--account ps-tab-root"
              >
                <input
                  type="hidden"
                  name="_token"
                  value="5bJAFaYL3xAfMKpMsyu71wwlxAvAIVjqPAijyXBU"
                />
                <div className="ps-form__content">
                  <h4>Register An Account</h4>
                  <div className="form-group">
                    <input
                      name="name"
                      className="form-control"
                      value={formik.values.name}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      placeholder="Your Name"
                    />
                    {formik.touched.name && formik.errors.name ? (
                      <span className="text-danger">{formik.errors.name}</span>
                    ) : null}
                  </div>
                  <div className="form-group">
                    <input
                      name="email"
                      className="form-control"
                      value={formik.values.email}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      placeholder="Your Email"
                    />
                    {formik.touched.email && formik.errors.email ? (
                      <span className="text-danger">{formik.errors.email}</span>
                    ) : null}
                  </div>
                  <div className="form-group">
                    <input
                      name="password"
                      className="form-control"
                      type="password"
                      value={formik.values.password}
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      placeholder="Password"
                    />
                    {formik.touched.password && formik.errors.password ? (
                      <span className="text-danger">
                        {formik.errors.password}
                      </span>
                    ) : null}
                  </div>
                  <div className="form-group">
                    <input
                      className="form-control"
                      name="repassword"
                      type="password"
                      value={formik.values.repassword}
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      placeholder="Confirm Password"
                    />
                    {formik.touched.repassword && formik.errors.repassword ? (
                      <span className="text-danger">
                        {formik.errors.repassword}
                      </span>
                    ) : null}
                  </div>

                  <div className="form-group">
                    <label htmlFor="shop-name" className="required">
                      Kode Referral Anda / Default Admin
                    </label>
                    <input
                      className="form-control"
                      type="text"
                      name="upline"
                      value={formik.values.upline}
                      onChange={formik.handleChange}
                      placeholder="Kode Referral / kosongkan jika tidak ada"
                    />
                  </div>

                  {formik.values.isVendor === "1" && (
                    <div className="show-if-vendor">
                      <div className="form-group">
                        <label htmlFor="shop-name" className="required">
                          Shop Name
                        </label>
                        <input
                          className="form-control"
                          type="text"
                          name="shopName"
                          value={formik.values.shopName}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          placeholder="Shop Name"
                        // required={userRole === "shop" ? true : false}
                        />
                        {formik.touched.shopName && formik.errors.shopName ? (
                          <span className="text-danger">
                            {formik.errors.shopName}
                          </span>
                        ) : null}
                      </div>
                      <div className="form-group shop-url-wrapper">
                        <label
                          htmlFor="shop-url"
                          className="required float-left"
                        >
                          Shop URL
                        </label>
                        <span className="d-inline-block float-right shop-url-status"></span>
                        <input
                          className="form-control"
                          type="text"
                          name="shopUrl"
                          value={formik.values.shopUrl}
                          // required={userRole === "shop" ? true : false}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          placeholder="Shop URL"
                          data-url="https://gracetoko.com/ajax/stores/check-store-url"
                        />
                        {formik.touched.shopUrl && formik.errors.shopUrl ? (
                          <span className="text-danger">
                            {formik.errors.shopUrl}
                          </span>
                        ) : null}

                        <span className="d-inline-block">
                          <small data-base-url="https://gracetoko.com/stores">
                            https://gracetoko.com/stores
                          </small>
                        </span>
                      </div>
                      <div className="form-group">
                        <label htmlFor="shop-phone" className="required">
                          Phone Number
                        </label>
                        <input
                          className="form-control"
                          type="text"
                          name="shopPhone"
                          value={formik.values.shopPhone}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          placeholder="Shop phone"
                        // required={userRole === "shop" ? true : false}
                        />
                        {formik.touched.shopPhone && formik.errors.shopPhone ? (
                          <span className="text-danger">
                            {formik.errors.shopPhone}
                          </span>
                        ) : null}
                      </div>
                    </div>
                  )}

                  <div className="form-group user-role">
                    <p>
                      <label>
                        <input
                          type="radio"
                          name="isVendor"
                          value="0"
                          // checked={userRole === "customer"}
                          onChange={formik.handleChange}
                          // onChange={user_role_handle}
                          defaultChecked
                        />
                        <span className="d-inline-block">I am a customer</span>
                      </label>
                    </p>
                    <p>
                      <label>
                        <input
                          type="radio"
                          name="isVendor"
                          value="1"
                          onChange={formik.handleChange}
                        // checked={userRole === "shop"}
                        // onChange={formik.handleChange}
                        // onChange={user_role_handle}
                        />
                        <span className="d-inline-block">I am a vendor</span>
                      </label>
                    </p>
                  </div>
                  <div className="form-group">
                    <p>
                      Your personal data will be used to support your experience
                      throughout this website, to manage access to your account,
                      and for other purposes described in our privacy policy.
                    </p>
                  </div>
                  <div className="form-group">
                    <div className="ps-checkbox">
                      <input
                        name="isChecked"
                        type="checkbox"
                        value={formik.values.isChecked}
                        id="agree-terms-and-policy"
                        onChange={formik.handleChange}
                      />
                      <label htmlFor="agree-terms-and-policy">
                        I agree to terms &amp; Policy.
                      </label>
                    </div>
                    {formik.touched.isChecked && formik.errors.isChecked ? (
                      <span className="text-danger">
                        {formik.errors.isChecked}
                      </span>
                    ) : null}
                  </div>
                  <div className="form-group submit">
                    {!isLoading && (
                      <button
                        className="ps-btn ps-btn--fullwidth"
                        type="submit"
                      >
                        Sign up
                      </button>
                    )}
                    {isLoading && (
                      <button className="ps-btn ps-btn--fullwidth" disabled>
                        <i className="fas fa-spinner fa-spin"></i>Loading ....
                      </button>
                    )}
                  </div>

                  <div className="form-group">
                    <p className="text-center">
                      Already have an account?
                      <a href="/Login" className="d-inline-block">
                        Log in
                      </a>
                    </p>
                  </div>
                </div>
                <div className="ps-form__footer"></div>
              </form>
            </div>
          </div>
        </div>
      </div>

      <ProductFooter />

      <div id="back2top">
        <i className="icon icon-arrow-up"></i>
      </div>

      <div className="ps-site-overlay"></div>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </>
  );
};

export default Resgiter;
