import { Navigate } from "react-router-dom";
import { useSelector } from "react-redux";

export const RequireAuth = ({ children }) => {

    const { isLoggedIn } = useSelector(state => state.userReducer);
    return isLoggedIn === true ? (children) : (<Navigate to="/login" />)

    // try {
    //     const bytes = CryptoJS.AES.decrypt(localStorage.getItem("tjwt"),process.env.React_APP_SecretKey);
    //     const encData = bytes.toString(CryptoJS.enc.Utf8);
    //     const logindata = jwt_decoded(encData);
    //     // console.log(logindata);
    //     return logindata?.email ? (
    //         children 
    //     ) : (
    //         <Navigate to ="/login"/>
    //     )        
    // } catch (error) {
    //     // console.log(error);
    //     return <Navigate to ="/login"/>
    // }
}

// import { useLocation, Navigate, Outlet } from "react-router-dom";
// import useAuth from "./useAuth";


// const RequireAuth = () => {
//     const { auth } = useAuth();
//     console.log(auth);
//     const location = useLocation();
//     return (
//         auth?.accessToken
//             ?<Outlet/>
//             :<Navigate to="/login" state={{from: location}} replace />
//     );
// }

// export default RequireAuth;