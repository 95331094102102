import React, { useState, useEffect } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Thumbs } from 'swiper';
 

function Banner() {

  const imagedata = [
    {
      src : "https://martfury.botble.com/storage/sliders/2-lg.jpg",
      url : "https:/gracetoko.com"
    },
    {
      src: "https://martfury.botble.com/storage/sliders/1-lg.jpg",
      url : "https:/gracetoko.com"
    },
    {
      src: "https://martfury.botble.com/storage/sliders/3-lg.jpg",
      url: "https://gracetoko.com"
    }
  ]

  // console.log(imagedata);

  return (
    <div className="ps-home-banner ps-home-banner--1">
      <div className="ps-container">
        <div className="ps-section__left">

        <Swiper
          loop={true}
          navigation={true}
          // thumbs={{swiper: activeThumb}}
          modules={[Navigation, Thumbs]}
          grabCursor={true}
        >
          {
              imagedata.map((item, index) =>  (
                  <SwiperSlide key={index}>
                      <img src={item.src} alt="product images" />
                  </SwiperSlide>
              ))
          }
            
        </Swiper>


        </div> 
        <div className="ps-section__right">
          <div className="ps-collection">
            <div>
              <a href="https://martfury.botble.com/ads-click/VC2C8Q1UGCBG" target="_blank">
                <img src="https://martfury.botble.com/storage/promotion/1.jpg" alt="Top Slider Image 1" style={{maxWidth: "100%"}} />
              </a>
            </div>
          </div> 
          <div className="ps-collection">
            <div>
              <a href="https://martfury.botble.com/ads-click/NBDWRXTSVZ8N" target="_blank">
                <img src="https://martfury.botble.com/storage/promotion/2.jpg" alt="Top Slider Image 2" style={{ maxWidth: "100%"}} />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
    
  )
}

export default Banner;
