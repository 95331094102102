import { SET_MESSAGE, CLEAR_MESSAGE } from "../../actions/types";

const initialState = {};

const messageReducer = (state = initialState, action) => {
    const { type, payload } = action;

    // console.log("message reducer type : ", type)
    // console.log("message reducer payload : ", payload)

    switch (type) {
        case SET_MESSAGE:
            return {
                message: payload
            };
        case CLEAR_MESSAGE:
            return {
                message: ""
            };
        default:
            return state;
    }
}

export default messageReducer;