import React from 'react'
import { Routes as Switch, Route, Navigate, BrowserRouter as Router } from "react-router-dom";
import {
    Cart,
    Checkout,
    CustomerAddress,
    CustomerChangePassword,
    CustomerCreateAddress,
    CustomerDashboard,
    CustomerEditAccount,
    CustomerOrders,
    CustomerOverview,
    Home,
    Login,
    PageProducts,
    Products,
    ResetPassword,
    Resgiter,
    TestHome,
    MyAccount
} from '../../pages';
import { RequireAuth } from '../../hooks/RequireAuth';

const Routes = () => {
    return (
        <Router>
            <Switch>
                <Route exact path="/" element={<Home />} />
                <Route path="/login" element={<Login />} />
                <Route path="/register" element={<Resgiter />} />
                <Route path="/resetpassword" element={<ResetPassword />} />
                <Route path="/products" element={<Products />} />
                <Route path="/product-categories/:id" element={<Products />} />
                <Route path="/cart" element={
                    <RequireAuth>
                        <Cart />
                    </RequireAuth>
                } />
                <Route path="/checkout" element={<Checkout />} />
                <Route path="/customer" element={<Navigate to="/customer/overview" />} />
                {/* <Route path="/customer/overview" element={<CustomerOverview />} /> */}
                <Route path="/account" element={
                    <RequireAuth>
                        <MyAccount />
                    </RequireAuth>
                } />
                <Route path="/customer/overview" element={
                    <RequireAuth>
                        <CustomerOverview />
                    </RequireAuth>
                } />
                <Route path="/customer/editaccount" element={
                    <RequireAuth>
                        <CustomerEditAccount />
                    </RequireAuth>
                } />
                <Route path="/customer/changepassword" element={
                    <RequireAuth>
                        <CustomerChangePassword />
                    </RequireAuth>
                } />
                <Route path="/customer/orders" element={
                    <RequireAuth>
                        <CustomerOrders />
                    </RequireAuth>
                } />
                <Route path="/customer/address" element={
                    <RequireAuth>
                        <CustomerAddress />
                    </RequireAuth>
                } />
                <Route path="/customer/address/create" element={
                    <RequireAuth>
                        <CustomerCreateAddress />
                    </RequireAuth>
                } />
                <Route path="/customer/dashboard" element={
                    <RequireAuth>
                        <CustomerDashboard />
                    </RequireAuth>
                } />
                <Route path="*" element={<PageProducts />} />
            </Switch>
        </Router>
    )
}


// {/* <CartContextProvider>
//       </CartContextProvider> */}

export default Routes