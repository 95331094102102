import { useState } from "react";
import "./Widget.css";

export default function WidgetSideBarItem({item}) {

    const [open, setOpen] = useState(false);

    if (item.children) {

        return (
            <div className={open ? "sidebar-item open" : "sidebar-item"}>
                <div className="sidebar-title">
                    <span>
                        {item.widget_name}    
                    </span> 
                    <i className="fa fa-angle-down toggle-btn" onClick={() => setOpen(!open)}></i>
                </div>
                <div className="sidebar-content">
                    { item.children.map((child, index) => <WidgetSideBarItem key={index} item={child} />) }
                </div>
            </div>
        ) 
    } else {
        return (

            <a href={"product-categories/"+item.link || "#"} className="sidebar-item plain">
                {item.name}
            </a>

        )
    }
}