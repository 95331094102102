import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../../config/actions/auth";
import styled from "styled-components";

const HideOnMobile = styled.div`
@media only screen and (max-device-width: 480px) {
    div {
      display: none;
    }
}`


const SideBar = props => {

    const dispatch = useDispatch();
    const { userLogin } = useSelector(state => state.userReducer);

    const logOut = useCallback(() => {
        dispatch(logout());
    }, [dispatch]);

    const pageid = props.pageid;

    return (
        <div className="col-lg-4">
            <HideOnMobile>
                <div className="ps-section__left">
                    <aside className="ps-widget--account-dashboard">
                        <div className="ps-widget__header">
                            <form id="avatar-upload-form">
                                <div className="avatar-upload-container">
                                    <div id="account-avatar">
                                        <div className="profile-image">
                                            <div className="avatar-view mt-card-avatar">
                                                <div className="mt-overlay br2">
                                                    <span><i className="fa fa-edit"></i></span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>
                            <figure>
                                <figcaption>Hello</figcaption>
                                <p>{userLogin.name}</p>
                            </figure>
                        </div>
                        <div className="ps-widget__content">
                            <ul>
                                <li className={pageid === 'overview' ? 'active' : ''}><a href="customer/overview"><i className="icon-user"></i> Account Information</a></li>
                                <li className={pageid === 'editaccount' ? 'active' : ''}><a href="customer/editaccount"><i className="icon-pencil"></i> Update profile</a></li>
                                <li className={pageid === 'orders' ? 'active' : ''}><a href="customer/orders"><i className="icon-papers"></i> Orders</a></li>
                                <li className={pageid === 'address' ? 'active' : ''}><a href="customer/address"><i className="icon-map-marker"></i> Address</a></li>
                                <li className={pageid === 'changepassword' ? 'active' : ''}><a href="customer/changepassword"><i className="icon-lock"></i> Change password</a></li>
                                {
                                    userLogin.isVendor > 0 &&
                                    <li className={pageid === 'dashboard' ? 'active' : ''}><a href="customer/dashboard"><i className="icon-cart"></i> Vendor dashboard</a></li>
                                }
                                <li><a href="#" onClick={logOut} ><i className="icon-power-switch"></i>Logout</a></li>
                            </ul>
                        </div>
                    </aside>
                </div>
            </HideOnMobile>
        </div>
    )
}

export default SideBar;